import React, { useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles, Typography, useTheme, useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'src/components/Logo';
import {Auth} from 'aws-amplify'

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
}));

const TopBar = ({
  className,
  onMobileNavOpen,
    items,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const location = useLocation();

  const flat_items = [];

  const appendChildren = (array) => {

    array.forEach(item=>{

      if (item.children){
        appendChildren(item.children)
      } else {
        flat_items.push(item)
      }
    })

  }

  appendChildren(items)

  // items = Object.assign(
  //     {},
  //     ...function _flatten(o) {
  //       return [].concat(...Object.keys(o)
  //           .map(k =>
  //               typeof o[k] === 'object' ?
  //                   _flatten(o[k]) :
  //                   ({[k]: o[k]})
  //           )
  //       );
  //     }(items)
  // )



  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      style={{position:'relative'}}
      {...rest}
    >
      <Toolbar>
        {/*<RouterLink to="/">*/}
          {/*<Logo/>*/}
          <Typography style={{fontSize:18, fontWeight:600}}>
            {flat_items.filter(item=>item.href===location.pathname)[0]?.title}
          </Typography>
        {/*</RouterLink>*/}
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/*<IconButton color="inherit">*/}
          {/*  <Badge*/}
          {/*    badgeContent={notifications.length}*/}
          {/*    color="primary"*/}
          {/*    variant="dot"*/}
          {/*  >*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          <IconButton color="inherit"  onClick={()=>{
            Auth.signOut()
          }}
          onPress>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
