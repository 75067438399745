import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },

    tableCell: {
        fontFamily:'Gilroy-Light'
    },
    tableHeaderCell: {
        fontFamily:'Gilroy',
        fontWeight:'bold',
        fontSize:14
    }
}));









const RequestsView = ({selected_garage}) => {
    const classes = useStyles();

    const [quotes, setQuotes] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate=useNavigate();
    const [status, quoteStatus] = useState("Closed");


    const [value, setValue] = React.useState(0);

    const [emptyQuotes, setEmptyQuotes]=useState(false)
    const [emptyQuotedJobs, setEmptyQuotedJobs]=useState(false)

    useEffect(()=>{
        if (!selected_garage) return;
        // setLoading(false)

        fetchAuth('/quotes/requests?garage=' + selected_garage)
            .then((resJSON)=>{
                setQuotes(resJSON.quotes)
                console.log(resJSON.quotes, "quotes")
                setLoading(false)
                // console.log( "quotesdata",resJSON)

                if (resJSON.quotes?.length===0)
                    setEmptyQuotes(true);
                setLoading(false)
            })

        fetchAuth('/quotes/garagequotedjobs?garage=' + selected_garage)
            .then((resJSON)=>{
                setQuotedJobs(resJSON.quoted_jobs)
                console.log(resJSON.quoted_jobs, "quoted_jobs")
                if (resJSON.quoted_jobs?.length===0)
                    setEmptyQuotedJobs(true);
                setLoading(false)

            })

    }, [selected_garage])



    const declineQuote = (identifier) => {
        if (!selected_garage) return;
        fetchQuote('/' + identifier + '/decline')
            .then((resJSON)=>{
                console.log("declined")
                navigate('/app/quotes/quote/' + identifier + '/decline');



            })


    }


    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Quotes"
        >
            <Container maxWidth="lg">

                <Card style={{overflow:'auto'}}>
                    <CardHeader titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}} title="Awaiting Quotes" />
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            {emptyQuotes?
                                <div>
                                    You have no awaiting quotes
                                </div>:




                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Quote ID
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Registration
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Car Model
                                        </TableCell>
                                        {/*<TableCell>*/}
                                        {/*    Customer Name*/}
                                        {/*</TableCell>*/}
                                        <TableCell className={classes.tableHeaderCell}>
                                            Services
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Date Created
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {quotes.map((quote) => (
                                        <TableRow
                                            hover
                                            key={quote.id}
                                        >
                                            <TableCell className={classes.tableCell}>
                                                {quote.id}
                                            </TableCell>

                                            <TableCell className={classes.tableCell}>
                                                {quote.rego.toUpperCase()}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {quote.make} {quote.model}
                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    {quote.name}*/}
                                            {/*</TableCell>*/}
                                            <TableCell className={classes.tableCell}>
                                                <ul style={{margin:0}}>
                                                    {quote.services.map((item)=>{
                                                    return <li>{item.title}</li>})}

                                                </ul>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {moment(quote.date_created).format('DD/MM/YYYY')}
                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    <Chip*/}
                                            {/*        color="primary"*/}
                                            {/*        label="Pending"*/}
                                            {/*        size="small"*/}
                                            {/*    />*/}
                                            {/*</TableCell>*/}
                                            <TableCell style={{whiteSpace:'nowrap' }} className={classes.tableCell}>
                                                <button variant="contained" className={'manage-button'} style={{padding:5}} color="primary" onClick={()=>{
                                                    navigate('/app/quotes/quote/' + quote.identifier)

                                                }}>

                                                    Quote
                                                </button>

                                                <button  style={{marginLeft:10, padding:5}} className={'quote-job-button-decline'}  onClick={()=>{
                                                    declineQuote(quote.identifier)


                                                }}>
                                                    Decline
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>


                            }

                        </Box>



                    </CardContent>
                </Card>

                <Card style={{marginTop: 10, overflow:'auto'}}>

                    <CardHeader title="Quoted Jobs (Last 30 days)" titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}}/>

                    <CardContent >
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            {emptyQuotedJobs ?
                                <div>
                                    You have not quoted on any jobs in the last 30 days.
                                </div> :


                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeaderCell}>
                                                Quote ID
                                            </TableCell>
                                            <TableCell className={classes.tableHeaderCell}>
                                                Registration
                                            </TableCell>

                                            <TableCell className={classes.tableHeaderCell}>
                                                Services
                                            </TableCell>
                                            <TableCell className={classes.tableHeaderCell}>
                                                Quoted Amount
                                            </TableCell>


                                            <TableCell className={classes.tableHeaderCell}>
                                                Status
                                            </TableCell>
                                            <TableCell className={classes.tableHeaderCell}>
                                                Date Quoted
                                            </TableCell>
                                            <TableCell className={classes.tableHeaderCell}>
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {quotedJobs.map((quotedjob) => (
                                            <TableRow
                                                hover
                                                key={quotedjob.id}
                                            >
                                                <TableCell className={classes.tableCell}>
                                                    {quotedjob.id}
                                                </TableCell>

                                                <TableCell className={classes.tableCell}>
                                                    {quotedjob.rego?quotedjob.rego.toUpperCase():"N/A"}
                                                </TableCell>

                                                <TableCell className={classes.tableCell}>

                                                    <ul style={{margin: 0}}>

                                                        {(quotedjob.garage_quote_breakdown?quotedjob.garage_quote_breakdown:[]).map(s =>
                                                            <li>{s.product}</li>)}

                                                    </ul>


                                                </TableCell>

                                                <TableCell className={classes.tableCell} style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                                    ${quotedjob.total ? quotedjob.total.toFixed(2) : "N/A"}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} style={{whiteSpace: 'nowrap'}}>

                                                    {quotedjob.r_status === 4 ?
                                                        <Chip style={{backgroundColor: "#99ec9d"}}
                                                              label="Accepted"
                                                              size="small"
                                                        /> :null

                                                    }

                                                    {quotedjob.r_status === 5 ?
                                                        <Chip style={{backgroundColor: "#f3f3f3"}}
                                                              label="Closed"
                                                              size="small"
                                                        /> :null

                                                    }

                                                    {quotedjob.q_status === 2?
                                                        <Chip style={{backgroundColor: "#f3f3f3"}}
                                                              label="Declined"
                                                              size="small"
                                                        /> : quotedjob.r_status < 4 ?

                                                            <Chip style={{backgroundColor: "#fecb0a"}}
                                                                  label="Open"
                                                                  size="small"
                                                            />

                                                        :null

                                                    }



                                                </TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {moment(quotedjob.date_quoted).format('DD/MM/YYYY')}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} style={{whiteSpace:'nowrap'}}>


                                                    {quotedjob.r_status < 5 ?
                                                        <button className={'manage-button'} variant="contained" style={{padding: 10}}
                                                                color="primary" onClick={()=>{
                                                            navigate('/app/quotes/quote/' + quotedjob.identifier)}}
                                                        >
                                                            View Quote
                                                        </button>
                                                        : null
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            }



                        </Box>



                    </CardContent>
                </Card>
            </Container>


        </Page>
    );
};

export default RequestsView;
