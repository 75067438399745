import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

// import Amplify from 'aws-amplify'
// import awsconfig from './aws-exports'
//
// Amplify.configure(awsconfig)
// // Auth.configure(awsconfig)

import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
