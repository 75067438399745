import {Auth} from 'aws-amplify'

export const fetchAuth = (url, options) => {

    return new Promise(((resolve, reject) => {

        Auth.currentSession()
            .then(session=>{

                let options_send = {...options, headers: {accesstoken: session.getAccessToken().getJwtToken()}}

                if (options?.headers){
                    options_send.headers = {...options_send.headers, ...options.headers}
                }
                // fetch('/api' + url, options_send)
                fetch('/mechanichub/v1' + url, options_send)
                    .then(res => res.json())
                    .then(resJSON => {
                        if (!resJSON.success) return reject();

                        resolve(resJSON)
                    })
                    .catch(err=>{
                        reject(err)
                    });
            })
            .catch(err=>{
            reject(err)
        });



    }));
}


export const fetchAPI = (url, options) => {

    return new Promise(((resolve, reject) => {

        Auth.currentSession()
            .then(session=>{

                let options_send = {...options, headers: {accesstoken: session.getAccessToken().getJwtToken()}}

                if (options?.headers){
                    options_send.headers = {...options_send.headers, ...options.headers}
                }
                // fetch('/flow-api' + url, options_send)
                //    /flow-api for live MH
                    fetch(url, options_send)
                    .then(res => res.json())
                    .then(resJSON => {
                        if (!resJSON.success) return reject();
                        resolve(resJSON)
                    })
                    .catch(err=>{
                        reject(err)
                    });
            })
            .catch(err=>{
                reject(err)
            });



    }));
}


export const fetchAuthBlob = (url, options) => {

    return new Promise(((resolve, reject) => {

        Auth.currentSession()
            .then(session=>{

                let options_send = {...options, headers: {accesstoken: session.getAccessToken().getJwtToken()}}

                if (options?.headers){
                    options_send.headers = {...options_send.headers, ...options.headers}
                }
                // fetch('/api' + url, options_send)
                fetch('/mechanichub/v1' + url, options_send)
                    .then(res => res.blob())
                    .then( blob => {
                        const file = window.URL.createObjectURL(blob);
                        resolve(file)
                    })
                    .catch(err=>{
                        reject(err)
                    });
            })
            .catch(err=>{
                reject(err)
            });



    }));
}




export const fetchQuote = (url, options) => {

    return new Promise(((resolve, reject) => {

        Auth.currentSession()
            .then(session=>{

                let options_send = {...options, headers: {accesstoken: session.getAccessToken().getJwtToken()}}

                if (options?.headers){
                    options_send.headers = {...options_send.headers, ...options.headers}
                }
                // fetch('/flow-api' + url, options_send)

                fetch('/quote/v1' + url, options_send)
                    .then(res => res.json())
                    .then(resJSON => {
                        if (!resJSON.success) return reject();

                        resolve(resJSON)
                    })
                    .catch(err=>{
                        reject(err)
                    });
            })
            .catch(err=>{
                reject(err)
            });



    }));
}
