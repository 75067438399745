import React, {useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import PricingView from 'src/views/pricing/PricingView';
import FixedPriceProductsView from 'src/views/pricing/FixedPriceProductsView';
import MonthlyPaymentsView from "./views/payments/MonthlyPayments";
import YourDataView from "./views/data/YourData";

import {useRoutes } from 'react-router-dom';
import {fetchAuth} from "./utils/fetch";
import OverrideView from "./views/pricing/OverrideView";
import SummaryView from "./views/pricing/SummaryView";
import AdjustmentView from "./views/pricing/AdjustmentView";
import ExtrasView from "./views/extras/Extras";
import NewPricingView from "./views/pricing/NewPricingView";
import MonthlyJobHistoryView from "./views/history/jobhistory";
import MonthlyPayments from "./views/payments/MonthlyPayments";

//QUOTE
import RequestsView from "./views/quotes/RequestsView";
import QuoteView from "./views/quotes/Quote";
import ContactUsView from "./views/help/ContactUsView";
import AvailabilityView from "./views/availability/AvailabilityView";
import AccountView from "./views/account/AccountView";
import IntegrationsView from "./views/account/IntegrationsView";
import CustomerReviewView from "./views/customer/Reviews_Engagement/CustomerReviewView";
import PromotionsView from "./views/promotions/Promotions";
import {BarChart as BarChartIcon, Calendar, Book, BookOpen,
Clipboard, DollarSign, HelpCircle, User, Settings, Smile, Database, Gift} from "react-feather";
import JobManagement from "./job_management/JobManagementView";
import GarageDataView from "./views/data/YourData";
import InvoicesView from "./views/invoices/InvoicesView";
import {ImProfile} from "react-icons/all";
import QuoteFinalView from "./views/quotes/QuoteFinalScreen";
import SuccessQuoteView from "./views/quotes/Success";
import Promotions from "./views/promotions/Promotions";
import PromotionsListView from "./views/promotions/PromotionsListView";


const Routes = ({setIsTourOpen, garageName, garageList, garage, setGarage, setGarageName, setGarageList, loading, shortTut, setShortTut})=>{




    const items = [

        {
            href: '/app/dashboard',
            icon: BarChartIcon,
            title: 'Your Dashboard'
        },

        {
            icon: DollarSign,
            title:'Pricing',
            children: [
                {
                    href: '/app/pricing/labour',
                    icon: DollarSign,
                    title: 'Your Pricing'
                },
                // {
                //     href: '/app/pricing/labour',
                //     icon: DollarSign,
                //     title: '1. Base Pricing'
                // },
                // {
                //     href: '/app/pricing/fixed',
                //     icon: DollarSign,
                //     title: '2. Fixed Products'
                // },
                {
                    href: '/app/pricing/overrides',
                    icon: DollarSign,
                    title: 'Overrides'
                },
                // {
                //     href: '/app/pricing/adjustment',
                //     icon: DollarSign,
                //     title: 'Margin Adjustment'
                // },
                {
                    href: '/app/pricing/extras',
                    icon: DollarSign,
                    title: 'Extras'
                },
                // {
                //     href: '/app/pricing/summary',
                //     icon: DollarSign,
                //     title: 'Pricing Summary'
                // },
            ]
        },
        {
            icon: Clipboard,
            title: 'Quotes',
            href: '/app/quotes/requests'

        },
        {
            href: '/app/reviews/view',
            icon: Smile,
            title: 'Customer Engagement & Reviews'
        },
        {
            href: '/app/promotions/view',
            icon: DollarSign,
            title: 'Promotions'
        },
        // {
        //     href: '/app/extras/manage',
        //     icon: Smile,
        //     title: 'Extras & Add-ons'
        // },
        {
            href: '/app/data/view',
            icon: Database,
            title: 'Your Data'
        },
        // { add in later
        //     href: '/app/availability/manage',
        //     icon: Calendar,
        //     title: 'Availability'
        // },
        // {
        //     href: '/app/account/manage',
        //     icon: User,
        //     title: 'Account'
        // },


        // {
        //     href: '/app/account/manage',
        //     icon: User,
        //     title: 'Account'
        // },
        // {
        //     href: '/app/payments/manage',
        //     icon: DollarSign,
        //     title: 'Payments'
        // },

        {
            href: '/app/profile/manage',
            icon: User,
            title: 'Profile'
        },
        {
            icon: Book,
            title:'History',
            children: [

                {
                    href: '/app/history/jobs',
                    icon: BookOpen,
                    title: 'Job History'
                },
                {
                    href: '/app/history/invoices',
                    icon: DollarSign,
                    title: 'Monthly Invoices'
                },
                // {
                //     href: '/app/history/payments',
                //     icon: DollarSign,
                //     title: 'Payment History'
                // }
            ]
        },


        // {
        //     href: '/app/account/integrations',
        //     icon: Settings,
        //     title: 'Integrations'
        // },
        {
            href: '/app/help/contact',
            icon: HelpCircle,
            title: 'Help'
        }
    ]

  // useEffect(()=>{
  //
  //   fetchAuth('/user/garages')
  //       .then((resJSON)=>{
  //         setGarageList(resJSON.garages);
  //         if (resJSON.garages.length > 0)
  //           setGarage(resJSON.garages[0].id);
  //           setGarageName(resJSON.garages[0].name);
  //       })
  //
  // }, [])

  const routes = [
    {
      path: 'app',
      element: <DashboardLayout garage_list={garageList} set_garage={setGarage} selected_garage={garage} items={items} loading={loading} />,
      children: [
        { path: 'dashboard', element: <DashboardView selected_garage={garage} selected_garage_name={garageName} setIsTourOpen={setIsTourOpen} setShortTut={setShortTut}/>},
          {path: 'dashboard/jobmanagement/:hash', element: <JobManagement selected_garage={garage} selected_garage_name={garageName}/> },

        { path: 'pricing', children: [
            { path: 'labour', element: <NewPricingView  selected_garage={garage} /> },
                { path: 'adjustment', element: <AdjustmentView selected_garage={garage} /> },
            { path: 'extras', element: <ExtrasView  selected_garage={garage} /> },
                { path: 'overrides', element: <OverrideView selected_garage={garage} /> },
            { path: 'summary', element: <SummaryView selected_garage={garage} /> },

          ] },

          { path: 'quotes', children: [
                  { path: 'requests', element: <RequestsView  selected_garage={garage} /> },
                  { path: 'quote/:identifier', element: <QuoteView  selected_garage={garage} /> },
              ] },
          { path: 'reviews', children: [
                  { path: 'view', element: <CustomerReviewView  selected_garage={garage} /> },
              ] },


          { path: 'promotions', children: [
                  { path: 'view', element: <PromotionsListView selected_garage={garage} /> },
                  { path: 'edit/:id', element: <PromotionsView selected_garage={garage} /> },
                  { path: 'new', element: <PromotionsView newPromotion={true} selected_garage={garage} /> },
              ] },
          { path: 'data', children: [
                  { path: 'view', element: <GarageDataView  selected_garage={garage} /> },
              ] },

        { path: 'help', children: [
            { path: 'contact', element: <ContactUsView  selected_garage={garage} /> },
          ] },
        { path: 'availability', children: [
            { path: 'manage', element: <AvailabilityView  selected_garage={garage} /> },
          ] },
          { path: 'history', children: [
                  { path: 'invoices', element:  <InvoicesView  selected_garage={garage} /> },
                  { path: 'jobs', element: <MonthlyJobHistoryView selected_garage={garage} /> },
              ] },
          { path: 'profile', children: [
                  { path: 'manage', element: <AccountView  selected_garage={garage} /> },
              ] },
        { path: 'account', children: [
            { path: 'integrations', element: <IntegrationsView  selected_garage={garage} /> },
            { path: 'manage', element: <AccountView  selected_garage={garage} /> },
          ] },


        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'login', element: <LoginView /> },
        { path: 'register', element: <RegisterView /> },
        { path: '404', element: <NotFoundView /> },
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];

  return useRoutes(routes)
}

export default Routes
