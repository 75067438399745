import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Grid,
    Paper,
    Tooltip,
    makeStyles, Container, TablePagination
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {fetchAuth} from "../../utils/fetch";
import Loading from "../../components/Loading";
import {useNavigate, useLocation} from "react-router-dom";
import {TiSpannerOutline} from "react-icons/all";
import Fade from "@material-ui/core/Fade";




const data = [
    {
        id: uuid(),
        ref: 'CDD1049',
        amount: 30.5,
        customer: {
            name: 'Ekaterina Tankova'
        },
        createdAt: 1555016400000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1048',
        amount: 25.1,
        customer: {
            name: 'Cao Yu'
        },
        createdAt: 1555016400000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1047',
        amount: 10.99,
        customer: {
            name: 'Alexa Richardson'
        },
        createdAt: 1554930000000,
        status: 'refunded'
    },
    {
        id: uuid(),
        ref: 'CDD1046',
        amount: 96.43,
        customer: {
            name: 'Anje Keizer'
        },
        createdAt: 1554757200000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1045',
        amount: 32.54,
        customer: {
            name: 'Clarke Gillebert'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1044',
        amount: 16.76,
        customer: {
            name: 'Adam Denisov'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    }
];

const useStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: "30%",
    },
    tableCell: {
        fontFamily:'Gilroy-Light'
    },
    tableHeaderCell: {
    fontFamily:'Gilroy',
        fontWeight:'bold',
        fontSize:14
}

}));

const MonthlyJobHistoryView = ({selected_garage, className, ...rest }) => {
    const classes = useStyles();
    const [orders] = useState(data);
    const d = new Date();
    const n = d.getMonth();

    const [month, setMonth] = React.useState(n+1);
    const [jobHistory, setJobHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate=useNavigate();
    const [year, setYear]=React.useState(2024);

    const [color, setColor]=useState("primary");
    const [emptyHistory, setEmptyHistory]=useState(false);


    const stripeFee = 0.025;
    const AfterPayFee = 0.045;

    const fees = {
        afterpay: 0.04,
            stripe: 0.04,
            instore: 0.00
    }


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(6);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(  0);
    };


    const handleChange = (event) => {
        setMonth(event.target.value);
    };


    const [amountDue, setAmountDue] = useState(null)



    useEffect(()=>{
        if (!selected_garage) return;

        fetchAuth(`/user/jobhistory?garage=${selected_garage}&month=${month}&year=${year}`)
            .then((resJSON)=>{

                setJobHistory(resJSON.data)
                setLoading(false)



                setAmountDue(resJSON.data?resJSON.data.map(job => (job.price - (fees[job.payment_type]*job.price))).reduce((prev, next) => prev + next):[])
            })
    }, [selected_garage, month, year])

    if (loading)
        return <Loading/>
        // else if (emptyHistory===true)
        //     return <div>Del</div>





    return (

        <Container maxWidth={false} style={{paddingTop: 20}}>
            <div>

                <FormControl className={classes.formControl}>
                    {/*<InputLabel id="demo-simple-select-helper-label"> Month</InputLabel>*/}
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={month}
                        onChange={handleChange}
                    >
                        <MenuItem value="This Month">
                            <em>This Month</em>
                        </MenuItem>
                        <MenuItem value={1}>January 2024</MenuItem>
                        <MenuItem value={2}>February 2024</MenuItem>
                        <MenuItem value={3}>March 2024</MenuItem>
                        <MenuItem value={4}>April 2024</MenuItem>
                        <MenuItem value={5}>May 2024</MenuItem>
                        <MenuItem value={6}>June 2024</MenuItem>
                        <MenuItem value={7}>July 2024</MenuItem>
                        <MenuItem value={8}>August 2024</MenuItem>
                        <MenuItem value={9}>September 2024</MenuItem>
                        <MenuItem value={10}>October 2024</MenuItem>
                        <MenuItem value={11}>November 2024</MenuItem>
                        <MenuItem value={12}>December 2024</MenuItem>
                    </Select>
                    <FormHelperText>Select the month you would like to view</FormHelperText>
                </FormControl>

            </div>




            {!jobHistory ?
                <Card
                className={clsx(classes.root, className)}
            {...rest}
                >
                    <CardHeader title="Sorry! There are no jobs. Please select another month"/>
                    <Divider/>

                    </Card> :


                <Card
                    className={clsx(classes.root, className)}
                    {...rest}
                >


                    <CardHeader title="Job History" titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}}/>
                    <Divider/>
                    <PerfectScrollbar>
                        <Box minWidth={400}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Customer Name
                                        </TableCell>
                                        <TableCell sortDirection="desc" className={classes.tableHeaderCell}>

                                            Booking Date
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Car Registration
                                        </TableCell>

                                        <TableCell className={classes.tableHeaderCell}>
                                            Status
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Payment Type
                                        </TableCell>

                                        <Tooltip  arrow title="If a negative amount, this is the amount invoiced by My Auto Shop" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive>
                                        <TableCell style={{width:150}} className={classes.tableHeaderCell}>
                                            Customer Amount
                                        </TableCell>
                                        </Tooltip>

                                        <TableCell style={{width:150}} className={classes.tableHeaderCell}>
                                             Merch %
                                        </TableCell>
                                        <TableCell style={{width:150}} className={classes.tableHeaderCell}>
                                            Merch Fee $
                                        </TableCell>
                                        <TableCell style={{width:150}} className={classes.tableHeaderCell}>
                                            Payment due to you
                                        </TableCell>
                                        <TableCell className={classes.tableHeaderCell}>
                                            Actions
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobHistory.slice((page) * rowsPerPage, (page + 1) * rowsPerPage).map((order) => (
                                        <TableRow
                                            hover

                                        >

                                            <TableCell className={classes.tableCell}>
                                                {order.first_name} {order.last_name}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {moment(order.pickup_time).format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {order.vehicle_rego?order.vehicle_rego.toUpperCase():null}
                                            </TableCell>
                                            {/*<TableCell>*/}
                                            {/*    /!*<ul style={{margin:0}}>*!/*/}

                                            {/*    /!*    {jobHistory.OpportunityLineItems?.records.map(record=>*!/*/}


                                            {/*    /!*                <span style={{marginLeft:8}}>*!/*/}
                                            {/*    /!*{record.Description?record.Description:record.Name.split(" Job ")[1]}*!/*/}
                                            {/*    /!*                </span>*!/*/}

                                            {/*    /!*    )}*!/*/}
                                            {/*    /!*</ul>*!/*/}

                                            {/*        /!*{order.garage_quote_breakdown.map(s=> <li>{s.product}</li>)}*!/*/}
                                            {/*</TableCell>*/}


                                            <TableCell className={classes.tableCell}>
                                                <Chip
                                                    style={{backgroundColor:"#99ec9d"}}

                                                    color={"#0afec5"}
                                                    label={"Completed, " + order.status}
                                                    size="small"
                                                />

                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Chip
                                                    style={{backgroundColor:order.payment_type==="afterpay"?"#b2fce4":(order.payment_type==="stripe")?"#008cdd":"#fecb0a"}}
                                                    label={order.payment_type}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                ${order.price.toFixed(2)}

                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {order.payment_type==="afterpay"?"4.5%":(order.payment_type==='stripe')?"2.5% + $0.30":"0.0%"}

                                            </TableCell>

                                            {order.payment_type === "afterpay" ?
                                                <TableCell className={classes.tableCell}>

                                                    ${(AfterPayFee * order.price).toFixed(2)}

                                                </TableCell> :
                                                order.payment_type === "stripe" ?
                                                    <TableCell className={classes.tableCell}>

                                                        ${((stripeFee * order.price) + 0.30).toFixed(2)}

                                                    </TableCell>
                                                    :
                                                    <TableCell className={classes.tableCell}>

                                                       $0.00

                                                    </TableCell>
                                            }

                                            {order.payment_type === "afterpay" ?
                                                <TableCell className={classes.tableCell}>

                                                    ${(order.price - (AfterPayFee * order.price)).toFixed(2)}

                                                </TableCell> :
                                                order.payment_type === "stripe" ?
                                                    <TableCell className={classes.tableCell}>

                                                        ${(order.price - (stripeFee * order.price) + 0.30).toFixed(2)}

                                                    </TableCell>
                                                    :
                                                    <TableCell className={classes.tableCell}>

                                                        ${order.price.toFixed(2)}

                                                    </TableCell>
                                            }



                                            <TableCell className={classes.tableCell}>
                                                <Button color={"primary"} variant={"outlined"} onClick={() => {
                                                    navigate(`/app/dashboard/jobmanagement/` + order.hash)
                                                }
                                                }>View</Button>
                                            </TableCell>



                                        </TableRow>
                                    ))}


                                </TableBody>
                            </Table>

                            {amountDue ?
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexDirection: 'row',
                                    margin: 14,
                                    fontSize: 20,
                                    fontFamily: 'Gilroy',
                                    fontWeight: 800,
                                    marginRight: 30
                                }}>
                                    Total amount due on the 7th of next month: {" $" + amountDue.toFixed(2)}

                                </div>
                                :
                                null
                            }

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={jobHistory.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Box>
                    </PerfectScrollbar>
                    <Divider/>

                    {/*<PerfectScrollbar>*/}
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    justifyContent="flex-end"*/}
                    {/*    p={2}*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        color={color}*/}
                    {/*        endIcon={<ArrowRightIcon />}*/}
                    {/*        size="small"*/}
                    {/*        variant="text"*/}
                    {/*    >*/}
                    {/*        Download Invoice*/}
                    {/*    </Button>*/}

                    {/*</Box>*/}
                    {/*</PerfectScrollbar>*/}


                </Card>

            }




            {/*<Card style={{marginTop:20}}>*/}

            {/*    <CardHeader title="Job Summary" />*/}

            {/*    <Paper className={classes.paper}>*/}


            {/*        <Table aria-label="spanning table">*/}



            {/*            <TableBody>*/}
            {/*                <TableRow>*/}
            {/*                    <TableCell style={{fontWeight:"bold"}}> Total (incl. GST)</TableCell>*/}
            {/*                    <TableCell align="center" style={{fontSize:"24"}}>${MonthlyPaymentData.reduce((accum,item) => accum + item.price, 0).toFixed(2)}</TableCell>*/}
            {/*                </TableRow>*/}

            {/*            </TableBody>*/}




            {/*        </Table>*/}
            {/*    </Paper>*/}

            {/*</Card>*/}
        </Container>
    );
};

MonthlyJobHistoryView.propTypes = {
    className: PropTypes.string
};

export default MonthlyJobHistoryView;
