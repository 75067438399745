import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, InputAdornment, OutlinedInput, Select, MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import {PricingInput} from "./components/PricingInput";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const FixedPriceProductsView = ({selected_garage}) => {
    const classes = useStyles();

    const [pricing, setPricing] = useState({});
    const [loading, setLoading] = useState(true);

    const [region, setRegion] = useState('Auckland');


    const regionData = {
        'Auckland': ['$53.87', '$97.12', '$169.00', '$29.99', '$79.00', '$329.00', '$77.45'],
        'Metro': ['$60.66', '$92.49', '$169.00', '$29.99', '$79.00', '$329.00', '$77.45'],
        'Towns': ['$63.33', '$90.08', '$169.00', '$29.99', '$79.00', '$329.00', '$77.45'],
    };

    useEffect(()=>{
        if (!selected_garage) return;
        fetchAuth('/pricing/fixed?garage=' + selected_garage)
            .then((resJSON)=>{
                setPricing(resJSON.labour)
                setLoading(false)
            })

    }, [selected_garage])

    const onSave = () => {
        fetchAuth('/pricing/fixed?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:pricing})
        })
            .then((resJSON)=>{
                toast.success("Fixed Pricing Saved!");

            })
    }

    if (loading)
        return <Loading/>


    return (
        <Page
            className={classes.root}
            title="Pricing"
        >
            <Container maxWidth="lg">

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <div>
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Typography>
                                            <p>
                                                Based on the product schedules, enter the appropriate labour and parts amounts which best reflect
                                                how you want to be priced in market. Feel free to jump back and forward to check outputs, as you adjust these numbers.
                                                <br/>Please consider:

                                                <br/>- Your price should be able to cover your costs, labour, GST and margin.
                                                <br/>- Your price will appear alongside other workshops in the neighbourhood.
                                                <br/>- Customers look for value, but being too low priced will raise questions about quality.
                                                <br/>- Our margin as agreed will be deducted from this amount (displayed on the final summary page)
                                                <br/>- Region averages are taken from the latest released MTA rates survey.
                                            </p>
                                        </Typography>
                                    </Grid>
                                    <Grid item cs={4}>
                                        <Typography>
                                            <p>
                                                Select your region to see your region averages:

                                            </p>
                                        </Typography>
                                        <FormControl
                                            style={{width:300}}
                                        >
                                            <InputLabel id="region-select-label">Region</InputLabel>
                                            <Select
                                                labelId="region-select-label"
                                                value={region}
                                                onChange={({target})=>setRegion(target.value)}
                                            >
                                                <MenuItem value={'Auckland'}>Auckland</MenuItem>
                                                <MenuItem value={'Metro'}>NZ Metro Cities (excl. Auckland)</MenuItem>
                                                <MenuItem value={'Towns'}>NZ Towns</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>

                            <br/>
                            <form noValidate autoComplete="off">

                                <Grid container spacing={3}>

                                    <Grid item xs={8}/>

                                    <Grid item xs={2}
                                          style={{
                                              display: 'flex',
                                              alignItems:'center',
                                          }}
                                    >
                                        <Typography>
                                            Difference
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}
                                          style={{
                                              display: 'flex',
                                              alignItems:'center',
                                          }}>
                                        <Typography>
                                            Region Average
                                        </Typography>
                                    </Grid>

                                </Grid>

                                {[
                                    {
                                        name: 'work-wof',
                                        title: 'WOF',
                                        state: 'wof'
                                    },
                                    {
                                        name: 'work-diagnostic',
                                        title: 'Diagnostic',
                                        state: 'diagnostic'
                                    },
                                    {
                                        name: 'work-prepurchase',
                                        title: 'Pre Purchase Inspection',
                                        state: 'pre_purchase'
                                    },
                                    {
                                        name: 'work-wheel',
                                        title: 'Wheel Balance (2 Wheels)',
                                        state: 'wheel_balance'
                                    },
                                    {
                                        name: 'work-wheelalign',
                                        title: 'Wheel Alignment (4 Wheels)',
                                        state: 'wheel_alignment'
                                    },
                                    {
                                        name: 'work-transmissionflush',
                                        title: 'Transmission Flush',
                                        state: 'transmission_flush'
                                    },
                                    {
                                        name: 'work-brakeflush',
                                        title: 'Brake Flush',
                                        state: 'brake_flush'
                                    },

                                ].map((item, index)=>
                                    <Grid container spacing={3}>

                                        <Grid item xs={8}>
                                    <PricingInput type="number"
                                                  step="0.01"
                                                  name={item.name}
                                                  title={item.title}
                                                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                  value={pricing[item.state]}
                                                  onChange={({target})=>{
                                                      setPricing({...pricing, [item.state]: target.value})
                                                  }}
                                    />
                                        </Grid>
                                            <Grid item xs={2}
                                                  style={{
                                                      display: 'flex',
                                                      alignItems:'center',
                                                  }}
                                            >
                                                <Typography style={{color:((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                    {((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}
                                                  style={{
                                                      display: 'flex',
                                                      alignItems:'center',
                                                  }}
                                            >
                                                <Typography>
                                                    {regionData[region][index]}
                                                </Typography>
                                            </Grid>

                                        </Grid>

                                )}


                                {[
                                    {
                                        name: 'front_brake_pads',
                                        title: 'Front Brake Pads'
                                    },
                                    {
                                        name: 'rear_brake_pads',
                                        title: 'Rear Brake Pads'
                                    },
                                    {
                                        name: 'rotors',
                                        title:'Rotors'
                                    },
                                    {
                                        name: 'spark_plugs',
                                        title: 'Spark Plugs'
                                    },
                                    {
                                        name: 'pads_rotors',
                                        title: 'Pads/Rotors'
                                    },
                                    {
                                        name: 'wipers',
                                        title:'Wipers'
                                    },
                                    {
                                        name: 'headlight_restoration',
                                        title:'Headlight Restoration'
                                    },
                                    {
                                        name: 'tyre_fit',
                                        title: 'Tyre Strip, Fit, Balance & Dispose'
                                    },
                                    {
                                        name: 'bulb_replacement',
                                        title: 'Bulb Replacement'
                                    }
                                ].map(product=>(
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={pricing[product.name]}
                                                onChange={({target})=>{
                                                    setPricing({...pricing, [product.name]: target.checked?1:0})
                                                }}
                                                name={product.name}
                                                color="primary"
                                            />
                                        }
                                        label={product.title}
                                    />
                                ))


                                }




                                <div>
                                    <Button variant="contained" color="primary" onClick={onSave}>Save</Button>

                                </div>

                            </form>

                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default FixedPriceProductsView;
