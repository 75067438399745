/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7569d112-fc38-401c-a5db-ba614c683f14",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_t92GyCsti",
    "aws_user_pools_web_client_id": "5hnbslmc9tpm5qkpp2s8ncfk1n",
    "oauth": {}
};


export default awsmobile;
