import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,

    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Loader from "src/components/Loading";


import styles from "../../styles/quote/quote.scss"
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));




const QuotedJobView = ({selected_garage, quote, notes, total, inhand, job}) => {
    const classes = useStyles();

    const params = useParams();
    const identifier = params.identifier


    const [breakdown, setBreakdown] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [successPage, setSuccessPage] = useState(false);

    const navigate=useNavigate();



    const [sendNotes, setSendNotes] = useState([]);

    const [disabled, setDisabled] = useState(true)


    const [viewQuote, setViewQuote] = useState(false)
    const [messagePage, setMessagePage] = useState(false)

    const [declinedQuote, setDeclinedQuote] = useState(false)
    const [declinedReason, setDeclinedReason] = useState("")


    const [displayJob, setDisplayJob] = useState([

    ])


    // const [quote, setQuote] = useState(quote)
    const [quoteable, setQuoteable] = useState([])
    const [title, setTitle] = useState([])
    const [message, setMessage] = useState([])






    const [items_1, setItems_1] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )

    const [items_2, setItems_2] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )





















    // if (loading)
    //     return <Loading></Loading>

    return (
        <Page
            className={styles.quotePage}
            title="Quote"
        >
            <div className={"quotePage"}>
            <Container  maxWidth="lg">

                <Card style={{overflow:'auto'}} style={{padding:25}}>


                    <div className={"header"} style={{display:'flex', justifyContent:'flex-start'}}>

                        <Button className={'manage-button'} style={{marginRight:10}} onClick={()=> {
                            navigate('/app/quotes/requests')
                        }}>
                            Back
                        </Button>
                        <div style={{textAlign:'center'}}>
                            Quote for {quote.firstname} {quote.lastname}'s {quote.make} {quote.model} - Submitted

                        </div>
                    </div>



                    <div>
                        <hr/>
                    </div>



                    <Grid container justifyContent="center" direction="row" alignItems="center">

                        <Grid item md={12}>

                            <div className={"car-info"}>
                                Car Info
                            </div>

                        </Grid>
                        <Grid container style={{marginTop:15}}>
                            <Grid item md={6} xs={6}>
                                <div className={"car-info-title"}>
                                    <span>Make:</span> {quote.make}
                                </div>
                                <div className={"car-info-title"}>
                                    <span>Model:</span> {quote.model}
                                </div>
                                <div className={"car-info-title"}>
                                    <span>Year: </span> {quote.year}
                                </div>

                            </Grid>

                            <Grid item md={6} xs={6}>
                                <div className={"car-info-title"}>
                                    <span>Engine:</span> {quote.engine}
                                </div>
                                <div className={"car-info-title"}>
                                    <span>Registration:</span> {quote.rego?quote.rego.toUpperCase():null}
                                </div>
                                <div className={"car-info-title"}>
                                    <span>KM's Driven:</span> {quote.kms?quote.kms:null}
                                </div>

                            </Grid>

                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className={"job-info"}>
                                Job Info


                                {(quote.services?quote.services:[]).map((service)=>(
                                    <li>
                                        {service.title}

                                    </li>
                                ))}
                            </div>


                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className={"job-info"}>
                                Further Relevant Info
                            </div>

                            <div className={"questions-wrapper"}>



                                <div dangerouslySetInnerHTML={{__html: unescape(quote.problem)}}>


                                </div>



                            </div>



                        </Grid>



                    </Grid>

                    <div>
                        <hr/>
                    </div>






                    <div> <hr/></div>


                    <Grid container>
                        <div className={"parts-labour-title"}>
                            Quoted Products & Services
                        </div>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={"cell-header"}>
                                        Product/Service
                                    </TableCell>
                                    <TableCell className={"cell-header"}>
                                        Unit Price (incl. GST)
                                    </TableCell>
                                    <TableCell className={"cell-header"}>
                                        Quantity
                                    </TableCell>
                                    <TableCell className={"cell-header"}>
                                        Total Price
                                    </TableCell>


                                </TableRow>
                            </TableHead>

                            <TableBody>


                                {job.map((j, index)=>(

                                    <TableRow>


                                        <TableCell className={"cell-item-text"}>
                                            {j.product}
                                        </TableCell>
                                        <TableCell className={"cell-item-text"}>
                                            ${(j.price)}
                                        </TableCell>
                                        <TableCell className={"cell-item-text"}>
                                            {j.quantity}
                                        </TableCell>
                                        <TableCell className={"cell-item-text"}>
                                            ${(j.price * j.quantity).toFixed(2)}
                                        </TableCell>

                                    </TableRow>
                                ))}

                                <TableRow>


                                    <TableCell  rowSpan={3}>

                                        <div className={"notes-wrapper"}>

                                            {/*<div> Notes:</div>*/}
                                            {/*<div>*/}
                                            {/*    {quote.notes?quote.notes:notes}*/}
                                            {/*</div>*/}

                                        </div>

                                    </TableCell>
                                    <TableCell className={"cell-item-text-bold"} colSpan={2}>

                                        Total to Customer (incl. GST)
                                    </TableCell>
                                    <TableCell className={"cell-item-text"}>
                                        ${job.reduce((a, b) => +a + +b.price * +b.quantity, 0).toFixed(2)}
                                    </TableCell>

                                </TableRow>

                                <TableRow>

                                    <TableCell colSpan={2} className={"cell-item-text-bold"}>
                                        My Auto Shop Service Fee
                                    </TableCell>
                                    <TableCell className={"cell-item-text"}>
                                        {quote.commission*100}%
                                    </TableCell>

                                </TableRow>

                                <TableRow>

                                    <TableCell colSpan={2} className={"cell-item-text-bold"}>
                                        Total Payment (incl. GST)
                                    </TableCell>
                                    <TableCell className={"cell-item-text"}>
                                        ${(job.reduce((a, b) => +a + +b.price * +b.quantity, 0) * ((100 - +(quote.commission)*100)/100)).toFixed(2)}
                                    </TableCell>

                                </TableRow>


                            </TableBody>


                        </Table>

                        <div>
                            <hr/>
                        </div>


                        <div className={"warning-text"}>
                            <br/>
                            You will be expected to honour this price when the customer books. However, if any unexpected elements outside the stated job are found, these can be communicated and solved with the customer directly.
                        </div>





                    </Grid>





                </Card>
            </Container>
            </div>

        </Page>
    );
};

export default QuotedJobView;
