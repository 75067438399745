import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,

    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader,
} from '@material-ui/core';
import Page from 'src/components/Page';


import styles from "../../styles/quote/quote.scss"
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));




const MessageView = ({selected_garage, title, message}) => {
    const classes = useStyles();

    const params = useParams();
    const identifier = params.identifier

    const [quotes, setQuotes] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate=useNavigate();

    const [notes, setNotes] = useState(
        {
            notes:null
        }

    );

    const [disabled, setDisabled] = useState(true)


    const [declined, setDeclinedQuote] = useState(false)


    const [displayJob, setDisplayJob] = useState([

    ])
    const [job, setJob] = useState([

    ])

    const [quote, setQuote] = useState([])

    const [jobNotes, setJobNotes] = useState([

    ])


    const [items_1, setItems_1] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )

    const [items_2, setItems_2] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )







    const onInputChangeParts = ({target: {value, name}})=>setItems_1({...items_1, [name]: value})
    const onInputChangeLabour = ({target: {value, name}})=>setItems_2({...items_2, [name]: value})


    const onNotesChange = ({target: {value, name}})=>setNotes({...notes, notes: value})


    const onSelectChange = (event) => {
        setItems_2({...items_2, [event.target.name]: event.target.value});
    };

    const onAdd = (type) => {

        if (type==="Parts") {
            setJob([...job, items_1])
        } else {
            setJob([...job, items_2])
        }

            fetchQuote('/' + identifier + '/add',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product:type==="Parts"?items_1.product:items_2.product,
                    price:type==="Parts"?items_1.price:items_2.price,
                    quantity:type==="Parts"?items_1.quantity:items_2.quantity,
                })
            })
                .then((resJSON)=>{
                    toast.success("Item added to quote!");
                })

    }

    const addNote = () => {

        setJobNotes([...jobNotes, notes])


        fetchQuote('/' + identifier + '/note',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                notes:jobNotes

            })
        })
            .then((resJSON)=>{
                toast.success("Job note added!");
            })

    }














    return (
        <Page
            className={styles.quotePage}
            title="Quote"
        >
            <div className={"quotePage"}>
            <Container  maxWidth="lg">

                <Card style={{overflow:'auto'}} style={{padding:25}}>


                    <div className={"header"} style={{textAlign:'left'}}>
                        {title}
                    </div>

                    <div style={{textAlign:'left', fontSize:16}} className={"header"}>
                        {message}
                    </div>


                    <Button  className={'manage-button'}  onClick={()=> {
                        navigate('/app/quotes/requests')
                    }}>
                        Back
                    </Button>

                </Card>
            </Container>
            </div>

        </Page>
    );
};

export default MessageView;
