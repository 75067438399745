import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles,
    TextareaAutosize
} from '@material-ui/core';
import {fetchAuth} from "../../../utils/fetch";
import {toast} from "react-toastify";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {AiOutlineMail} from "react-icons/all";
import {GrLocation} from "react-icons/all";
import {FiGlobe} from "react-icons/all";
import {BiPencil} from "react-icons/all";
import {AiOutlinePhone} from "react-icons/all";
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Switch from '@material-ui/core/Switch';




const states = [
    {
        value: 'alabama',
        label: 'Alabama'
    },
    {
        value: 'new-york',
        label: 'New York'
    },
    {
        value: 'san-francisco',
        label: 'San Francisco'
    }
];

const useStyles = makeStyles(() => ({
    root: {}
}));

const WorkshopProfileDetails = ({selected_garage, className, garage, garageInfo, setGarageInfo, ...rest }) => {
    const classes = useStyles();
    const [values, setValues] = useState({
        firstName: 'Katarina',
        lastName: 'Smith',
        email: 'demo@devias.io',
        phone: '',
        state: 'Alabama',
        country: 'USA'
    });

    const [bio, setBio] = useState('');
    // const [garageInfo, setGarageInfo] = useState({});
    const [isDisabled, setDisabled] = useState(true);
    const [specialty1, setSpecialty1] = useState("Specialty 1");
    const [specialty2, setSpecialty2] = useState("Specialty 2");
    const [specialty3, setSpecialty3] = useState("Specialty 3");

    const [editGarageInfo, setEditGarageInfo] = useState({});

    useEffect(()=>{
        setGarageInfo(garageInfo)
        setEditGarageInfo(garageInfo)
    }, [garageInfo]);





    const specialties = [
        "Customer didn't show",
        "European Vehicles",
        "Japanese Vehicles",
        "Brakes", "Clutch & Transmission",
        "Classic Vehicles",
        "Performance Vehicles",
        "Mobile Service",
        "Radiator Specialist",
        "Turbo Specialist",
        "Glass & Wipers",
        "Fleet Maintenance",
        "Official Dealership",
        "Diesel Vehicles",
        "Air Conditioning",
        "Car Keys",
        "Suspension",
        "Tyres & Balancing",
        "Valet & Detailing",
        "Towing",
        "Scratch & Dent",
        "Audi",
        "BMW/Mini",
        "Daihatsu",
        "Ford",
        "Fiat",
        "Holden",
        "Honda",
        "Hyundai",
        "Jaguar",
        "Jeep",
        "Kia",
        "Mazda",
        "Mitsibushi",
        "Nissan",
        "Subaru",
        "Suzuki",
        "Toyota",
        "Volkswagen",
        "Volvo"

    ]

    const [loading, setLoading] = useState(true);
    const [editForm, setEditForm] = useState(false);
    // conditional based on when button for edit is clicked, change the state to true, and then use a conditional rendering to allow editing

    useEffect(()=>{

        setBio(garage.bio)

    }, [garage.bio])


    // useEffect(()=>{
    //
    //     setBio(garage.bio)
    //
    // }, [garage.bio])




    // useEffect(()=>{
    //     if (!selected_garage) return;
    //
    //     fetchAuth('/user/garageprofileinfo?garage=' + selected_garage)
    //         .then((resJSON)=>{
    //             setGarageInfo(resJSON.garageprofileinfo)
    //             setEditGarageInfo(resJSON.garageprofileinfo)
    //             setLoading(false)
    //             console.log("garageinfo", garageInfo)
    //         })
    //
    //
    // }, [selected_garage])


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const onSave = () => {



        fetchAuth('/user/bio',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                garage_id: garage.id,
                garage_name: garage.name,
                bio: bio,
            //    add in post of email and address changes

            //

            })
        })
            .then((resJSON)=>{
                toast.success("Your changes will go live within 24 hours.");

            })
    }


    const onSaveWorkshopDetails = () => {

        // setGarageInfo(editGarageInfo);
        fetchAuth('/user/profileinfo',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                garage_id: garage.id,
                profile_info: editGarageInfo,


            })
        })
            .then((resJSON)=>{
                toast.success("Your workshop details will go live within 24 hours.");

            })




    }

    return (
        <div style={{height:"100%"}}>
        <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}

        >

            <Card style={{marginTop:10, height:"100%"}}>
                <CardHeader subheader="Update details if necessary"
                            title="Workshop Details">


                </CardHeader>

                <div style={{display:'flex', justifyContent:'flex-end'}}>

                    <span style={{marginTop:5}}> <BiPencil></BiPencil> </span>



                    <span style={{}}>
        <Button onClick={()=>{

            setEditForm(true);
        }}>Edit Info</Button>
            </span>
                </div>


                <Divider />
                <CardContent>

                    {editForm?
                        <div>

                            <Form>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Website</Form.Label>
                                    <Form.Control type="email" placeholder={garageInfo.website} onChange={(e)=>setEditGarageInfo({...editGarageInfo, website: e.target.value})}/>
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="email" placeholder={garageInfo.address} onChange={(e)=>setEditGarageInfo({...editGarageInfo, address: e.target.value})}/>
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder={garageInfo.email}  onChange={(e)=>setEditGarageInfo({...editGarageInfo, email: e.target.value})} />
                                </Form.Group>



                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Phone number</Form.Label>
                                    <Form.Control placeholder={garageInfo.phone} onChange={(e)=>setEditGarageInfo({...editGarageInfo, phone: e.target.value})}/>
                                </Form.Group>


                                <Form.Group controlId="formBasicCheckbox">
                                </Form.Group>
                                <div style={{display:'flex', justifyContent:'flex-end'}}>
                                    <Button onClick={()=>{

                                        setEditForm(false);
                                        console.log(editGarageInfo)
                                        setGarageInfo(editGarageInfo)
                                        console.log(garageInfo)

                                        onSaveWorkshopDetails();
                                    }} variant="primary" type="submit">
                                        Save
                                    </Button>

                                    <Button onClick={()=>{

                                        setEditForm(false);

                                    }} variant="primary" type="submit">
                                        Cancel
                                    </Button>
                                </div>



                            </Form>

                        </div>

                        :<div>
                            <div style={{fontSize:14, marginTop:5}}>
                                <FiGlobe></FiGlobe><span> Website: <a href={ `${garageInfo.website}`}> {garageInfo.website} </a> </span>
                            </div>
                            <div style={{fontSize:14, marginTop:5}} >
                                <GrLocation></GrLocation>  <span> Address: {garageInfo.address} </span>
                            </div>
                            <div style={{fontSize:14, marginTop:5}}>
                                <AiOutlineMail></AiOutlineMail> <span> Workshop Email: {garageInfo.email} </span>
                            </div>

                            <div style={{fontSize:14, marginTop:5}}>
                                <AiOutlinePhone></AiOutlinePhone> <span> Phone Number: {garageInfo.phone} </span>
                            </div>

                            <div style={{fontSize:14, marginTop:5}}>
                                <a href={ `https://myautoshop.co.nz/garages/${garageInfo.slug}`}> <FiGlobe></FiGlobe> View My Auto Shop Profile</a>
                            </div>

                            {/*<div style={{marginTop:20}}>*/}
                            {/*    Pick Up/Drop Off Enabled <Switch color={"primary"} checked={garageInfo.delivery_service_enabled}></Switch>*/}
                            {/*</div>*/}



                        </div>


                    }



                </CardContent>
                <Divider />
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                >




                </Box>
            </Card>


            {/*<Card style={{marginTop:10}}>*/}
            {/*    <CardHeader*/}
            {/*                title="Opening Hours">*/}
            {/*        */}
            {/*    </CardHeader>*/}
            {/*    <CardContent>*/}
            {/*        <Box display="flex"*/}
            {/*             justifyContent="flex-end"*/}
            {/*             p={1}>*/}

            {/*            <div style={{display:'flex', justifyContent:'space-between'}}>*/}

            {/*                <div>*/}

            {/*                </div>*/}

            {/*            </div>*/}




            {/*        </Box>*/}

            {/*    </CardContent>*/}

            {/*</Card>*/}






        </form>
        </div>






    );
};

WorkshopProfileDetails.propTypes = {
    className: PropTypes.string
};

export default WorkshopProfileDetails;
