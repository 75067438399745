import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(() => ({
  root: {}
}));

Array.prototype.rotateRight = function( n ) {
  this.unshift.apply( this, this.splice( n, this.length ) );
  return this;
}

const Sales = ({ className, data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();


  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  var d = new Date();
  var n = d.getMonth();

  months.push.apply(months, months.splice(0, n+1));

  let data_values = []

  months.forEach((item, index)=>{


    let value = 0;
    data?.forEach((data_row)=>{
      console.log(data_row.month === index+1)
      if (data_row.month === index+1)
        value = data_row.revenue.toFixed()
    })

    data_values.push(value)
  })

  data_values.push.apply(data_values, data_values.splice(0, n+1));

  const data_graph = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: data_values,
        label: 'Month Revenue'
      },
    ],
    labels: months
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            callback: function(value, index, ticks) {
              return '$' + value;
            }
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
          titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}}
        title="Latest 12 Months Revenue"
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={data_graph}
            options={options}
          />
        </Box>
      </CardContent>
      {/*<Divider />*/}
      {/*<Box*/}
      {/*  display="flex"*/}
      {/*  justifyContent="flex-end"*/}
      {/*  p={2}*/}
      {/*>*/}
      {/*  /!*<Button*!/*/}
      {/*  /!*  color="primary"*!/*/}
      {/*  /!*  endIcon={<ArrowRightIcon />}*!/*/}
      {/*  /!*  size="small"*!/*/}
      {/*  /!*  variant="text"*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  Overview*!/*/}
      {/*  /!*</Button>*!/*/}
      {/*</Box>*/}
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string
};

export default Sales;
