import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles, FormControl, InputLabel, Select, MenuItem,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {
  BarChart as BarChartIcon,
    DollarSign,
    Clipboard,
    HelpCircle,
    Calendar,
    User
} from 'react-feather';
import NavItem from './NavItem';
import Logo from "../../../components/Logo";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};


// const items = [
//   {
//     href: '/app/dashboard',
//     icon: BarChartIcon,
//     title: 'Dashboard'
//   },
//   // {
//   //   href: '/app/customers',
//   //   icon: UsersIcon,
//   //   title: 'Customers'
//   // },
//   // {
//   //   href: '/app/products',
//   //   icon: ShoppingBagIcon,
//   //   title: 'Products'
//   // },
//   // {
//   //   href: '/app/account',
//   //   icon: UserIcon,
//   //   title: 'Account'
//   // },
//
//   // {
//   //   href: '/app/settings',
//   //   icon: SettingsIcon,
//   //   title: 'Settings'
//   // },
//   // {
//   //   href: '/login',
//   //   icon: LockIcon,
//   //   title: 'Login'
//   // },
//   // {
//   //   href: '/register',
//   //   icon: UserPlusIcon,
//   //   title: 'Register'
//   // },
//   // {
//   //   href: '/404',
//   //   icon: AlertCircleIcon,
//   //   title: 'Error'
//   // }
// ];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    // top: 64,
    // height: 'calc(100% - 64px)'
    height: '100%'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
      height:0
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile, garage_list, set_garage, selected_garage, items }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <RouterLink to="/">

          {/*<Logo style={{width:'100%', objectFit:'contain', marginBottom:20}}/>*/}
          <img src={'/static/MHFinal.png'}  width={"100%"} style={{objectFit: 'contain', marginBottom:15}}/>


        </RouterLink>

        {/*<Avatar*/}
        {/*  className={classes.avatar}*/}
        {/*  component={RouterLink}*/}
        {/*  src={user.avatar}*/}
        {/*  to="/app/account"*/}
        {/*/>*/}
        {/*<Typography*/}
        {/*  className={classes.name}*/}
        {/*  color="textPrimary"*/}
        {/*  variant="h5"*/}
        {/*>*/}
        {/*</Typography>*/}
        {/*<Typography*/}
        {/*  color="textSecondary"*/}
        {/*  variant="body2"*/}
        {/*>*/}

        {/*</Typography>*/}

        {/*select garage*/}
          <FormControl style={{width:'100%'}}>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => {
                    const value = event.target.value;
                    set_garage(value);
                  }}

                  value={selected_garage}
              >
                {
                  garage_list.map(garage=>(
                      <MenuItem value={garage.id}>{garage.name}</MenuItem>

                  ))
                }
              </Select>
          </FormControl>

      </Box>
      <Divider />
      <Box p={2}>

        {
          items.map((main_nav_item, index)=> {

            if (main_nav_item.children) return <Accordion

                defaultExpanded={location.pathname.indexOf('/app/pricing') > -1}
                elevation={0}

                classes={{
                  root: classes.MuiAccordionroot,
                  expanded: classes.expanded
                }}
            >
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls={"nav-header-" + index}
                  id={"nav-header-" + index}
                  style={{
                    paddingLeft:8,
                  }}
              >

                <main_nav_item.icon size={20} style={{marginRight: 10, color:"#546e7a"}}/>
                <Typography style={{fontSize:'0.875rem', fontWeight:500}}
                            color="textSecondary"
                            variant="body2">

                  {main_nav_item.title}

                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List style={{}}>

                  {main_nav_item.children.map((item) => (
                      <NavItem

                          href={item.href}
                          key={item.title}
                          title={item.title}
                          icon={item.icon}
                      />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>


            return <NavItem
                href={main_nav_item.href}
                key={main_nav_item.title}
                title={main_nav_item.title}
                icon={main_nav_item.icon}
            />
          })
        }





      </Box>
      <Box flexGrow={1} />
      {/*<Box*/}
      {/*  p={2}*/}
      {/*  m={2}*/}
      {/*  bgcolor="background.dark"*/}
      {/*>*/}
      {/*  <Typography*/}
      {/*    align="center"*/}
      {/*    gutterBottom*/}
      {/*    variant="h4"*/}
      {/*  >*/}
      {/*    Need more?*/}
      {/*  </Typography>*/}
      {/*  <Typography*/}
      {/*    align="center"*/}
      {/*    variant="body2"*/}
      {/*  >*/}
      {/*    Upgrade to PRO version and access 20 more screens*/}
      {/*  </Typography>*/}
      {/*  <Box*/}
      {/*    display="flex"*/}
      {/*    justifyContent="center"*/}
      {/*    mt={2}*/}
      {/*  >*/}
      {/*    <Button*/}
      {/*      color="primary"*/}
      {/*      component="a"*/}
      {/*      href="https://react-material-kit.devias.io"*/}
      {/*      variant="contained"*/}
      {/*    >*/}
      {/*      See PRO version*/}
      {/*    </Button>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
