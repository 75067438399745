import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
  Divider,
  Grid,
  TextField,
  makeStyles,
  TextareaAutosize, Slider, FormControlLabel, Checkbox, Container, List
} from '@material-ui/core';
import {fetchAuth} from "../../../utils/fetch";
import {toast} from "react-toastify";
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import {AiOutlineMail} from "react-icons/all";
import {GrLocation} from "react-icons/all";
import {FiGlobe} from "react-icons/all";
import {BiPencil} from "react-icons/all";
import {TiDelete} from "react-icons/all";
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import NavItem from "../../../layouts/DashboardLayout/NavBar/NavItem";

const states = [
  {
    value: 'alabama',
    label: 'Alabama'
  },
  {
    value: 'new-york',
    label: 'New York'
  },
  {
    value: 'san-francisco',
    label: 'San Francisco'
  }
];

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({selected_garage, className, garageInfo, setGarageInfo, garage, ...rest }) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    firstName: 'Katarina',
    lastName: 'Smith',
    email: 'demo@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  });

  const [bio, setBio] = useState('');
  const [garageSpecialty, setGarageSpecialty] = useState([]);
  const [garagePoints, setGaragePoints] = useState({});
  const [isDisabled, setDisabled] = useState(true);


  const [specialty, setSpecialty] = useState({});

  const [specialtyList, setSpecialtyList] = useState([]);

  // const specialties = [
  //   "Customer didn't show",
  //   "European Vehicles",
  //   "Japanese Vehicles",
  //   "Brakes", "Clutch & Transmission",
  //   "Classic Vehicles",
  //   "Performance Vehicles",
  //     "Mobile Service",
  //     "Radiator Specialist",
  //     "Turbo Specialist",
  //     "Glass & Wipers",
  //     "Fleet Maintenance",
  //     "Official Dealership",
  //     "Diesel Vehicles",
  //     "Air Conditioning",
  //     "Car Keys",
  //     "Suspension",
  //     "Tyres & Balancing",
  //     "Valet & Detailing",
  //     "Towing",
  //     "Scratch & Dent",
  //     "Audi",
  //     "BMW/Mini",
  //     "Daihatsu",
  //     "Ford",
  //     "Fiat",
  //     "Holden",
  //     "Honda",
  //     "Hyundai",
  //     "Jaguar",
  //     "Jeep",
  //     "Kia",
  //     "Mazda",
  //     "Mitsibushi",
  //     "Nissan",
  //     "Subaru",
  //     "Suzuki",
  //     "Toyota",
  //     "Volkswagen",
  //     "Volvo"
  //
  // ]

  const [loading, setLoading] = useState(true);
  const [cms_id, setCMS_ID] = useState(true);
  const [editForm, setEditForm] = useState(false);
  const [editSpecialty, setEditSpecialty] = useState(false);
  const [editPoints, setEditPoints] = useState(false);
  const [editBio, setEditBio] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [dropdownValue, setDropdownValue] = useState('');
  // const handleSelect=(e)=>{
  //   console.log(e);
  //   setDropdownValue(e)
  // }


  useEffect(() => {


    setBio(garage.bio)

  }, [garage.bio])


  useEffect(() => {
    if (!selected_garage) return;

    fetchAuth('/user/garagespecialty?garage=' + selected_garage)
        .then((resJSON) => {
          setGarageSpecialty(resJSON.garagespecialty)

          setLoading(false)


        })

    fetchAuth('/user/websitespecialtieslist')
        .then((resJSON) => {
          setSpecialtyList(resJSON.specialtylist)
          // console.log(resJSON.specialtylist)
        })


    fetchAuth('/user/garagepoints?garage=' + selected_garage)
        .then((resJSON) => {
          setGaragePoints(resJSON.points)
          setLoading(false)
          // console.log( "points",resJSON)
        })

  }, [selected_garage])









  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSave = () => {

    fetchAuth('/user/bio',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        garage_id: garage.id,
        garage_name: garage.name,
        bio: garageInfo.description,
      })
    })
        .then((resJSON)=>{
          toast.success("Bio updated! Your changes will go live within 24 hours.");

        })


  }


  const onSaveSpecialty = () => {




    fetchAuth('/user/CMSUpdateSpecialties',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        garage_id: garage.id,
        specialties:specialty.id
      })
    })
        .then((resJSON)=>{

          setGarageSpecialty([...garageSpecialty, {name: specialty.name, specialty_id: specialty.id}])
          toast.success("Specialties updated! Your changes will go live within 24 hours.");

        })






  }







  const onDelete = (specialty_id) => {



    fetchAuth('/user/CMSDeleteSpecialty',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        garage_id: garage.id,
        specialties: specialty_id
      })
    })
        .then((resJSON)=>{
          toast.success("Specialties updated! Your changes will go live within 24 hours.");

        })

    fetchAuth('/user/garagespecialty?garage=' + selected_garage)
        .then((resJSON) => {
          setGarageSpecialty(resJSON.garagespecialty)
          // setSpecialty(resJSON.garagespecialty.id)
          console.log((resJSON.garagespecialty))
          setLoading(false)


          // console.log(JSON.stringify(resJSON.garagespecialty[0].specialty))

        }, [selected_garage])

  }

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card >
        <CardHeader
          subheader="The information can be edited"
          title="Profile">



        </CardHeader>



        <Divider />
        <CardContent >


              <ReactQuill value={garageInfo?.description?garageInfo.description:''} onChange={(content, delta, source, editor)=>setGarageInfo({...garageInfo, description: content})}/>

          {/*<CardHeader*/}
          {/*    subheader="Workshop Bullet Points">*/}

          {/*</CardHeader>*/}

          {/*  /!*<ReactQuill value={garagePoints?.content?garagePoints.content:''} onChange={(content, delta, source, editor)=>setGaragePoints({...garagePoints, content: content})}/>*!/*/}

          {/*  <div style={{padding:2}}>*/}

          {/*   */}
          {/*    <Col>*/}
          {/*    <Button color={"primary"} style={{marginTop:10}} onClick={()=>{*/}
          {/*      setEditPoints(true);*/}
          {/*      handleShow();*/}
          {/*    }}>*/}
          {/*      Add New*/}
          {/*    </Button>*/}
          {/*    </Col>*/}
          {/*    */}
          {/*   */}
          {/*  </div>*/}



        </CardContent>
        <Divider />


      </Card>



      <Card style={{marginTop:10, overflow:'visible'}}>

        <CardHeader
            subheader="Select what your workshop specialises in:"
            title="Workshop Specialities"
        />
        {garageSpecialty.length >= 3 ?
            null :
        <div style={{display:'flex', justifyContent:'flex-end', marginRight:25}}>
        <span style={{}}>


               <Button onClick={() => {


                 setShowModal(true);
               }}>Add Specialty</Button>

            </span>
          <span style={{marginTop:5}}> <BiPencil></BiPencil> </span>

        </div>
        }




        <Divider />
        <CardContent>




      <div style={{display:"flex", flexWrap: 'wrap'}}>

        {garageSpecialty.length === 0 ?

            <div>
              You haven't added any specialties yet! Click the button to add one.
            </div>:null
        }
        {garageSpecialty.map(spec=>
            <div>
              {spec.name}

              <span>
                <Button onClick={()=>
                    onDelete(spec.specialty_id)
                }
                > <TiDelete size={17}></TiDelete> </Button>
              </span>
            </div>




        )}
      </div>









        </CardContent>
        <Divider />
        <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
        >
          {/*<Button*/}
          {/*    color="primary"*/}
          {/*    variant="contained"*/}
          {/*    onClick={onSave}*/}
          {/*>*/}
          {/*  Save details*/}
          {/*</Button>*/}
        </Box>
      </Card>




      <Card style={{marginTop:10}}>
        <CardContent>
          <Box display="flex"
               justifyContent="flex-end"
               p={1}>

        <div style={{display:'flex', justifyContent:'space-between'}}>

            <div>
            <Button
                color="primary"
                variant="contained"
                onClick={onSave}
                // onClick={console.log(specialty1.specialty_id)}
            >
              Save details
            </Button>
            </div>

        </div>

            {/*<div>*/}
            {/*  <Button*/}
            {/*      color="primary"*/}
            {/*      variant="contained"*/}
            {/*      onClick={()=>{window.open(`https://myautoshop.co.nz/garages/${garageInfo.slug}`)}}*/}
            {/*  >*/}
            {/*    View Garage Profile on Website*/}
            {/*  </Button>*/}
            {/*</div>*/}


          </Box>

        </CardContent>

      </Card>

      <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Workshop Specialty</Modal.Title>
        </Modal.Header>
        <Modal.Body>



              <div style={{display: 'flex', justifyContent: 'center'}}>

                <div>
                  <Dropdown drop={"down"}>
                    <DropdownButton
                        drop={"down"}
                        bsStyle="default"
                        bsSize="small"
                        variant="warning" title={specialty.name ? specialty.name : "Add new"}

                    >

                      {specialtyList.map(s => <Dropdown.Item style={{maxHeight: "28px", height: "70px"}}
                                                             onClick={() => {
                                                               setSpecialty(s);
                                                               // setDisabled(false);


                                                             }}>{s.name}
                      </Dropdown.Item>)}
                    </DropdownButton>
                  </Dropdown>
                </div>


              </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            handleClose();
            onSaveSpecialty();





          }}>Confirm</Button>
          <Button variant="primary" onClick={handleClose}>Cancel</Button>
        </Modal.Footer>
      </Modal>




    </form>







  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
