import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  city: 'Los Angeles',
  country: 'USA',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith',
  timezone: 'GTM-7'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 200,
    width: 200
  }
}));

const Profile = ({ className, garage, ...rest }) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow= () => setShowModal(true);

  return (

    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={garage.image_url}
            style={{marginBottom:20}}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {garage.name}
          </Typography>
          <div>




            <label htmlFor="icon-button-file">
              <Tooltip title="Change your profile photo" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

              <Button  endIcon={<PhotoCamera />} onClick={()=>{

                handleShow();
              }}  variant="contained" color="primary" aria-label="upload picture" component="span">
                Change Image
                {/*<PhotoCamera />*/}




              </Button>
              </Tooltip>
            </label>
          </div>
        </Box>
      </CardContent>
      <Divider />
      <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Let's get you looking snazzy!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{padding:20}}>

            Email the team at <a href="mailto:hello@myautoshop.co.nz">hello@myautoshop.co.nz</a> with your new photo.
            {/*<div>*/}
            {/*  <div style={{display:"flex", justifyContent:'center'}}>*/}
            {/*    <input accept="image/*" className={classes.input} id="icon-button-file" type="file" />*/}
            {/*  </div>*/}

            {/*</div>*/}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Card>

  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
