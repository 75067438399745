import React from "react";

import {fade, FormControl, InputBase, InputLabel, makeStyles, withStyles, InputAdornment, OutlinedInput, TextField} from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 300,
        marginBottom:10,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));


export const PricingInput = (props)=> {
    const classes = useStyles();

    return (
        <div>

            {/*<OutlinedInput*/}
            {/*    id={props.name+'-input'}*/}
            {/*    {...props}*/}
            {/*    InputLabelProps={{*/}
            {/*        shrink: true,*/}
            {/*    }}*/}
            {/*    variant="outlined"*/}
            {/*    label={props.title}*/}
            {/*    fullWidth*/}
            {/*    className={classes.margin}*/}
            {/*    startAdornment={<InputAdornment position="start">$</InputAdornment>}*/}

            {/*/>*/}

            <TextField
                required={false}
                className={classes.margin}
                id={props.name+'-input'}
                {...props}
                label={props.title}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true,
                }}
            >

            </TextField>

            {/*<FormControl fullWidth className={classes.margin}>*/}
            {/*    <InputLabel  shrink htmlFor={props.name+'-input'}>{props.title}</InputLabel>*/}
            {/*    <OutlinedInput*/}

            {/*        id={props.name+'-input'}*/}
            {/*        {...props}*/}
            {/*        label={props.title}*/}

            {/*    />*/}
            {/*</FormControl>*/}
            {/*<FormControl>*/}
            {/*    <InputLabel shrink htmlFor={props.name+'-input'}>*/}
            {/*        {props.title}*/}
            {/*    </InputLabel>*/}
            {/*    <BootstrapInput id={props.name+'-input'} {...props} />*/}
            {/*</FormControl>*/}
        </div>

    )
}

