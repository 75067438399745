import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem,Select, MenuItem, Slider, Checkbox
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import Modal from "react-bootstrap/Modal";
import FormGroup from '@material-ui/core/FormGroup';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    warningButton: {
        backgroundColor: "#d42211"
    }
}));



const HoursSelect = ({day, openValue, closeValue, onSelectOpen, onSelectClose}) => (

    <Grid container style={{marginBottom:20}}>

        <Grid item xs={6}>
            <div style={{marginRight:5}}>
                <FormControl style={{width:'100%'}}>
                    <InputLabel  id={day+'-open'}>{day} Opening</InputLabel>
                    <Select
                        labelId={day+'-open'}
                        // id="demo-simple-select"
                        value={openValue}
                        onChange={onSelectOpen}
                    >
                        <MenuItem value={'6:00 AM'}>6:00 AM</MenuItem>
                        <MenuItem value={'6:30 AM'}>6:30 AM</MenuItem>
                        <MenuItem value={'7:00 AM'}>7:00 AM</MenuItem>
                        <MenuItem value={'7:30 AM'}>7:30 AM</MenuItem>
                        <MenuItem value={'8:00 AM'}>8:00 AM</MenuItem>
                        <MenuItem value={'8:30 AM'}>8:30 AM</MenuItem>
                        <MenuItem value={'9:00 AM'}>9:00 AM</MenuItem>
                        <MenuItem value={'9:30 AM'}>9:30 AM</MenuItem>
                        <MenuItem value={'10:00 AM'}>10:00 AM</MenuItem>
                        <MenuItem value={'10:30 AM'}>10:30 AM</MenuItem>
                        <MenuItem value={'11:00 AM'}>11:00 AM</MenuItem>
                        <MenuItem value={'11:30 AM'}>11:30 AM</MenuItem>
                        <MenuItem value={'12:00 PM'}>12:00 PM</MenuItem>
                        <MenuItem value={'12:30 PM'}>12:30 PM</MenuItem>
                        <MenuItem value={'1:00 PM'}>1:00 PM</MenuItem>
                        <MenuItem value={'1:30 PM'}>1:30 PM</MenuItem>
                        <MenuItem value={'2:00 PM'}>2:00 PM</MenuItem>
                        <MenuItem value={'2:30 PM'}>2:30 PM</MenuItem>
                        <MenuItem value={'3:00 PM'}>3:00 PM</MenuItem>
                        <MenuItem value={'3:30 PM'}>3:30 PM</MenuItem>
                        <MenuItem value={'4:00 PM'}>4:00 PM</MenuItem>
                        <MenuItem value={'4:30 PM'}>4:30 PM</MenuItem>
                        <MenuItem value={'5:00 PM'}>5:00 PM</MenuItem>
                        <MenuItem value={'5:30 PM'}>5:30 PM</MenuItem>
                        <MenuItem value={'6:00 PM'}>6:00 PM</MenuItem>
                        <MenuItem value={'6:30 PM'}>6:30 PM</MenuItem>
                        <MenuItem value={'7:00 PM'}>7:00 PM</MenuItem>
                        <MenuItem value={'7:30 PM'}>7:30 PM</MenuItem>
                        <MenuItem value={'8:00 PM'}>8:00 PM</MenuItem>
                        <MenuItem value={'8:30 PM'}>8:30 PM</MenuItem>
                        <MenuItem value={'9:00 PM'}>9:00 PM</MenuItem>
                        <MenuItem value={'9:30 PM'}>9:30 PM</MenuItem>
                        <MenuItem value={'10:00 PM'}>10:00 PM</MenuItem>
                        <MenuItem value={'10:30 PM'}>10:30 PM</MenuItem>
                        <MenuItem value={'11:00 PM'}>11:00 PM</MenuItem>
                        <MenuItem value={'11:30 PM'}>11:30 PM</MenuItem>
                        <MenuItem value={'12:00 AM'}>12:00 AM</MenuItem>
                        <MenuItem value={'12:30 AM'}>12:30 AM</MenuItem>
                        <MenuItem value={'1:00 AM'}>1:00 AM</MenuItem>
                        <MenuItem value={'1:30 AM'}>1:30 AM</MenuItem>
                        <MenuItem value={'2:00 AM'}>2:00 AM</MenuItem>
                        <MenuItem value={'2:30 AM'}>2:30 AM</MenuItem>
                        <MenuItem value={'3:00 AM'}>3:00 AM</MenuItem>
                        <MenuItem value={'3:30 AM'}>3:30 AM</MenuItem>
                        <MenuItem value={'4:00 AM'}>4:00 AM</MenuItem>
                        <MenuItem value={'4:30 AM'}>4:30 AM</MenuItem>
                        <MenuItem value={'5:00 AM'}>5:00 AM</MenuItem>
                        <MenuItem value={'5:30 AM'}>5:30 AM</MenuItem>
                        <MenuItem value={'Closed'}>Closed</MenuItem>
                    </Select>
                </FormControl>
            </div>

        </Grid>

        <Grid item xs={6}>
            <div style={{marginLeft:5}}>
                <FormControl style={{width:'100%'}}>
                    <InputLabel id={day+'-close'}>{day} Closing</InputLabel>
                    <Select
                        labelId={day+'-close'}
                        // id="demo-simple-select"
                        value={closeValue}
                        onChange={onSelectClose}
                    >
                        <MenuItem value={'3:00 PM'}>3:00 PM</MenuItem>
                        <MenuItem value={'3:30 PM'}>3:30 PM</MenuItem>
                        <MenuItem value={'4:00 PM'}>4:00 PM</MenuItem>
                        <MenuItem value={'4:30 PM'}>4:30 PM</MenuItem>
                        <MenuItem value={'5:00 PM'}>5:00 PM</MenuItem>
                        <MenuItem value={'5:30 PM'}>5:30 PM</MenuItem>
                        <MenuItem value={'6:00 PM'}>6:00 PM</MenuItem>
                        <MenuItem value={'6:30 PM'}>6:30 PM</MenuItem>
                        <MenuItem value={'7:00 PM'}>7:00 PM</MenuItem>
                        <MenuItem value={'7:30 PM'}>7:30 PM</MenuItem>
                        <MenuItem value={'8:00 PM'}>8:00 PM</MenuItem>
                        <MenuItem value={'8:30 PM'}>8:30 PM</MenuItem>
                        <MenuItem value={'9:00 PM'}>9:00 PM</MenuItem>
                        <MenuItem value={'9:30 PM'}>9:30 PM</MenuItem>
                        <MenuItem value={'10:00 PM'}>10:00 PM</MenuItem>
                        <MenuItem value={'10:30 PM'}>10:30 PM</MenuItem>
                        <MenuItem value={'11:00 PM'}>11:00 PM</MenuItem>
                        <MenuItem value={'11:30 PM'}>11:30 PM</MenuItem>
                        <MenuItem value={'12:00 AM'}>12:00 AM</MenuItem>
                        <MenuItem value={'12:30 AM'}>12:30 AM</MenuItem>
                        <MenuItem value={'1:00 AM'}>1:00 AM</MenuItem>
                        <MenuItem value={'1:30 AM'}>1:30 AM</MenuItem>
                        <MenuItem value={'2:00 AM'}>2:00 AM</MenuItem>
                        <MenuItem value={'2:30 AM'}>2:30 AM</MenuItem>
                        <MenuItem value={'3:00 AM'}>3:00 AM</MenuItem>
                        <MenuItem value={'3:30 AM'}>3:30 AM</MenuItem>
                        <MenuItem value={'4:00 AM'}>4:00 AM</MenuItem>
                        <MenuItem value={'4:30 AM'}>4:30 AM</MenuItem>
                        <MenuItem value={'5:00 AM'}>5:00 AM</MenuItem>
                        <MenuItem value={'5:30 AM'}>5:30 AM</MenuItem>
                        <MenuItem value={'6:00 AM'}>6:00 AM</MenuItem>
                        <MenuItem value={'6:30 AM'}>6:30 AM</MenuItem>
                        <MenuItem value={'7:00 AM'}>7:00 AM</MenuItem>
                        <MenuItem value={'7:30 AM'}>7:30 AM</MenuItem>
                        <MenuItem value={'8:00 AM'}>8:00 AM</MenuItem>
                        <MenuItem value={'8:30 AM'}>8:30 AM</MenuItem>
                        <MenuItem value={'9:00 AM'}>9:00 AM</MenuItem>
                        <MenuItem value={'9:30 AM'}>9:30 AM</MenuItem>
                        <MenuItem value={'10:00 AM'}>10:00 AM</MenuItem>
                        <MenuItem value={'10:30 AM'}>10:30 AM</MenuItem>
                        <MenuItem value={'11:00 AM'}>11:00 AM</MenuItem>
                        <MenuItem value={'11:30 AM'}>11:30 AM</MenuItem>
                        <MenuItem value={'12:00 PM'}>12:00 PM</MenuItem>
                        <MenuItem value={'12:30 PM'}>12:30 PM</MenuItem>
                        <MenuItem value={'1:00 PM'}>1:00 PM</MenuItem>
                        <MenuItem value={'1:30 PM'}>1:30 PM</MenuItem>
                        <MenuItem value={'2:00 PM'}>2:00 PM</MenuItem>
                        <MenuItem value={'2:30 PM'}>2:30 PM</MenuItem>
                        <MenuItem value={'Closed'}>Closed</MenuItem>
                    </Select>
                </FormControl>
            </div>

        </Grid>




    </Grid>

)

const AvailabilityView = ({selected_garage}) => {
    const classes = useStyles();

    const [quotes, setQuotes] = useState({});
    const [loading, setLoading] = useState(true);

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleShow = () => setShow(true);
    const handleShowPause = () => setShow1(true);

    const GreenCheckbox = withStyles({
        root: {
            color: "#fecb0a",
            '&$checked': {
                color: "#fecb0a",
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);


    const [state, setState] = useState({
        checkedYes: true,
        checkedNo: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };



    const [hours, setHours] = useState({
        monday: { open: '', close:''},
        tuesday: { open: '', close:''},
        wednesday: { open: '', close:''},
        thursday: { open: '', close:''},
        friday: { open: '', close:''},
        saturday: { open: '', close:''},
        sunday: { open: '', close:''},
    });

    const [availability, setAvailability] = useState({
        max_daily: 1,
        advance: 2,
    })

    const [meta, setMeta] = useState({});


    useEffect(()=>{
        if (!selected_garage) return;

        fetchAuth('/user/CmsGarageAvailability?garage=' + selected_garage)
            .then((resJSON)=>{
                console.log("availability",resJSON)
                if (resJSON.availability.availability) {
                    setAvailability(resJSON.availability.availability)
                } else {
                    setAvailability({
                        max_daily: 1,
                        advance: 2,
                    })
                }

                if (resJSON.availability.hours) {
                    setHours(resJSON.availability.hours)
                } else {
                    setHours({
                            monday: { open: '', close:''},
                            tuesday: { open: '', close:''},
                            wednesday: { open: '', close:''},
                            thursday: { open: '', close:''},
                            friday: { open: '', close:''},
                            saturday: { open: '', close:''},
                            sunday: { open: '', close:''},
                        })
                }

                setMeta({
                    garage_id: resJSON.availability.id,
                    garage_name: resJSON.availability.name,
                })


                setLoading(false)
            })

    }, [selected_garage])

    const onSave = () => {

        if (
            hours.monday.open === '' ||
            hours.monday.close === '' ||
            hours.tuesday.open === '' ||
            hours.tuesday.close === '' ||
            hours.wednesday.open === '' ||
            hours.wednesday.close === '' ||
            hours.thursday.open === '' ||
            hours.thursday.close === '' ||
            hours.friday.open === '' ||
            hours.friday.close === '' ||
            hours.saturday.open === '' ||
            hours.saturday.close === '' ||
            hours.sunday.open === '' ||
            hours.sunday.close === ''
        )
            return toast.error("You must fill in all your hours to update.");
        if (
            (hours.monday.open === 'Closed' && hours.monday.close !== 'Closed') ||
            (hours.monday.open !== 'Closed' && hours.monday.close === 'Closed') ||
            (hours.tuesday.open === 'Closed' && hours.tuesday.close !== 'Closed') ||
            (hours.tuesday.open !== 'Closed' && hours.tuesday.close === 'Closed') ||
            (hours.wednesday.open === 'Closed' && hours.wednesday.close !== 'Closed') ||
            (hours.wednesday.open !== 'Closed' && hours.wednesday.close === 'Closed') ||
            (hours.thursday.open === 'Closed' && hours.thursday.close !== 'Closed') ||
            (hours.thursday.open !== 'Closed' && hours.thursday.close === 'Closed') ||
            (hours.friday.open === 'Closed' && hours.friday.close !== 'Closed') ||
            (hours.friday.open !== 'Closed' && hours.friday.close === 'Closed') ||
            (hours.saturday.open === 'Closed' && hours.saturday.close !== 'Closed') ||
            (hours.saturday.open !== 'Closed' && hours.saturday.close === 'Closed') ||
            (hours.sunday.open === 'Closed' && hours.sunday.close !== 'Closed') ||
            (hours.sunday.open !== 'Closed' && hours.sunday.close === 'Closed')
        )
            return toast.error("You have selected Closed on a day you have chosen an open/close hour.");


        // post request
        // fetchAuth('/user/availability',{
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         garage_id: meta.garage_id,
        //         garage_name: meta.garage_name,
        //         hours: hours,
        //         availability: availability,
        //     })
        // })
        //     .then((resJSON)=>{
        //         toast.success("Availability updated! Your changes will go live within 24 hours.");
        //
        //     })

    }

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Contact Us"
        >
            <Container maxWidth="lg">

                <Card style={{}}>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <Grid container>



                                <Grid item md={6} xs={12}>
                                    <Typography>
                                        <h2>Availability</h2>
                                    </Typography>
                                        <div style={{display:"flex", justifyContent:'flex-end'}}>






                                </div>
                                </Grid>


                            </Grid>

                        </Box>
                    </CardContent>
                </Card>


                <Card style={{marginTop:10}}>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <Grid container>



                                <Grid item md={6} xs={12}>

                                    <Typography>
                                        <h3>Opening Hours</h3>
                                    </Typography>

                                    <div style={{display:'flex', flexDirection: 'column', marginBottom:50, marginRight:20}}>

                                        <HoursSelect
                                            day={'Monday'}
                                            openValue={hours.monday.open}
                                            closeValue={hours.monday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, monday: {...hours['monday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, monday: {...hours['monday'], close: target.value, open: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, monday: {...hours['monday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, monday: {...hours['monday'], open: target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Tuesday'}
                                            openValue={hours.tuesday.open}
                                            closeValue={hours.tuesday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, tuesday: {...hours['tuesday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, tuesday: {...hours['tuesday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, tuesday: {...hours['tuesday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, tuesday: {...hours['tuesday'],open:target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Wednesday'}
                                            openValue={hours.wednesday.open}
                                            closeValue={hours.wednesday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, wednesday: {...hours['wednesday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, wednesday: {...hours['wednesday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, wednesday: {...hours['wednesday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, wednesday: {...hours['wednesday'],open:target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Thursday'}
                                            openValue={hours.thursday.open}
                                            closeValue={hours.thursday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, thursday: {...hours['thursday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, thursday: {...hours['thursday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, thursday: {...hours['thursday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, thursday: {...hours['thursday'],open:target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Friday'}
                                            openValue={hours.friday.open}
                                            closeValue={hours.friday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, friday: {...hours['friday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, friday: {...hours['friday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, friday: {...hours['friday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, friday: {...hours['friday'],open:target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Saturday'}
                                            openValue={hours.saturday.open}
                                            closeValue={hours.saturday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, saturday: {...hours['saturday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, saturday: {...hours['saturday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, saturday: {...hours['saturday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, saturday: {...hours['saturday'],open:target.value, close: target.value}})
                                            }}
                                        />
                                        <HoursSelect
                                            day={'Sunday'}
                                            openValue={hours.sunday.open}
                                            closeValue={hours.sunday.close}
                                            onSelectOpen={({target})=>{
                                                setHours({...hours, sunday: {...hours['sunday'], open: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, sunday: {...hours['sunday'], open:target.value, close: target.value}})
                                            }}
                                            onSelectClose={({target})=>{
                                                setHours({...hours, sunday: {...hours['sunday'], close: target.value}})
                                                if (target.value === 'Closed')
                                                    setHours({...hours, sunday: {...hours['sunday'],open:target.value, close: target.value}})
                                            }}
                                        />

                                    </div>
                                </Grid>

                                <Grid item md={6}  xs={12}>

                                    <Typography>
                                        <h3>Job Capacity</h3>
                                    </Typography>


                                    <div  style={{display:'flex', flexDirection: 'row', justifyContent:'space-between', marginTop:20}}>

                                        <div>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={()=>
                                                {
                                                    handleShow();
                                                }}

                                            >
                                               Edit Your Weekly Capacity
                                            </Button>

                                        </div>

<div>
                                                <Button
                                                    color="secondary"
                                                    variant="secondary"
                                                    onClick={()=>
                                                    {
                                                        handleShowPause();
                                                    }}

                                                >
                                                    Pause Bookings
                                                </Button>
</div>

                                        </div>


                                        <div style={{marginTop:20, padding:20}}>
                                        <Typography id="max-daily-slider" gutterBottom style={{marginBottom:40}}>
                                            Maximum daily jobs
                                        </Typography>

                                        <Slider
                                            aria-labelledby="max-daily-slider"
                                            valueLabelDisplay={"on"}
                                            value={availability.max_daily}
                                            onChange={(e, value)=>{
                                                setAvailability({...availability, max_daily: value})
                                            }}
                                            min={1}
                                            max={10}
                                        />

                                        <Typography id="availability-advance" gutterBottom style={{marginBottom:40}}>
                                            Days in advance needed to book job
                                        </Typography>

                                        <Slider
                                            aria-labelledby="availability-advance"
                                            valueLabelDisplay={"on"}
                                            value={availability.advance}
                                            onChange={(e, value)=>{
                                                setAvailability({...availability, advance: value})
                                            }}
                                            min={1}
                                            max={7}
                                        />
                                        </div>

                                </Grid>
                            </Grid>

                            {/*<Typography>*/}
                            {/*    Contact Us*/}
                            {/*</Typography>*/}


                            <div style={{display:'flex', justifyContent:'flex-end'}}>
                                <Button
                                    color="primary"
                                    variant="contained"

                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>







                        </Box>
                    </CardContent>
                </Card>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>What's your availability looking like this week to take My Auto Shop customers?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            Select your capacity for this week:

                            <div style={{fontSize:12, fontWeight:'normal', fontStyle:'italic', color:'lightslategray', padding:10}}>
                                1 being the least, 10 being the most
                            </div>
                            <Slider
                                style={{paddingTop:60}}
                                aria-labelledby="availability-advance"
                                valueLabelDisplay={"on"}
                                value={availability.advance}
                                onChange={(e, value)=>{
                                    setAvailability({...availability, advance: value})
                                }}
                                min={1}
                                max={10}
                            />
                        </div>

                        <div style={{paddingTop:20}}>
                            Are you available for following day bookings this week?
                            <div style={{fontSize:12, fontWeight:'normal', fontStyle:'italic', color:'lightslategray', padding:10}}>
                                We will send you jobs on a 1 day notice
                            </div>
                            <div>
                            <div style={{display:"flex", justifyContent:'center'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={state.checkedYes}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Yes"
                                />

                                <FormControlLabel
                                    style={{marginLeft:10}}
                                    control={
                                        <Checkbox
                                            checked={state.checkedNo}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="No"
                                />
                            </div>

                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>Confirm</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={show1}
                    onHide={handleClose1}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure you want to temporarily pause bookings?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>



                            <div style={{fontSize:12, fontWeight:'normal', fontStyle:'italic', color:'lightslategray', padding:10}}>
                                For 24 hours, you will receive no more bookings from My Auto Shop.

                            </div>



                            <div>
                                <div style={{display:"flex", justifyContent:'center'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={state.checkedYes}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="Yes"
                                    />

                                    <FormControlLabel
                                        style={{marginLeft:10}}
                                        control={
                                            <Checkbox
                                                checked={state.checkedNo}
                                                onChange={handleChange}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label="No"
                                    />
                                </div>

                            </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose1}>Confirm</Button>
                    </Modal.Footer>
                </Modal>

            </Container>
        </Page>

    // weekly availability modal





    );
};

export default AvailabilityView;
