import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField,FormControl,Input,InputLabel, InputBase,fade, withStyles,
    FormControlLabel,Switch,
    Select, MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import {PricingInput} from "./components/PricingInput";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {MergeTypeRounded} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import {BiQuestionMark} from "react-icons/all";

import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Adjustment from "./AdjustmentView";
import InputSlider from "./components/InputSlider";



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));



const NewPricingView = ({selected_garage}) => {
    const classes = useStyles();

    const [pricing, setPricing] = useState({});
    const [fixedPricing, setFixedPricing] = useState({});
    const [preferences, setJobPreferences] = useState({});
    const [loading, setLoading] = useState(true);

    // const [checked, setChecked] = useState(false);
    // const [checked, setChecked] = useState(true);
    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };

    const [state, setState] = useState({
        checkedYes: true,
        checkedNo: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    // let preferences = {
    //     front_brake_pads:jobPreferences.front_brake_pads,
    //     rear_brake_pads:jobPreferences.rear_brake_pads,
    //     rotors:jobPreferences.rotors,
    //     spark_plugs:jobPreferences.spark_plugs,
    //     pads_rotors:jobPreferences.pads_rotors,
    //     wipers:jobPreferences.wipers,
    //     headlight_restoration:jobPreferences.headlight_restoration,
    //     tyre_fit:jobPreferences.tyre_fit,
    //     bulb_replacement:jobPreferences.bulb_replacement
    // }

    const [noPricing, setNoPricing] = useState(false);



    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    const [region, setRegion] = useState('Auckland');


    // const regionData = {
    //     'Auckland': ['$97.12', '$13.95', '$17.95', '$17.99', '$25.99', '$15.00', '$53.87', '$97.12', '$169.00', '$89.00'],
    //     'Metro': ['$92.49', '$13.95', '$17.95', '$17.99', '$25.99', '$15.00', '$60.66', '$92.49', '$169.00', '$89.00'],
    //     'Towns': ['$90.08', '$13.95', '$17.95', '$17.99', '$25.99', '$15.00', '$63.33', '$90.08', '$169.00', '$89.00'],
    // };

    const regionData = {
        'Auckland': ['$97.12','$15.00', '$13.95', '$17.95', '$17.99', '$25.99',  '$53.87', '$97.12', '$169.00', '$89.00', '$329.00', '$77.45', '129.99'],
        'Metro': ['$92.49','$15.00', '$13.95', '$17.95', '$17.99', '$25.99',  '$60.66', '$92.49', '$169.00', '$89.00', '$329.00', '$77.45', '129.99'],
        'Towns': ['$90.08','$15.00', '$13.95', '$17.95', '$17.99', '$25.99',  '$63.33', '$90.08', '$169.00', '$89.00', '$329.00', '$77.45', '129.99'],
    };


    const [adjustmentPricing, setAdjustmentPricing] = useState({});
    useEffect(()=>{
        if (!selected_garage) return;
        fetchAuth('/pricing/adjustment?garage=' + selected_garage)
            .then((resJSON)=>{
                setAdjustmentPricing(resJSON.adjustment)
                setLoading(false)
            })

    }, [selected_garage])

    const onSaveMarginAdjustments = () => {
        fetchAuth('/pricing/adjustment?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:adjustmentPricing})
        })
            .then((resJSON)=>{
                toast.success("Margin Adjustments Saved!");
            })
    }


    useEffect(()=>{
        if (!selected_garage) return;
        fetchAuth('/pricing/labour?garage=' + selected_garage)
            .then((resJSON)=>{
                setPricing(resJSON.labour)

                if (resJSON.labour?.length===0)
                    setNoPricing(true);
                setLoading(false)
            })

        // fixed price products
        fetchAuth('/pricing/fixed?garage=' + selected_garage)
            .then((resJSON)=>{
                setFixedPricing(resJSON.fixed)

            })
        //
        // // job preferences
        fetchAuth('/pricing/preferences?garage=' + selected_garage)
            .then((resJSON)=>{
                setJobPreferences(resJSON.preferences)
                setLoading(false)


            })

    }, [selected_garage])

    const onSave = () => {

        // service pricing
        fetchAuth('/pricing/labour?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:pricing})
        })
            .then((resJSON)=>{
                toast.success("Service Pricing Saved!");

            })

        // fiexd price products
        fetchAuth('/pricing/fixed?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:fixedPricing})
        })
            .then((resJSON)=>{
                toast.success("Fixed Pricing Saved!");

            })

        // job preferences
        fetchAuth('/pricing/labour?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:preferences})
        })
            .then((resJSON)=>{
                toast.success("Preferences Saved!");

            })


        fetchAuth('/user/logevent?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, event: "PRICING_UPDATED"})
            })
            .then((resJSON)=>{
            })








    }

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Pricing"
        >
            <Container maxWidth="lg">



                <Grid item xs={12} style={{paddingBottom:10}}>

                                                <Grid container xs={12} spacing={2}
                                                >


                                                    <Grid item md={6}>

                                                        <Paper className={classes.paper}>
                                                            <div style={{
                                                                display:'flex',
                                                                flexDirection: 'row',
                                                                padding:20,
                                                                textAlign:'left',
                                                                justifyContent:'left',
                                                                alignItems:'center'
                                                            }}>



                                                        <div style={{display:"flex", flexDirection:'column'}}>

                                                            <div>
                                                        <Typography>
                                                            <p>
                                                                Select your region to see your region averages:

                                                            </p>
                                                        </Typography>



                                                            </div>


                                                            <div>
                                                        <FormControl>
                                                            <InputLabel id="region-select-label">Region</InputLabel>
                                                            <Select
                                                                labelId="region-select-label"
                                                                value={region}
                                                                onChange={({target})=>setRegion(target.value)}
                                                            >
                                                                <MenuItem value={'Auckland'}>Auckland</MenuItem>
                                                                <MenuItem value={'Metro'}>NZ Metro Cities (excl. Auckland)</MenuItem>
                                                                <MenuItem value={'Towns'}>NZ Towns & Rural</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                            </div>



                                                        </div>


</div>
</Paper>

                                                    </Grid>

                                                    <Grid item md={6}>

                                                        {/*<Paper className={classes.paper}>*/}
                                                            <div style={{
                                                                display:'flex',
                                                                flexDirection: 'row',
                                                                padding:20,
                                                                justifyContent:'flex-end',
                                                            }}>






                                                            <div style={{display:'flex', flexDirection:'column'}}>
                                                            <div>
                                                                <Button variant="contained" color="primary" onClick={onSave} style={{padding:10}}>Save Pricing</Button>
                                                            </div>


                                                                {/*<div style={{fontSize: 12, fontColor: 'grey', marginTop:10, fontStyle:'italic'}}>*/}
                                                                {/*   Pricing Last Modified:  {moment(pricing.last_modified).format('DD/MM/YYYY')}*/}
                                                                {/*</div>*/}
                                                            </div>




                                                            </div>
                                                        {/*</Paper>*/}


                                                    </Grid>




                                                </Grid>


                </Grid>


                <Grid container xs={12} spacing={1}>

                    <Grid item md={6}>
                        <Card style={{height:"100%"}}>
                            <Box style={{padding:10}}>
                                <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                    Set your labour rate:
                                </div>
                                <div>

                                    {[
                                        {
                                            name: 'labour-hourly',
                                            title: 'Labour Hourly Rate',
                                            state: 'labour_rate'
                                        },

                                        {
                                            name: 'parts-consumable',
                                            title: 'Consumables (per job)',
                                            state: 'consumables'

                                        }
                                    ].map((item, index)=>

                                        <Grid container spacing={3}>

                                            <Grid item xs={8}>
                                                <PricingInput
                                                    required
                                                    type="number"
                                                    step="0.01"
                                                    name={item.name}
                                                    title={item.title}
                                                    value={pricing[item.state]}
                                                    onChange={({target})=>{
                                                        setPricing({...pricing, [item.state]: target.value})
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={2}
                                                  style={{
                                                      display: 'flex',
                                                      alignItems:'center',
                                                  }}
                                            >

                                                {!noPricing?
                                                    <Typography style={{fontSize:12, color:((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                        {((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                    </Typography>
                                                    : null
                                                }
                                            </Grid>
                                            <Grid item xs={2}
                                                  style={{
                                                      display: 'flex',
                                                      alignItems:'center',
                                                  }}>
                                                <Typography style={{fontSize:12}}>
                                                    {regionData[region][index]}
                                                </Typography>
                                            </Grid>

                                        </Grid>


                                    )


                                    }


                                </div>


                            </Box>
                        </Card>

                    </Grid>

                    <Grid item md={6}>
                        <Card style={{height:"100%"}}>



                                <Box
                                    style={{padding:10}}
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                        Adjust labour time and part markup:
                                    </div>

                                    <Box   style={{padding:10}}>
                                    <InputSlider
                                        title={"Labour Margin Adjustment"}
                                        value={adjustmentPricing.labour_adjustment}
                                        setValue={(value)=>{
                                            setAdjustmentPricing({...adjustmentPricing, labour_adjustment: value})
                                        }}
                                        min={-50}
                                        max={50}
                                    />

                                    <InputSlider
                                        title={"Parts Margin Adjustment"}
                                        value={adjustmentPricing.parts_adjustment}
                                        setValue={(value)=>{
                                            setAdjustmentPricing({...adjustmentPricing, parts_adjustment: value})
                                        }}
                                        min={0}
                                        max={200}
                                    />
                                        <div>
                                            <Button variant="contained" color="primary" onClick={onSaveMarginAdjustments}>Save</Button>

                                        </div>
                                    </Box>



                                </Box>

                        </Card>

                    </Grid>


                </Grid>



                    <Grid container xs={12} spacing={1}>
                        <Grid item md={4}>
                            <Card>
                                <Box style={{padding:10}}>
                                    <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                        Fixed Price Products

                                        <div style={{fontWeight:'normal', color:'grey', fontSize: 12, paddingTop:10, paddingBottom:15}}>
                                            Price your common jobs.
                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end',fontWeight:'normal', fontSize:10}}>
                                            <div>
                                                Region Average
                                            </div>
                                        </div>
                                        <div>

                                            {[
                                                {
                                                    name: 'wof',
                                                    title: 'WoF',
                                                    state: 'wof'
                                                },
                                                {
                                                    name: 'diagnostic',
                                                    title: 'Diagnostic',
                                                    state: 'diagnostic'
                                                },
                                                {
                                                    name: 'pre_purchase',
                                                    title: 'Pre-Purchase Inspection',
                                                    state: 'pre_purchase'
                                                },
                                                {
                                                    name: 'wheel-alignment',
                                                    title: 'Wheel Alignment',
                                                    state: 'wheel_alignment'
                                                }
                                                ,
                                                {
                                                    name: 'wheel-balance',
                                                    title: 'Wheel Balance',
                                                    state: 'wheel_balance'
                                                },
                                                {
                                                    name: 'hybrid-battery-test',
                                                    title: 'Hybrid Battery Test',
                                                    state: 'hybrid_battery_test'
                                                },

                                            ].map((item, index)=>

                                                <Grid container spacing={3}>

                                                    <Grid item xs={8}>
                                                        <PricingInput
                                                            required
                                                            type="number"
                                                            step="0.01"
                                                            name={item.name}
                                                            title={item.title}
                                                            value={fixedPricing[item.state]}
                                                            onChange={({target})=>{
                                                                setFixedPricing({...fixedPricing, [item.state]: parseFloat(target.value)})
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}
                                                    >
                                                        {!noPricing?
                                                            <Typography style={{fontSize:12, color:((parseFloat(fixedPricing[item.state]) - parseFloat(regionData[region][index+7].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+7].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                                {((parseFloat(fixedPricing[item.state]) - parseFloat(regionData[region][index+7].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+7].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                            </Typography>
                                                            : null
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}>
                                                        <Typography style={{fontSize:12}}>
                                                            {regionData[region][index+7]}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>


                                            )


                                            }



                                        </div>


                                        <div style={{fontWeight:'bold', fontSize: 16, padding:10}}>
                                            Other Services

                                            <div style={{fontWeight:'normal', color:'grey', fontSize: 12, paddingTop:10, paddingBottom:10}}>Choose & price what other add-ons you offer.</div>

                                            <div>

                                                {[

                                                    {
                                                        name: 'transmission-flush',
                                                        title: 'Transmission Flush',
                                                        state: 'transmission_flush'
                                                    },
                                                    {
                                                        name: 'brake-flush',
                                                        title: 'Brake Flush',
                                                        state: 'brake_flush'
                                                    }

                                                ].map((item, index)=>

                                                    <Grid container spacing={3}>

                                                        <Grid item xs={8}>
                                                            <PricingInput
                                                                required
                                                                type="number"
                                                                step="0.01"
                                                                name={item.name}
                                                                title={item.title}
                                                                value={fixedPricing[item.state]}
                                                                onChange={({target})=>{
                                                                    setFixedPricing({...fixedPricing, [item.state]: target.value})
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={2}
                                                              style={{
                                                                  display: 'flex',
                                                                  alignItems:'center',
                                                              }}
                                                        >
                                                            {!noPricing?
                                                                <Typography style={{fontSize:12, color:((parseFloat(fixedPricing[item.state]) - parseFloat(regionData[region][index+10].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+10].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                                    {((parseFloat(fixedPricing[item.state]) - parseFloat(regionData[region][index+10].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+10].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                                </Typography>
                                                                :null}
                                                        </Grid>
                                                        <Grid item xs={2}
                                                              style={{
                                                                  display: 'flex',
                                                                  alignItems:'center',
                                                              }}>
                                                            <Typography style={{fontSize:12}}>
                                                                {regionData[region][index+10]}
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>


                                                )


                                                }

                                            </div>

                                        </div>
                                    </div>
                                </Box>
                            </Card>

                        </Grid>
                        <Grid item md={4}>
                            <Card  style={{height:"100%"}}>
                                <Box style={{padding:10}}>
                                    <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                        Service Roll Back Pricing

                                        <div style={{fontWeight:'normal', color:'grey', fontSize: 12, paddingTop:10, paddingBottom:10}}>
In the instance our intelligent parts data tool can't find the right oil & filter for a service, please enter average pricing for Japanese & Euro vehicles.                                        </div>

                                        <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end',fontWeight:'normal', fontSize:10}}>
                                            <div>
                                                Region Average
                                            </div>
                                        </div>



                                        <div style={{fontWeight:'bold', fontSize: 16, padding:10}}>
                                            Oil Costs (Per Litre)



                                        </div>

                                        <div>

                                            {[

                                                {
                                                    name: 'oilcost-standard',
                                                    title: 'Standard Oil Cost (per litre)',
                                                    state: 'standard_oil_cost'
                                                },
                                                {
                                                    name: 'oilcost-euro',
                                                    title: 'Euro Oil Cost (per litre)',
                                                    state: 'euro_oil_cost'
                                                }
                                            ].map((item, index)=>

                                                <Grid container spacing={3}>

                                                    <Grid item xs={8}>
                                                        <PricingInput
                                                            required
                                                            type="number"
                                                            step="0.01"
                                                            name={item.name}
                                                            title={item.title}
                                                            value={pricing[item.state]}
                                                            onChange={({target})=>{
                                                                setPricing({...pricing, [item.state]: target.value})
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}
                                                    >
                                                        {!noPricing?
                                                            <Typography style={{fontSize:12, color:((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index+2].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+2].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                                {((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index+2].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+2].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                            </Typography>
                                                            :null }
                                                    </Grid>
                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}>
                                                        <Typography style={{fontSize:12}}>
                                                            {regionData[region][index+2]}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>


                                            )


                                            }


                                        </div>
                                        <div style={{fontWeight:'bold', fontSize: 16, padding:10}}>
                                            Oil Filters
                                        </div>

                                        <div>

                                            {[

                                                {
                                                    name: 'oilfilter-standard',
                                                    title: 'Standard Oil Filter',
                                                    state: 'standard_oil_filter'
                                                },
                                                {
                                                    name: 'oilfilter-euro',
                                                    title: 'Euro Oil Filter',
                                                    state: 'euro_oil_filter'
                                                }


                                            ].map((item, index)=>

                                                <Grid container spacing={3}>

                                                    <Grid item xs={8}>
                                                        <PricingInput
                                                            required
                                                            type="number"
                                                            step="0.01"
                                                            name={item.name}
                                                            title={item.title}
                                                            value={pricing[item.state]}
                                                            onChange={({target})=>{
                                                                setPricing({...pricing, [item.state]: target.value})
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}
                                                    >
                                                        {!noPricing?
                                                            <Typography style={{fontSize:12, color:((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index+4].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+4].replace(/[$|%]/g, ''))  *100)>0?'green':'red'}}>
                                                                {((parseFloat(pricing[item.state]) - parseFloat(regionData[region][index+4].replace(/[$|%]/g, ''))) / parseFloat(regionData[region][index+4].replace(/[$|%]/g, ''))  *100).toFixed(2)}%
                                                            </Typography>
                                                            : null

                                                        }
                                                    </Grid>
                                                    <Grid item xs={2}
                                                          style={{
                                                              display: 'flex',
                                                              alignItems:'center',
                                                          }}>
                                                        <Typography style={{fontSize:12}}>
                                                            {regionData[region][index+4]}
                                                        </Typography>
                                                    </Grid>

                                                </Grid>


                                            )


                                            }


                                        </div>



                                    </div>



                                </Box>
                            </Card>
                        </Grid>

                    <Grid item md={4}>
                        <Card style={{height:"100%"}}>
                            <Box style={{padding:10}}>
                                <div style={{width:"100%", height:"100%"}}>
                            <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                Opt in

                            <div style={{fontWeight:'normal', color:'grey', fontSize: 12, paddingTop:10, paddingBottom:10}}>
                                Choose what work you want to receive.
                            </div>







                                <div style={{fontWeight:'normal', fontSize: 16, width:"100%"}}>

                                    <div style={{display:"flex", justifyContent:'space-between'}}>
                                        <div>
                                            Tyre Fitting
                                        </div>
                                        <div>
                                            <Checkbox
                                                defaultChecked={preferences.tyre_fit?"on":false}
                                                onChange={(e)=>setJobPreferences({...preferences, tyre_fit: e.target.checked?1:0})}
                                                // onChange={handleChange}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Front Brake Pad Replacements*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.front_brake_pads}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, front_brake_pads: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Rear Brake Pad Replacements*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.rear_brake_pads}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, rear_brake_pads: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Rotor Replacements*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.rotors}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, rotors: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Pads & Rotor Replacements*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.pads_rotors}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, pads_rotors: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Wiper Replacements*/}

                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        /!*{preferences.wipers}*!/*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.wipers}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, wipers: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Bulbs*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.bulb_replacement}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, bulb_replacement: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Headlight Restoration*/}
                                    {/*      </div>*/}
                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.headlight_restoration}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, headlight_restoration: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div style={{display:"flex", justifyContent:'space-between'}}>*/}
                                    {/*    <div>*/}
                                    {/*        Spark Plugs*/}
                                    {/*    </div>*/}

                                    {/*    <div>*/}
                                    {/*        <Checkbox*/}
                                    {/*            defaultChecked={preferences.spark_plugs}*/}
                                    {/*            // checked={}*/}
                                    {/*            onChange={(e)=>setJobPreferences({...preferences, spark_plugs: e.target.checked?1:0})}                                                inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*            inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                                </div>
                            </Box>
                        </Card>

                    </Grid>

                    {/*test*/}

                    </Grid>





            </Container>
        </Page>
    );
};

export default NewPricingView;
