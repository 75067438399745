import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Select from "react-select";
import moment from "moment";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Chip,
    Divider,
    TableContainer,
    TableBody,
    Table,
    Input,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Grid,
    Paper,
    Tooltip,
    makeStyles, Container, TablePagination, TextField
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Loading from "../../components/Loading";
import {useParams} from 'react-router-dom';
import {fetchAuth, fetchAPI} from "../../utils/fetch";
import { toast } from 'react-toastify';
import {useNavigate} from "react-router";
import Modal from "react-bootstrap/Modal";




const PromotionsListView = ({selected_garage, className, props}) => {
    const [loading, setLoading] = useState(true);

    const {id} = useParams();


    const navigate=useNavigate();
    const [promotion, setPromotion] = useState({
        title: null,
        code: null,
        start_date: null,
        end_date: null,
        type: 1,
        discount_amount: null,
        minimum_spend: null,
        max_uses: null,
        number_of_codes:null,
    });

    const [servicesList, setServicesList] = useState([])

    const [tieredPromotion, setTieredPromotion] = useState([]    )


    const [tieredInput, setTieredInput] = useState({
        minimum_spend: '',
        discount_amount: '',
        maximum_spend: '',

    })


    const [options, setOptions] = useState([])

    const [selectedExcludeService, setSelectedExcludeService] = useState()

    const [valid, setValid] = useState(null)
    const [selectedGarages, setSelectedGarages] = useState([])

    useEffect(()=>{

        if (promotion.number_of_codes <= (62*62*62)) {
            setValid(true)
        }

    }, [promotion.number_of_codes])

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const handleClose = () => setShowModal(false);
    const handleShow= () => setShowModal(true);

    const [disableAllPromos, setDisableAllPromos] = useState(false);


    const togglePromo = (promo, disableAll) => {


        // check length of promoList - filter and check the is_enabled condition

        if (promoList.filter(p=>p.is_enabled===1).length > 1) {


            handleShow()
            setModalData(promo)


                if (disableAll) {
                    fetchAuth('/promotions/toggle-all-promos?id=' + promo.id + '&garage=' + selected_garage)
                        .then(() => {
                            toast.success(promo.code + ' promotion is now live')
                            fetchPromos()
                            handleClose()

                        })
                }


        } else {
            fetchAuth('/promotions/toggle?id=' + promo.id)
                .then(() => {
                    toast.success(promo.title + 'toggled')
                    fetchPromos()
                    handleClose()
                })
        }





    }


    const editPromo = (promo) => {
        navigate('/app/promotions/edit/' + promo.id);
    }

    const deletePromo = (promo) => {


        setLoading(true)

        fetchAuth('/promotions/delete?id=' + promo.id)
            .then(()=>{
                toast.success(promo.title + ' promotion deleted')
                    fetchPromos()


            })

    }


    const removeExcludedServices = (id) => {
        const p = {...promotion}
        p.data.excluded_service.splice(p.data.excluded_service.indexOf(id),1)
        setPromotion(p)
    }

    const onInputChange = ({target: {value, name}})=>setPromotion({...promotion, [name]: value})
    const onDateChange = (event)=>setPromotion({...promotion, start_date: typeof event.target.value==='string'?null:(event.target.value.format('YYYY-MM-DD'))})





    // onChange={(date)=>setPromotion({...promotion, start_date: typeof date === 'string'?null:(.toISOString().split('T')[0]('YYYY-MM-DD'))})}

    const onTieredInputChange = ({target: {value, name}})=>{
        setTieredInput({...tieredInput, [name]: parseFloat(value)})
    }




    const toggleNavs = (e, index) => {


        e.preventDefault();
        setPromotion({
            ...promotion, type: index
        });
    };

    const onSelectChange = ({value}) => {
        setSelectedExcludeService(value.toString())
    }

    const [generateCodeButton, setGenerateCodeButton] = useState(true)
    const [numberOfCodes, setNumberofCodes] = useState(null)

    const [garages, setGarages] = useState([]);




    const onAddExcludeService = () => {
        if(selectedExcludeService){
            const p = {...promotion}
            if(promotion.data && promotion.data.excluded_service) {
                if(!p.data.excluded_service.includes(selectedExcludeService)) {
                    p.data.excluded_service.push(selectedExcludeService)
                    setPromotion(p)
                }
            }
            else{
                p.data = {excluded_service:[selectedExcludeService]}
                setPromotion(p)
            }
        }

    }



    const fetchPromos = () => {
        fetchAuth('/promotions/garage-funded-promos?garage=' + selected_garage)
            .then(resJSON=> {

                setPromoList(resJSON.garagePromos)

                console.log(resJSON.garagePromos, "g")
            })
            .then(() => {
                setLoading(false)
        })

    }


    const [promoList, setPromoList] = useState([])
    useEffect(()=>{
        if (!selected_garage) return;
        setLoading(true)

        fetchPromos()

    }, [selected_garage])



    if (loading)
        return <Loading/>

    return (



        <div>

            {loading ?
                <div>
                    <Loading></Loading>
                </div> :
                <div>


                    <Container maxWidth={true} style={{paddingTop: 20}}>
                        <Card style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            padding:10,
                            alignItems:'center',
                            alignContent:'center'
                        }}>

                            <CardHeader
                                titleTypographyProps={{style: {fontWeight: 'bold', fontSize: 18, fontFamily: 'Gilroy'}}}
                                title="Your Promotions"></CardHeader>


                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                navigate('/app/promotions/new');
                            }}>
                                Add New
                            </Button>




                        </Card>


                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 650}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Title</TableCell>
                                            <TableCell align="left">Code</TableCell>
                                            <TableCell align="left">Type</TableCell>
                                            <TableCell align="left">Discount</TableCell>
                                            <TableCell align="left">Uses</TableCell>
                                            <TableCell align="left">Start Date</TableCell>
                                            <TableCell align="left">End Date</TableCell>
                                            <TableCell align="left">Is Enabled?</TableCell>
                                            <TableCell align="left">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {promoList.map((garagePromo) => (
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {garagePromo.title}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {garagePromo.code}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {garagePromo.type === 1 ? "Fixed" : garagePromo.type === 3 ? "Percentage" : "Bucket"}
                                                </TableCell>
                                                {garagePromo.type === 1 ?
                                                    <TableCell component="th" scope="row">
                                                        {"$" + (garagePromo.discount_amount).toFixed(2)}
                                                    </TableCell> :
                                                    <TableCell component="th" scope="row">
                                                        {(garagePromo.discount_percentage).toFixed(2) + "%"}
                                                    </TableCell>
                                                }
                                                <TableCell component="th" scope="row">
                                                    {garagePromo.uses}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {moment(garagePromo.start_date).format('DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {moment(garagePromo.end_date).format('DD-MM-YYYY')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {garagePromo.is_enabled === 1 ? "Y" : "N"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Button onClick={() => {
                                                        togglePromo(garagePromo)
                                                    }}>Enable</Button>
                                                    <Button onClick={() => {
                                                        deletePromo(garagePromo)
                                                    }}
                                                    >Delete</Button>

                                                    <Button
                                                        onClick={() => {
                                                            editPromo(garagePromo)
                                                        }}

                                                    >Edit</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>


                    </Container>
                </div>

            }
            <Modal
                show={showModal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>An error occured</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{padding:20}}>
                        You may only have one promotion running on the My Auto Shop website for customers.
<br/>
                        Selecting 'Confirm' will disable all other promotions.


                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" color={'primary'} onClick={() => {
                        togglePromo(modalData, true)
                    }}>Confirm</Button>
                    <Button variant="primary" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </div>


    );
};

PromotionsListView.propTypes = {
    className: PropTypes.string
};

export default PromotionsListView;
