import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Select from "react-select";
import moment from "moment";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    Input,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Grid,
    Paper,
    Tooltip,
    makeStyles, Container, TablePagination, TextField, Checkbox, FormControlLabel
} from '@material-ui/core';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Loading from "../../components/Loading";
import {useParams} from 'react-router-dom';
import {fetchAuth, fetchAPI, fetchAuthBlob} from "../../utils/fetch";
import { toast } from 'react-toastify';





const PromotionsView = ({selected_garage,  newPromotion, className}) => {
    const [loading, setLoading] = useState(true);

    const {id} = useParams();




    const [promotion, setPromotion] = useState({
        title: null,
        code: null,
        start_date: null,
        end_date: null,
        type: 1,
        discount_amount: null,
        minimum_spend: null,
        max_uses: null,
        number_of_codes:10000,
    });


    const generateUniquePromoCodes = () => {




        fetchAuthBlob('/promotions/generate-unique', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: promotion.code?promotion.code:null
            })
        })
            .then((file)=>{
                console.log(file)
                window.location.assign(file);
                toast.success(`${promotion.number_of_codes} codes successfully generated.`)
                setGenerateCodeButton(true)
            })





    }



    const [servicesList, setServicesList] = useState([])

    const [tieredPromotion, setTieredPromotion] = useState([]    )


    const [tieredInput, setTieredInput] = useState({
        minimum_spend: '',
        discount_amount: '',
        maximum_spend: '',

    })


    const [options, setOptions] = useState([])

    const [selectedExcludeService, setSelectedExcludeService] = useState()

    const [valid, setValid] = useState(false)
    const [selectedGarages, setSelectedGarages] = useState([])

    useEffect(()=>{

        if (promotion.number_of_codes <= (62*62*62)) {
            setValid(true)
        } else setValid(false)

    }, [promotion.number_of_codes])



    const removeExcludedServices = (id) => {
        const p = {...promotion}
        p.data.excluded_service.splice(p.data.excluded_service.indexOf(id),1)
        setPromotion(p)
    }

    const onInputChange = ({target: {value, name}})=>setPromotion({...promotion, [name]: value})
    const onDateChange = (event)=>setPromotion({...promotion, start_date: typeof event.target.value==='string'?null:(event.target.value.format('YYYY-MM-DD'))})





    // onChange={(date)=>setPromotion({...promotion, start_date: typeof date === 'string'?null:(.toISOString().split('T')[0]('YYYY-MM-DD'))})}

    const onTieredInputChange = ({target: {value, name}})=>{
        setTieredInput({...tieredInput, [name]: parseFloat(value)})
    }



    const toggleNavs = (e, index) => {


        e.preventDefault();
        setPromotion({
            ...promotion, type: index
        });
    };

    const onSelectChange = ({value}) => {
        setSelectedExcludeService(value.toString())
    }

    const [generateCodeButton, setGenerateCodeButton] = useState(true)
    const [numberOfCodes, setNumberofCodes] = useState(null)

    const [garages, setGarages] = useState([]);


    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };



    const onAddExcludeService = () => {
        if(selectedExcludeService){
            const p = {...promotion}
            if(promotion.data && promotion.data.excluded_service) {
                if(!p.data.excluded_service.includes(selectedExcludeService)) {
                    p.data.excluded_service.push(selectedExcludeService)
                    setPromotion(p)
                }
            }
            else{
                p.data = {excluded_service:[selectedExcludeService]}
                setPromotion(p)
            }
        }

    }

    useEffect(()=>{

        fetchAuth('/promotions/services-list')
            .then(data => {


                if (data.data){
                    setServicesList(data.data)
                    data.data.map(d => {
                        options.push({value:d.id, label:d.title})
                    })
                }

            })

        setPromotion({data:{excluded_service:[]}})






        if (newPromotion) return;
        setLoading(true)
        fetchAuth('/promotions/promotions-edit?id=' + id)
            .then(data=>{




                if (data.promotion.bucket_data){
                    data.promotion.bucket_data = JSON.parse(data.promotion.bucket_data)
                }
                if(data.promotion.data){
                    data.promotion.data = JSON.parse(data.promotion.data)
                }
                setPromotion(data.promotion);
                setTieredPromotion(data.promotion.bucket_data?data.promotion.bucket_data:[]);


            })
            .then(()=>{
                setLoading(false)

            })





    }, [id])




    useEffect(()=>{
        if (!selected_garage) return;
        setLoading(false)


    }, [selected_garage])

    if (loading)
        return <Loading/>

    const addPromoGarages = (id) => {



        selectedGarages.push(parseInt(id))



    }



    return (

        <Container maxWidth={false} style={{paddingTop: 20}}>
            <Card>

                <CardHeader
                    titleTypographyProps={{style: {fontWeight: 'bold', fontSize: 18, fontFamily: 'Gilroy'}}}
                    title={newPromotion?"Add New Promotion":"Edit Promotion"}></CardHeader>

                <div style={{padding:20}}>

                    <div style={{marginBottom:10}}>

                        <TextField
                            value={promotion.title}
                            name={'title'}
                            label={"Title"}
                            onChange={onInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>


                    </div>


                    <div>

                        <TextField
                            disabled={!newPromotion}
                            value={promotion.code}
                            name={'code'}
                            label={"Code"}
                            onChange={onInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            Input
                        </TextField>


                    </div>




                    <div style={{display:'flex', marginTop:10, flexDirection:'column', justifyContent:'space-between', fontWeight:'bold'}}>


                        <div style={{marginBottom:10}}>
                        Promotion Period
                        </div>
                        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', maxWidth:360}}>

                        <div >


                            <TextField
                                id="date"
                                label="Start Date"
                                type="date"

                                sx={{ width: 220 }}

                                value={promotion.start_date?moment(promotion.start_date).format('YYYY-MM-DD'):null}

                                onChange={(e)=>setPromotion({...promotion, start_date: e.target.value})}


                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>


                        <div>
                            <TextField
                                id="date"
                                label="End Date"
                                type="date"
                                value={promotion.end_date?moment(promotion.end_date).format('YYYY-MM-DD'):null}

                                onChange={(e)=>setPromotion({...promotion, end_date: e.target.value})}

                                defaultValue={new Date()}
                                sx={{ width: 220 }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        </div>

                    </div>

                    <div>

                    <ButtonGroup style={{marginTop:30, marginBottom:30 , boxShadow:'none'}} variant="contained" aria-label="outlined primary button group">
                        <Button

                            style={{backgroundColor: promotion.type===1?'#fecb0a':'lightgray', margin:8}}
                            onClick={e => toggleNavs(e, 1)}
                            role="tab"
                        >Fixed</Button>
                        <Button  aria-selected={promotion.type === 3}
                                 style={{backgroundColor: promotion.type===3?'#fecb0a':'lightgray', margin:8}}
                                 onClick={e => toggleNavs(e, 3)}
                                 role="tab">Percentage</Button>
                        {/*<Button*/}
                        {/*    aria-selected={promotion.type === 2}*/}
                        {/*    style={{backgroundColor: promotion.type===2?'#fecb0a':'lightgray', margin:8}}*/}
                        {/*    onClick={e => toggleNavs(e, 2)}*/}
                        {/*    role="tab"*/}

                        {/*>Bucket</Button>*/}
                    </ButtonGroup>
                    </div>


                    {promotion.type===1?
                        <div style={{marginBottom:10}}>

                            <TextField
                                value={promotion.discount_amount}
                                name={'discount_amount'}
                                label={"Fixed Amount"}
                                type={'number'}
                                onChange={onInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </TextField>


                        </div>
                    :
                    null}

                    {promotion.type===3?
                        <div style={{marginBottom:10}}>

                            <TextField
                                value={promotion.discount_percentage}
                                name={'discount_percentage'}
                                type={'number'}
                                label={"Percentage Discount"}
                                onChange={onInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </TextField>


                        </div>
                        :
                        null}


                    {promotion.type===2?
                        <div style={{marginBottom:10}}>
                            <TextField
                                value={promotion.discount_percentage}
                                name={'    Tiered Promotion'}
                                label={"    Tiered Promotion"}
                                onChange={onInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                            </TextField>
                        </div>
                        :
                        null
                    }

                    <div style={{marginBottom:10}}>
                        <TextField
                            value={promotion.minimum_spend}
                            name={'minimum_spend'}
                            label={"Minimum Spend"}
                            onChange={onInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>
                    </div>
                    <div style={{marginBottom:10}}>
                        <TextField
                            value={promotion.max_uses}
                            name={'max_uses'}
                            label={"Max Uses"}
                            onChange={onInputChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>
                    </div>
                    {/*<div style={{marginBottom:10, display:'flex', justifyContent:'space-between', flexDirection:'row', width:400}}>*/}

                    {/*    <TextField*/}
                    {/*        value={promotion.number_of_codes}*/}
                    {/*        name={'number_of_codes'}*/}
                    {/*        type={"number"}*/}
                    {/*        sx={{ width: 220 }}*/}
                    {/*        label={"Number of Codes"}*/}
                    {/*        onChange={onInputChange}*/}
                    {/*        InputLabelProps={{*/}
                    {/*            shrink: true,*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*    </TextField>*/}
                    {/*    <div>*/}
                    {/*        <Button onClick={()=>{*/}

                    {/*            generateUniquePromoCodes()*/}
                    {/*        }} disabled={generateCodeButton} color={'primary'} variant={'outlined'}>*/}
                    {/*            Generate {numberOfCodes?promotion.number_of_codes:null} Codes*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}





                    {/*</div>*/}



                    <FormControlLabel control={<Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} label="Enable?" />


                    <div style={{marginBottom:10}}>
                        <div style={{marginBottom:10}}>
                            Exclude Services
                        </div>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', flexDirection:'row', alignContent:'center', alignItems:'center'}}>

                            <Select onChange={onSelectChange} options={options}></Select>
                            <div md="3"><Button onClick={onAddExcludeService} style={{padding:8, width:180}}>Add Excluded Services</Button></div>
                            </div>

                            {promotion.data&&promotion.data.excluded_service&&promotion.data.excluded_service.length!=0?promotion.data.excluded_service.map(es => {
                                return <div>{servicesList.length!=0?<p>{servicesList.find(sl => sl.id === parseInt(es)).title}<span onClick={() => {removeExcludedServices(es)}} style={{float:"right",marginRight:"65%", cursor:"pointer"}}>x</span></p>:null}</div>
                            }):<p>None</p>}
                        </div>

                    </div>


                    <div>
                        <Button color={'primary'} variant={'contained'}
                        onClick={(()=>{
                            let discount = 0;
                            if (promotion.type === 1)
                                discount = parseFloat(promotion.discount_amount);
                            if (promotion.type === 3)
                                discount = parseFloat(promotion.discount_percentage);

                            fetchAuth('/promotions/save', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    id: newPromotion?0:id,
                                    title: promotion.title?promotion.title:null,
                                    code: promotion.code?promotion.code:null,
                                    start_date: promotion.start_date?promotion.start_date:null,
                                    end_date: promotion.end_date?promotion.end_date:null,
                                    type: promotion.type?promotion.type:null,
                                    discount_amount:discount?discount:0,
                                    minimum_spend: promotion.minimum_spend?promotion.minimum_spend:null,
                                    max_uses: promotion.max_uses?promotion.max_uses:null,
                                    number_of_codes: promotion.number_of_codes?parseFloat(promotion.number_of_codes):0,
                                    bucket_data: tieredPromotion,
                                    is_enabled: checked?1:0,
                                    data:promotion.data?promotion.data:null,
                                    is_new_users:promotion.is_new_users?promotion.is_new_users:0,
                                    garages:selected_garage?[selected_garage]:null
                                })
                            })
                                .then(()=>{
                                    setGenerateCodeButton(false)
                                    toast.success( `Promotion has been ${newPromotion?'created':'updated'}`)

                                    if (promotion.number_of_codes!=null) {
                                        setGenerateCodeButton(false)
                                        toast.success("Please generate your unique promo codes by pressing the 'Generate Codes Button'")
                                    }

                                })

                        })}


                        >Save</Button>
                    </div>






                </div>
            </Card>

        </Container>
    );
};

PromotionsView.propTypes = {
    className: PropTypes.string
};

export default PromotionsView;
