import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles({
    root: {
        width: 500,
    },
    input: {
        width: 80,
    },
});

export default function InputSlider({title, value, setValue, disabled, min, max}) {
    const classes = useStyles();

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    return (
        <div className={classes.root}>
            <Typography id="input-slider" gutterBottom>
                {title}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        disabled={disabled}

                        value={typeof value === 'number' ? value : 0}
                        min={min}
                        max={max}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                    />
                </Grid>
                <Grid item>
                    <Input
                        disabled={disabled}
                        className={classes.input}
                        value={value}
                        margin="dense"
                        min={min}
                        max={max}
                        onChange={handleInputChange}
                        inputProps={{
                            step: 1,
                            min: min,
                            max: max,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}

                    />
                </Grid>
            </Grid>
        </div>
    );
}
