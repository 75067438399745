import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField,FormControl,Input,InputLabel, InputBase,fade, withStyles,
    FormControlLabel,Switch,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Collapse, CircularProgress

} from '@material-ui/core';
import Page from 'src/components/Page';
import Loading from 'src/components/Loading';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import {PricingInput} from "./components/PricingInput";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const Summary = ({selected_garage}) => {
    const classes = useStyles();

    const [summary, setSummary] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(()=>{
        if (!selected_garage) return;
        fetchAuth('/pricing/summary?garage=' + selected_garage)
            .then((resJSON)=>{
                setSummary(resJSON.summary)
                setLoading(false)
            })

    }, [selected_garage])

    const onSave = () => {
        fetchAuth('/pricing/labour?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:summary})
        })
            .then((resJSON)=>{
                // console.log(resJSON)
            })
    }

    if (loading)
        return <Loading/>


    return (
        <Page
            className={classes.root}
            title="Pricing"
        >
            <Container maxWidth="lg">

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Typography>
                                <h4>Your Pricing Summary</h4>
                            </Typography>
                            <Table>
                                <TableHead>
                                <TableRow style={{textAlign:'center'}}>
                                    <TableCell rowSpan="2">Average List Price<br/>by Vehicle Size</TableCell>
                                    <TableCell colSpan="2">Comprehensive Service</TableCell>
                                    <TableCell colSpan="2">Basic Service</TableCell>
                                    <TableCell colSpan="2">Oil & Filter Change</TableCell>
                                </TableRow>
                                <TableRow style={{textAlign:'center'}}>
                                    <TableCell style={{width:'13%'}}>Cost</TableCell>
                                    <TableCell style={{width:'13%'}}>Labour Time<br/>(hours)</TableCell>
                                    <TableCell style={{width:'13%'}}>Cost</TableCell>
                                    <TableCell style={{width:'13%'}}>Labour Time<br/>(hours)</TableCell>
                                    <TableCell style={{width:'13%'}}>Cost</TableCell>
                                    <TableCell style={{width:'13%'}}>Labour Time<br/>(hours)</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell>XL</TableCell>
                                    <TableCell className="price">${summary["XL"][0][0]}</TableCell>
                                    <TableCell>{summary["XL"][0][1]}</TableCell>
                                    <TableCell className="price">${summary["XL"][1][0]}</TableCell>
                                    <TableCell>{summary["XL"][1][1]}</TableCell>
                                    <TableCell className="price">${summary["XL"][2][0]}</TableCell>
                                    <TableCell>{summary["XL"][2][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>L</TableCell>
                                    <TableCell className="price">${summary["L"][0][0]}</TableCell>
                                    <TableCell>{summary["L"][0][1]}</TableCell>
                                    <TableCell className="price">${summary["L"][1][0]}</TableCell>
                                    <TableCell>{summary["L"][1][1]}</TableCell>
                                    <TableCell className="price">${summary["L"][2][0]}</TableCell>
                                    <TableCell>{summary["L"][2][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>M</TableCell>
                                    <TableCell className="price">${summary["M"][0][0]}</TableCell>
                                    <TableCell>{summary["M"][0][1]}</TableCell>
                                    <TableCell className="price">${summary["M"][1][0]}</TableCell>
                                    <TableCell>{summary["M"][1][1]}</TableCell>
                                    <TableCell className="price">${summary["M"][2][0]}</TableCell>
                                    <TableCell>{summary["M"][2][1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>S</TableCell>
                                    <TableCell className="price">${summary["S"][0][0]}</TableCell>
                                    <TableCell>{summary["S"][0][1]}</TableCell>
                                    <TableCell className="price">${summary["S"][1][0]}</TableCell>
                                    <TableCell>{summary["S"][1][1]}</TableCell>
                                    <TableCell className="price">${summary["S"][2][0]}</TableCell>
                                    <TableCell>{summary["S"][2][1]}</TableCell>
                                </TableRow>
                            </Table>
                        </Box>
                    </CardContent>
                </Card>

                            <br/>

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <Typography>
                                <h4>Basic Repairs</h4>
                            </Typography>

                            <Table>
                                <TableHead>
                                <TableRow style={{textAlign:'center'}}>
                                    <TableCell rowSpan="2">Repair</TableCell>
                                    <TableCell colSpan="4">Euro</TableCell>
                                    <TableCell colSpan="4">Japanese</TableCell>
                                </TableRow>
                                <TableRow style={{textAlign:'center'}}>
                                    <TableCell>S</TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>XL</TableCell>
                                    <TableCell>S</TableCell>
                                    <TableCell>M</TableCell>
                                    <TableCell>L</TableCell>
                                    <TableCell>XL</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell>Front Brake Pad Replacement (no skim)</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].Sy}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].My}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].Ly}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].XLy}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].Sn}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].Mn}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].Ln}</TableCell>
                                    <TableCell className="price">${summary.products["Front Brake Pads"].XLn}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Rear Brake Pad Replacement (no skim)</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].Sy}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].My}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].Ly}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].XLy}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].Sn}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].Mn}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].Ln}</TableCell>
                                    <TableCell className="price">${summary.products["Rear Brake Pads"].XLn}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Rotor Replacement</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].Sy}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].My}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].Ly}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].XLy}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].Sn}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].Mn}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].Ln}</TableCell>
                                    <TableCell className="price">${summary.products["Rotors"].XLn}</TableCell>
                                </TableRow>
                                {/*<TableRow>*/}
                                {/*    <TableCell>Spark Plugs</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].Sy}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].My}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].Ly}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].XLy}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].Sn}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].Mn}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].Ln}</TableCell>*/}
                                {/*    <TableCell className="price">${summary.products["Spark Plugs"].XLn}</TableCell>*/}
                                {/*</TableRow>*/}
                                <TableRow>
                                    <TableCell>Brake Pad & Rotor Replacement</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].Sy}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].My}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].Ly}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].XLy}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].Sn}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].Mn}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].Ln}</TableCell>
                                    <TableCell className="price">${summary.products["Pads/Rotors"].XLn}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>Wipers</TableCell>
                                    <TableCell className="price" colSpan="4" style={{textAlign:'center'}}>${summary.products["Wipers"].Sy}</TableCell>
                                    <TableCell className="price" colSpan="4" style={{textAlign:'center'}}>${summary.products["Wipers"].Sn}</TableCell>

                                </TableRow>

                                <TableRow>
                                    <TableCell>Headlight Restoration (Pair)</TableCell>
                                    <TableCell className="price" colSpan="4" style={{textAlign:'center'}}>${summary.products["Headlight Restoration"].Sy}</TableCell>
                                    <TableCell className="price" colSpan="4" style={{textAlign:'center'}}>${summary.products["Headlight Restoration"].Sn}</TableCell>
                                </TableRow>

                            </Table>

                        </Box>
                    </CardContent>
                </Card>
                            <br/>

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >


                        <Typography>
                                <h4>Fixed Price Products</h4>

                            </Typography>

                            <Table>
                                <TableRow style={{textAlign:'center'}}>
                                    <TableCell>Product</TableCell>
                                    <TableCell colSpan="8"  style={{width:'80%', textAlign:'center'}}>Price</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell>WoF</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.wof}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Diagnostic</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.diagnostic}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Pre Purchase Inspection</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.prepurchase}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wheel Balance</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.wheel}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wheel Alignment</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.wheelalign}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Transmission Flush</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.transmissionflush}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Brake Flush</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.brakeflush}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Tyre Strip, Fit, Balance & Dispose</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.tyres}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bulb Replacement</TableCell>
                                    <TableCell className="price" colSpan="8" style={{textAlign:'center'}}>${summary.bulbs}</TableCell>
                                </TableRow>
                            </Table>

                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default Summary;
