import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField,FormControl,Input,InputLabel, InputBase,fade, withStyles,
    FormControlLabel,Switch,
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Collapse,TablePagination,Paper
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import {PricingInput} from "./components/PricingInput";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import Price from "../../components/Price";



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

function Row(props) {
    const { row, selected_garage, updateOverride } = props;

    const [open, setOpen] = React.useState(false);
    const [editComp, setEditComp] = useState(false)
    const [editBasic, setEditBasic] = useState(false)
    const [editOil, setEditOil] = useState(false)

    const [overrides, setOverrides] = useState([])



    const onInputChange = ({target: {value, name}},make,model,variant, type, index)=>setOverrides({...overrides, [index]:{ ...overrides[index], [name]: value, variant: variant, make: make, model:model, type:type}})


    function onButtonChange () {
        setEditComp(false)
    }


    const override = () => {

        fetchAuth('/pricing/overrideprice?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({overrides:overrides})
        })
            .then((resJSON)=>{
                toast.success("Override made")
                updateOverride()

            })
    }



    return (
        <React.Fragment>

            <div>

            </div>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row["Make"]}
                </TableCell>
                <TableCell align="right">{row["Model"]}</TableCell>
                <TableCell align="right">${row["Comprehensive Service"]}</TableCell>
                <TableCell align="right">${row["Basic Service"]}</TableCell>
                <TableCell align="right">${row["Oil & Filter Change"]}</TableCell>
                {/*<TableCell align="right">{row.protein}</TableCell>*/}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Variant</TableCell>
                                        <TableCell align="right">Comprehensive Service</TableCell>
                                        <TableCell align="right">Basic Service</TableCell>
                                        <TableCell align="right">Oil & Filter Change</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row["Engine"].split(", ").map((variant_row, index) => (

                                        <TableRow key={variant_row}>

                                            <TableCell component="th" scope="row">
                                                {variant_row}
                                            </TableCell>


                                            <TableCell align="right">
                                                <Price variant_row={variant_row} type={"Comprehensive Service"} index={index} row={row} overrideFunction={override} overrides={overrides} setOverrides={setOverrides} onInputChange={onInputChange} price={row["Comprehensive Variants"][index]}>
                                                </Price>
                                            </TableCell>

                                            <TableCell align="right">
                                                <Price  variant_row={variant_row} type={"Basic Service"} index={index} row={row} overrideFunction={override} overrides={overrides} setOverrides={setOverrides} onInputChange={onInputChange} price={row["Basic Variants"][index]}>

                                                </Price>
                                            </TableCell>

                                            <TableCell align="right">
                                                <Price  variant_row={variant_row} type={"Oil & Filter"} index={index} row={row} overrideFunction={override} overrides={overrides} setOverrides={setOverrides} onInputChange={onInputChange} price={row["Oil Variants"][index]}>
                                                </Price>
                                            </TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const Override = ({selected_garage}, setOverrides) => {
    const classes = useStyles();

    const [pricing, setPricing] = useState({});
    const [loading, setLoading] = useState(true);


    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateOverride = () => {
        fetchAuth('/pricing/overrides?garage=' + selected_garage)
            .then((resJSON)=>{
                setPricing(resJSON.pricing)
                setLoading(false)
            })
    }

    useEffect(()=>{
        if (!selected_garage) return;

        updateOverride()

    }, [selected_garage])

    const onSave = () => {
        fetchAuth('/pricing/labour?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:pricing})
        })
            .then((resJSON)=>{
                // console.log(resJSON)
            })
    }



    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Overrides"
        >
            <Container maxWidth="lg">

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >



                            <TableContainer>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Make</TableCell>
                                            <TableCell align="right">Model</TableCell>
                                            <TableCell align="right">Avg. Comprehensive Service</TableCell>
                                            <TableCell align="right">Avg. Basic Service</TableCell>
                                            <TableCell align="right">Avg. Oil & Filter Change</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pricing.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                            <Row key={row.Make+row.Model} row={row} selected_garage={selected_garage} updateOverride={updateOverride}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={pricing.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />


                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
};

export default Override;
