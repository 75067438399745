import {useLottie} from "lottie-react";
import loadingAnimation from "./loader.json";

export default function Loader() {
        const options = {
                animationData: loadingAnimation,
                loop: true,
                autoplay: true,
        };
        const { View, setSpeed } = useLottie(options, {
                height: 100
        });
        setSpeed(1.6)
        return View;
}
