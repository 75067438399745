import React, {useEffect, useState} from 'react';
import {
    colors,
    Container,
    Grid,
    makeStyles,
    Box,
    Checkbox,
    Card,
    Typography,
    CardHeader,
    CardContent,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Hidden,
    TablePagination
} from '@material-ui/core';
import Page from 'src/components/Page';
import LatestOrders from './LatestOrders';

import {fetchAuth} from "../../../utils/fetch";
import Loading from "../../../components/Loading";

import {useNavigate, useLocation} from "react-router-dom";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import {GrRefresh} from 'react-icons/gr'
import {GrNotification} from 'react-icons/gr'
import {GrCircleInformation} from 'react-icons/gr'
import {AiOutlineTable} from 'react-icons/ai'
import {AiOutlineFieldTime} from 'react-icons/ai'
import {AiOutlineDollarCircle} from 'react-icons/ai'
import {AiFillBook} from 'react-icons/ai'



import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import JobTable from "./components/JobTable";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from '@material-ui/core/Tooltip';
import { useCookies } from 'react-cookie';
import {toast} from "react-toastify";

import styles from "../../../styles/job/job.scss"



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));



const Dashboard = ({selected_garage, selected_garage_name, setIsTourOpen, shortTut, setShortTut}) => {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);
  const [loading5, setLoading5] = useState(true);
  const [loading6, setLoading6] = useState(true);
  const [loadingNotificationModal, setLoadingNotificationModal] = useState(true);
  const [loadingNotificationModal1, setLoadingNotificationModal1] = useState(true);
  const [loadingNotificationModal2, setLoadingNotificationModal2] = useState(true);



  const [isLoading, setIsLoading] = useState(true)
  const [quotes, setQuotes] = useState([]);
  const [todayBookings, settodayBookings] = useState([]);
  const [upcomingJobs, setupcomingJobs] = useState([]);
  const [unclosedJobs, setunclosedJobs] = useState([]);



  // notification modal states

  const [RecentQuotes, setRecentQuotes] = useState ([]);
  const [Last24HourBookings, setLast24HourBookings] = useState ([]);

  const [page, setPage] = React.useState(0);
  const [pageTodaysBookings, setPageTodayBookings] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [rowsPerPageTodaysBookings, setRowsPerPageTodaysBookings] = React.useState(3);



    const handleChangePageTodaysBookings = (event, newPage) => {
        setPageTodayBookings(newPage);
    };
    //rows
    const handleChangeRowsPerPageTodaysBookings = (event) => {
        setRowsPerPageTodaysBookings(+event.target.value);
        setPageTodayBookings(0);
    };
    const navigate=useNavigate();


  const [satisfation_rating, setAvgRating]=useState(5);

  const [show, setShow] = useState(false);
  const [close] = useState(true);

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const handleCloseNotificationModal = () => setShowNotificationModal(false);
  const handleShowNotificationModal= () => setShowNotificationModal(true);


  const [showModal1, setShowModal1] = useState(false);
  const handleClose1 = () => setShowModal1(false);
  const handleShow1= () => setShowModal1(true);

  const [showModal2, setShowModal2] = useState(false);
  const handleClose2 = () => setShowModal2(false);
  const handleShow2= () => setShowModal2(true);

  const [showModal3, setShowModal3] = useState(false);
  const handleClose3 = () => setShowModal3(false);
  const handleShow3= () => setShowModal3(true);

  const [jobData, setJobData] = useState(null);

    const [checked, setChecked] = useState({});




  const [vehicle_registration, setRego] = useState('GTW444');

  const [jobId, setJobID] = useState([]);


  const [reason, setReason] = useState(null);

  const [RegoSearch, setRegoSearch] = useState(null);


  const [empty, setEmpty]=useState(false);

  const [emptyRego, setEmptyRego]=useState(false);


  const [refreshPage, setRefreshPage] = useState(false)

    const [announcement, setAnnouncement] = useState([])
    const [error, setError] = useState(false);

    const [responseData, setResponseData] = useState(
        {}
    )


    const [responses, setResponses] = useState([])





    function kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  const [value, setValue] = React.useState(0);



    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        const midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ midnight.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }



    const [jobLookUp, setJobLookUp] = useState([])


    const [regoLoading, setRegoLoading] = useState(true)

    const closeJob = () => {
        fetchAuth('/user/closeJob?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, hash: jobData.hash})
            })
            .then((resJSON)=>{
                toast.success("Booking Completed")
            })
    }

  const handleRegoSubmit = () => {

    handleShow3();





    fetchAuth('/user/joblookup?garage=' + selected_garage,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({garage: selected_garage, vehicle_registration: RegoSearch})
        })
        .then((resJSON)=>{

          setJobLookUp(resJSON.data)
          setRegoLoading(false);

          if (resJSON.data?.length>0)
            setEmptyRego(false)
          else
            setEmptyRego(true);

        })


  }

    const handleConfirmBooking = (hash) => {

        setLoadingNotificationModal(true)

        fetchAuth('/user/confirmbooking?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, hash:hash})
            })
            .then((resJSON)=>{

                return fetchAuth('/user/todaysBookings?garage=' + selected_garage)
                    .then((resJSON)=>{
                        setLoadingNotificationModal(false)

                        settodayBookings(resJSON.data)
                        toast.success("Booking confirmed! Thank you.");



                    })

            })

    }



    // const handleConfirmBooking = (hash) => {
    //
    //     setChecked({
    //         ...checked, [hash]:!checked[hash]
    //     });
    //
    // }

    const confirmCheckedBookings = () => {

       Object.keys(checked).forEach(hash=>{

           if (checked[hash])
                fetchAuth('/user/confirmbooking?garage=' + selected_garage,
               {
                   method: 'POST',
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({garage_id: selected_garage, hash: hash})
               })
               .then((resJSON)=>{
               })
       })
        setCookie("NotificationModalCookie", "true", 1);

        handleCloseNotificationModal();
        toast.success("Bookings confirmed! Thank you.");

    }


  const handleNotificationShow = () =>{



    fetchAuth('/user/todaysBookings?garage=' + selected_garage)
        .then((resJSON)=>{
            settodayBookings(resJSON.data.length?resJSON.data:[])
            setEmpty(false);

            if (resJSON.data.length) {
                handleShowNotificationModal();
                setLoadingNotificationModal(true);
            }

            setLoadingNotificationModal(false);





        })








  }




  useEffect(()=>{
    if (!selected_garage) return;
    setLoading(false)

    fetchAuth('/quotes/requests?garage=' + selected_garage)
        .then((resJSON)=>{
          setQuotes(resJSON.quotes)
          setEmpty(false);
          setLoading1(false);

          if (!resJSON.quotes)
            setEmpty(true);

        })
    // todays bookings
    fetchAuth('/user/todaysBookings?garage=' + selected_garage)
        .then((resJSON)=>{
            settodayBookings(resJSON.data)

            setLoading2(false);

          if (!resJSON.data)
            setShortTut(true);
            setEmpty(true);

        })

    // upcoming Jobs
    fetchAuth('/user/upcomingJobs?garage=' + selected_garage)
        .then((resJSON)=>{
          setupcomingJobs(resJSON.data)
          setEmpty(false);
          setLoading3(false);

          if (!resJSON.data)
            setEmpty(true);

        })
    // unclosed jobs
    fetchAuth('/user/unclosedJobs?garage=' + selected_garage)
        .then((resJSON)=>{
          setunclosedJobs(resJSON.data)


        })

    fetchAuth('/user/dashboard?garage=' + selected_garage)
        .then((resJSON)=>{
          setData(resJSON.data[0])
          setPending(resJSON.pending)
          setLoading5(false);

        })




      if (getCookie("NotificationModalCookie") !== 'true'){
          handleNotificationShow();
      }



  }, [selected_garage, vehicle_registration, refreshPage])



    if (loading)
    return <Loading/>

  return (
    <Page
      className={classes.root}
      style={{height:'100%', paddingTop:0, paddingBottom:0}}
      title="Dashboard"
    >

    <div className={styles.dashboard}>

      <Grid container>
        <Grid item md={11} sm={11} xs={12}
        >
          <Container maxWidth={false}>


            <Grid item md={12}
            >





              <Grid
                  container
                  spacing={2}
                  alignItems="stretch"

              >

                <Grid
                    item
                    md={12}
                    sm={12}
                    boxShadow={3}

                >
                    <Card style={{width:"100%", textAlign:'center', fontWeight: 'bold', fontVariant:'small-caps', padding:10, marginTop:20}}
                    >


                        <Row style={{display:'flex', marginTop:20}}>




                          <Col md={6}>
                            <ToggleButtonGroup id={"actionbar"} size={"large"} style={{backgroundColor:"white", boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.1)'}}>
                              <Tooltip title="Refresh" arrow>
                              <ToggleButton onClick={()=>{


                                setRefreshPage(!refreshPage)
                                  setIsLoading(true)





                              }}
                              >
                                <GrRefresh />
                              </ToggleButton>
                              </Tooltip>
                              {/*<ToggleButton>*/}
                              {/*  <GrNotification />*/}
                              {/*</ToggleButton>*/}
                              <Tooltip title="Help" arrow>
                              <ToggleButton onClick={()=>{navigate('/app/help/contact')}}


                              >
                                <GrCircleInformation />
                              </ToggleButton>
                              </Tooltip>

                              <Tooltip title="Adjust Pricing" arrow>
                              <ToggleButton onClick={()=>{navigate('/app/pricing/labour')}}>
                                <AiOutlineDollarCircle color={'#000000'}/>

                              </ToggleButton>
                              </Tooltip>

                              <Tooltip title="Notifications" arrow>
                                <ToggleButton
                                    onClick={()=>handleNotificationShow()}
                                >
                                  <GrNotification color={'#000000'}/>
                                </ToggleButton>
                              </Tooltip>

                              <Tooltip title="Mechanic Hub Tutorial" arrow>
                                <ToggleButton onClick={()=>setIsTourOpen(true)}>
                                  <AiFillBook color={'#000000'}/>
                                </ToggleButton>
                              </Tooltip>




                              {/*<Tooltip title="Coming soon" arrow>*/}
                              {/*<ToggleButton>*/}
                              {/*  <AiOutlineFieldTime />*/}
                              {/*</ToggleButton>*/}
                              {/*</Tooltip>*/}
                              {/*<ToggleButton disabled={false}>*/}
                              {/*  <AiOutlineTable />*/}
                              {/*  <ArrowDropDownIcon />*/}
                              {/*</ToggleButton>*/}
                            </ToggleButtonGroup>
                          </Col>


                          <Col md={6} style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>

                              <Form.Group id={"joblookup"}>
                                <input
                                    className={'regoInput'}
                                    fullWidth
                                    value={RegoSearch}
                                    onChange={e=>setRegoSearch(e.target.value.toUpperCase())}
                                    placeholder="Enter vehicle registration"
                                    onKeyPress={(event) => {
                                      if(event.key === 'Enter'){
                                        handleRegoSubmit();
                                      }
                                    }}
                                />



                              </Form.Group>
                            {/*</Form>*/}

                            <div style={{marginLeft:20}}>
                              <button className={'searchButton'} type={'submit'}
                                      onClick={handleRegoSubmit}



                              >Search</button>

                              </div>

                              <Modal
                                  show={showModal3}
                                  onHide={handleClose3}
                                  backdrop="static"
                                  keyboard={false}
                                  style={{padding:40}}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title style={{fontFamily:'Gilroy', fontWeight:'bold'}}>
                                    Jobs

                                    <div style={{display:'flex', marginTop:20}}>
                                      <Form.Group>
                                        {/*<Form.Label>Find a job (REGO)</Form.Label>*/}
                                        <Form.Control
                                            fullWidth={true}
                                            value={RegoSearch}
                                            onChange={e=>setRegoSearch(e.target.value.toUpperCase())}
                                            placeholder="Enter vehicle registration"
                                            onKeyPress={(event) => {
                                              if(event.key === 'Enter'){
                                                handleRegoSubmit();
                                              }
                                            }}
                                        />




                                      </Form.Group>
                                      {!loading?
                                      <div style={{marginLeft:20}}>

                                        <button type="submit" type={"submit"} className={'searchButton'} variant={"contained"} color={"primary"}
                                                onClick={
                                                    ()=>{
                                                        setRegoLoading(true)
                                                        handleRegoSubmit()
                                                    }

                                                    }
                                        >Search</button>

                                      </div> :null

                                      }
                                    </div>

                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>


                                  {regoLoading?<Loading></Loading>:

                                      emptyRego?<div>No results!

                                  </div>
                                          :


                                  <TableContainer style={{ maxHeight: 250 }}>
                                          <Table stickyHeader>

                                            <TableBody>

                                              <TableRow>
                                                <TableCell>
                                                  Registration
                                                </TableCell>

                                                <TableCell>
                                                  Booking Date
                                                </TableCell>
                                                <TableCell>
                                                  {/*Customer Name*/}
                                                </TableCell>

                                                {/*<TableCell>*/}
                                                {/*  /!*Action*!/*/}
                                                {/*</TableCell>*/}


                                              </TableRow>


                                              {jobLookUp.map(record=>
                                                  <TableRow>

                                                    <TableCell>
                                                      {record.vehicle_rego?record.vehicle_rego.toUpperCase():"N/A"}
                                                    </TableCell>



                                                    <TableCell>
                                                      {record.pickup_time?moment(record.pickup_time).format('DD/MM/YYYY'):<div>N/A</div>}
                                                    </TableCell>

                                                    <TableCell>
                                                      <button  className={'manage-button'} onClick={()=>{navigate('/app/dashboard/jobmanagement/' + record.hash)
                                                      }}>Manage</button>
                                                    </TableCell>

                                                  </TableRow>

                                              )}

                                            </TableBody>

                                          </Table>
                                  </TableContainer>



                                  }
                                </Modal.Body>


                                <Modal.Footer style={{display:'flex', justifyContent:'space-between', fontSize:12, fontWeight:'normal', fontStyle:'italic', color:'lightslategray'}}>



                                  <div style={{}}>


                                    {emptyRego?"0 jobs found":

                                        jobLookUp.length + " " + "jobs found"
                                    }
                                   </div>


                                  <Button
                                      variant="primary" onClick={()=>{
                                    handleClose3();




                                  }}>
                                    Done
                                  </Button>


                                </Modal.Footer>


                              </Modal>




                          </Col>


                        </Row>












                      {/*<div>{emptyRego?<div>No jobs!</div>:null}</div>*/}







                    </Card>
                  {/*<TrafficByDevice />*/}
                </Grid>

                <Grid
                    item
                    md={6}
                    boxShadow={3}
                    style={{minHeight:300, width: '100%'}}
                    id={"quotes"}

                >
                      <LatestOrders
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          message={"You have no pending quotes"} quotes={quotes} buttonFunction={null}></LatestOrders>

                </Grid>


                <Grid
                    item
                    md={6}
                    style={{width: '100%'}}
                    id={"todaysjobs"}

                >

                  <JobTable
                      selected_garage={selected_garage}
                      tooltip
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      tooltipText={"Here are your jobs for the day. Remember to close each job after it is completed."}
                      message={"You have no jobs today"} data={todayBookings} title={"Today's Jobs"} button_text={"Manage"}
                      onClick={(info)=>{navigate('/app/dashboard/jobmanagement/'+info.hash, {})}}
                  />

                </Grid>

                <Grid
                    item
                    md={6}
                    style={{height:"100%", width:'100%'}}
                    id={"firsttable"}
                >

                  <JobTable
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      selected_garage={selected_garage}
                      message={"You have no upcoming jobs"}
                      data={upcomingJobs?upcomingJobs:null} title={"Upcoming Jobs"}
                      button_text={"Manage"}
                      tooltip
                      tooltipText={"Here are your upcoming jobs. If things are looking busy, adjust your availability or reschedule a job"}
                      onClick={(info)=>{
                        navigate('/app/dashboard/jobmanagement/'+ info.hash, {
                        });
                      }}

                  />
                </Grid>


                <Grid
                    item
                    style={{height:"100%", width:'100%'}}
                    md={6}
                    id={"openjobs"}
                >
                  {/* =closed jobs table*/}
                  <JobTable
                      dualButton={true}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      message={"You have no jobs to close"}
                      data={unclosedJobs} title={"Open Jobs"}
                      tooltip
                      tooltipText={"Here are your open jobs. Remember to close each job after it is completed."}
                      selected_garage={selected_garage}
                      button_text={"Close"}
                      close={close}
                      onClick={(info)=>{

                        setJobData(info);
                        handleShow1();


                      }}

                      onClick2={(info)=>{
                        navigate('/app/dashboard/jobmanagement/' + info.hash, {

                        });
                      }}
                  />

                </Grid>

              </Grid>

            </Grid>

          </Container>
        </Grid>



      <Grid item md={1} style={{height:"100%", position:"fixed", backgroundColor: 'white', flex: 1, flexDirection: 'row', right:0, boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)'
      }}>

        <div style={{width:"100%", padding:20}}>
          <div style={{display:'flex',
            flexDirection: 'column',
            marginTop:30,
            textAlign:'center',
            fontSize: 10,
            fontVariant:'small-caps',
            fontWeight:'bold'
            }}>
            CUSTOMER RATING
            <div style={{display:'flex',
              flexDirection: 'column',
              marginTop:10,
              textAlign:'center',
              fontSize: 30,
              fontVariant:'small-caps',
              fontWeight:'bold'
            }}>
              {satisfation_rating?
                satisfation_rating:"N/A"
              }
            </div>
          </div>

            <div style={{display:'flex',
              flexDirection: 'column',
              marginTop:30,
              textAlign:'center',
              fontSize: 10,
              fontVariant:'small-caps',
              fontWeight:'bold'
            }}>
              PENDING QUOTE REQUESTS
              <div style={{display:'flex',
                flexDirection: 'column',
                marginTop:10,
                textAlign:'center',
                fontSize: 30,
                fontVariant:'small-caps',
                fontWeight:'bold'
              }}>
                {pending?pending:"0"}
              </div>
            </div>


            {data.month_bookings ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    textAlign: 'center',
                    fontSize: 10,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>
                    MONTH'S JOBS
                </div>
                :
                null
            }
            {data.month_bookings ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                    textAlign: 'center',
                    fontSize: 30,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>
                    {data.month_bookings}
                </div>
                :
                null
            }
          {/*<div style={{display:'flex',*/}
          {/*  flexDirection: 'column',*/}
          {/*  marginTop:30,*/}
          {/*  textAlign:'center',*/}
          {/*  fontSize: 10,*/}
          {/*  fontVariant:'small-caps',*/}
          {/*  fontWeight:'bold'*/}
          {/*}}>*/}
          {/*  MONTH'S REVENUE*/}
          {/*</div>*/}
          {/*<div style={{display:'flex',*/}
          {/*  flexDirection: 'column',*/}
          {/*  marginTop:10,*/}
          {/*  textAlign:'center',*/}
          {/*  fontSize: 18,*/}
          {/*  fontVariant:'small-caps',*/}
          {/*  fontWeight:'bold'*/}
          {/*}}>*/}
          {/*    {"$" + kFormatter(data.total_month)}*/}
          {/*  /!*{"$" + parseFloat(data.total_month).toFixed(2)}*!/*/}
          {/*</div>*/}
            {data.year_bookings ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    textAlign: 'center',
                    fontSize: 10,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>
                    YEAR'S JOBS
                </div>
                :
                null
            }
            {data.year_bookings ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                    textAlign: 'center',
                    fontSize: 30,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>
                    {data.year_bookings}
                </div>
                :
                null
            }
            {data.year_rev ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 30,
                    textAlign: 'center',
                    fontSize: 10,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>
                    THIS YEAR'S REVENUE
                </div>
                :
                null
            }
            {data.year_rev ?
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 10,
                    textAlign: 'center',
                    fontSize: 18,
                    fontVariant: 'small-caps',
                    fontWeight: 'bold'
                }}>

                    {"$" + kFormatter(data.year_rev)}
                </div> :
                null
            }

          <div style={{display:'flex',
            flexDirection: 'column',
            marginTop:10,
            textAlign:'center',
            fontSize: 30,
            fontVariant:'small-caps',
            fontWeight:'bold',
            paddingTop:5
          }}>
            <Button style={{padding:5}} variant="contained" color="primary" size="small" onClick={()=>{navigate('/app/data/view')}}> MORE</Button>
          </div>
        </div>


      </Grid>


        {/*<div>*/}
        {/*  <img src={mas_man} alt="Logo" style={{objectFit:"cover", width:'10%'}}/>*/}
        {/*</div>*/}

      </Grid>


    </div>


      <Modal
          show={showModal1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              {jobData?.Payment_Type__c === "In-Store" ?
                  <div>Have you captured all remaining payments from the customer in-store?</div>
                  : null}
              {jobData?.Payment_Type__c === "Afterpay" ?
                  <div>Are you sure you want to close this job?</div>
                  : null}
              {jobData?.Payment_Type__c === "Stripe" ?
                  <div>Are you sure you want to close this job?</div>
                  : null}

            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            {jobData?.Payment_Type__c === "In-Store" ?
                <div>Remember that for an in-store payment, you must capture the full amount from the customer</div>
                : null}
            {jobData?.Payment_Type__c === "Afterpay" ?
                <div>This payment was captured online on our website, so feel free to invoice us and we will send you what we owe next month!</div>
                : null}
            {jobData?.Payment_Type__c === "Stripe" ?
                <div>This payment was captured online on our website, so feel free to invoice us and we will send you what we owe next month!</div>
                : null}

          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={()=>{
            handleClose1();

            fetchAuth('/user/closeJob?garage=' + selected_garage,
                {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({garage_id: selected_garage, job_id: jobData?.Id})
                })
                .then((resJSON)=>{
                })


          }}>
            Yes
          </Button>
          <Button variant="primary" onClick={()=>{

            handleClose1();
          }}>
            No
          </Button>

          <Button variant="warning" onClick={()=>{navigate('/app/dashboard/jobmanagement/' + jobData.Id, {

            })
          }}>
            Manage Job
          </Button>

        </Modal.Footer>
      </Modal>

      {/*modal 2*/}
      <Modal
          show={showModal2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          style={{padding:40}}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            What's the reason this job is still ongoing?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DropdownButton title="Reason">
            <Dropdown.Item onClick={()=>{
              setReason("Customer didn't show");
            }}  as="button">Customer didn't show
            </Dropdown.Item>
            <Dropdown.Item onClick={()=> {
              setReason("More work to be completed");
            }}
                           as="button">More work to be completed
            </Dropdown.Item>
            <Dropdown.Item onClick={()=> {
              setReason("Customer dispute");
            }}
                           as="button">Customer dispute
            </Dropdown.Item>
            <Dropdown.Item onClick={()=> {
              setReason("Something else");
            }}
                           as="button">Something else
            </Dropdown.Item>
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>{


            handleClose2();

            // close job with reason
            fetchAuth('/user/incompleteJob?garage=' + selected_garage,
                {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({garage_id: selected_garage, job_id: jobData.Id, reason: reason})
                })
                .then((resJSON)=>{
                })

              fetchAuth('/user/logevent?garage=' + selected_garage,
                  {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({garage_id: selected_garage, event: "JOB_CLOSED"})
                  })
                  .then((resJSON)=>{
                  })



          }}>
            Close Job
          </Button>
        </Modal.Footer>
      </Modal>



      {/*notification modal*/}
      <Modal
          show={showNotificationModal}
          onHide={handleCloseNotificationModal}
          backdrop="static"
          keyboard={false}
          size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{display:'flex', fontSize: 24}}>
            See what's new in the last 24 hours
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

            {loadingNotificationModal ?
                <Loading></Loading> :

            <div>
                <div style={{padding: 10}}>
                    <div style={{
                        fontSize: 24,
                        fontVariant: 'small-caps',
                        fontWeight: 'normal',
                        color: 'grey'
                    }}>
                        Today's Jobs

                    </div>


                    {todayBookings?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Registration
                                        </TableCell>
                                        <TableCell>
                                            Customer Name
                                        </TableCell>
                                        <TableCell>
                                           Booking Date
                                        </TableCell>


                                        <TableCell>
                                            Confirm Booking
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {todayBookings.slice((page) * rowsPerPage, (page + 1) * rowsPerPage).map((record) => (
                                        <TableRow>

                                            <TableCell>{record.vehicle_rego ? record.vehicle_rego.toUpperCase() : "N/A"}</TableCell>
                                            <TableCell>{record.first_name}  {record.last_name}</TableCell>
                                            <TableCell>{moment(record.pickup_time).format('DD/MM/YYYY')}</TableCell>

                                            {/*<TableCell>{record.StageName}</TableCell>*/}

                                            {/*<TableCell>*/}

                                            {/*    <Button variant={"outlined"} color={"primary"} onClick={() => {*/}
                                            {/*        navigate('/app/dashboard/jobmanagement/' + record.hash)*/}
                                            {/*    }}>View</Button>*/}
                                            {/*</TableCell>*/}
                                            <TableCell style={{textAlign:'left'}}>




                                                <Checkbox
                                                    checked={JSON.parse(record.data)?.isConfirmed?JSON.parse(record.data).isConfirmed:
                                                    false
                                                        // checked[record.hash]
                                                    }
                                                    onChange={() => handleConfirmBooking(record.hash)}
                                                    value={record.hash}
                                                    inputProps={{'aria-label': 'primary checkbox'}}
                                                />

                                            </TableCell>

                                        </TableRow>
                                    ))}

                                </TableBody>

                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[3, 4, 5]}
                                component="div"
                                count={todayBookings.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />


                        </TableContainer> : <div style={{
                            marginTop: 10,
                            fontSize: 18,
                            fontWeight: 'normal',
                            padding: 10
                        }}>You have no new bookings.</div>
                    }
                </div>


            </div>
            }


        </Modal.Body>
        {/*  {todayBookings.length!==0?*/}
        {/*<Modal.Footer>*/}

        {/*    <div>*/}


        {/*        <Button onClick={confirmCheckedBookings} variant={"contained"} color={"primary"}>*/}
        {/*            Confirm Selected Bookings*/}
        {/*        </Button>*/}
        {/*    </div>*/}

        {/*</Modal.Footer>*/}
        {/*      :null}*/}
      </Modal>



    </Page>
  );
};

export default Dashboard;
