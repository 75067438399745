import React from "react";

export const garage_messages = {
    high_range:90,
    high_range_blurb:'You\'re a customer champion. Customers love your workshop! See what they\'re saying about your work below.',
    mid_range:75,
    mid_range_blurb:'Good job, customers are pretty happy with your work',
    low_range:50,
    low_range_blurb:'You\'re currently under-perfoming, you can improve by:',
    image:'',
}


