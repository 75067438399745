import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,

    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader,
} from '@material-ui/core';
import Page from 'src/components/Page';


import styles from "../../styles/quote/quote.scss"
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";
import SuccessQuoteView from "./Success";
import DeclinedQuoteView from "./QuoteFinalScreen";
import MessageView from "./MessageView";
import QuotedJobView from "./QuotedView";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));




const QuoteView = ({selected_garage}) => {
    const classes = useStyles();

    const params = useParams();
    const identifier = params.identifier

    const [quotes, setQuotes] = useState([]);
    const [breakdown, setBreakdown] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [successPage, setSuccessPage] = useState(false);

    const navigate=useNavigate();

    const [notes, setNotes] = useState(
        ""

    );

    const [sendNotes, setSendNotes] = useState([]);

    const [disabled, setDisabled] = useState(true)


    const [viewQuote, setViewQuote] = useState(false)
    const [messagePage, setMessagePage] = useState(false)

    const [declinedQuote, setDeclinedQuote] = useState(false)
    const [declinedReason, setDeclinedReason] = useState("")


    const [gstToggle, setGSTToggle] = useState(true)

    const [displayJob, setDisplayJob] = useState([

    ])
    const [job, setJob] = useState([

    ])

    const [quote, setQuote] = useState(null)
    const [quoteable, setQuoteable] = useState([])
    const [title, setTitle] = useState([])
    const [message, setMessage] = useState([])

    const [total, setTotal] = useState(null)
    const [inHand, setInHand] = useState(null)


    const [jobNotes, setJobNotes] = useState([

    ])


    const [items_1, setItems_1] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )

    const [items_2, setItems_2] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )




    useEffect(()=>{
        if (!selected_garage) return;
        fetchQuote('/'+identifier)
            .then((resJSON)=>{

                console.log(resJSON)


                if (resJSON.quote.q_status===2  || resJSON.status === 5 || resJSON.status===4) {
                    setMessagePage(true)
                    setTitle(resJSON.title)
                    setMessage(resJSON.message)
                    setQuote(resJSON.quote)
                } else if (resJSON.status===1) {
                    setViewQuote(true)
                    setQuote(resJSON.quote)


                }

                    setQuoteable(true)

                    setQuote(resJSON.quote)

                    resJSON.quote.breakdown?.forEach(i=>i.price = parseFloat((i.price / 1.15).toFixed(2)))

                    setJob(resJSON.quote.breakdown)
                    setNotes(resJSON.quote.notes?resJSON.quote.notes:null)
                    setTotal(resJSON.quote.total?resJSON.quote.total:null)
                    setInHand(resJSON.quote.in_hand?resJSON.quote.in_hand:null)
                    setLoading(false)
                    setItems_2({...items_2, product: resJSON.quote.services[0].title + " Labour"})
                // }

            })

    }, [selected_garage])


    const onInputChangeParts = ({target: {value, name}})=>setItems_1({...items_1, [name]: value})
    const onInputChangeLabour = ({target: {value, name}})=>setItems_2({...items_2, [name]: value})


    const onNotesChange = ({target: {value, name}})=>setNotes(value)


    const onSelectChange = (event) => {
        setItems_2({...items_2, [event.target.name]: event.target.value});
    };

    const onAdd = (type) => {

        if (type==="Parts") {
            setJob([...job, items_1])
        } else {
            setJob([...job, items_2])
        }

            fetchQuote('/' + identifier + '/add',{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    product:type==="Parts"?items_1.product:items_2.product,
                    price:type==="Parts"?parseFloat((items_1.price*(gstToggle?1.15:1)).toFixed(2)):parseFloat((items_2.price*(gstToggle?1.15:1)).toFixed(2)),
                    quantity:type==="Parts"?items_1.quantity:items_2.quantity,
                })



            })



    }

    const addNote = () => {


        setJobNotes([...jobNotes, notes])

        fetchQuote('/' + identifier + '/note',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                notes:notes

            })
        })
            .then((resJSON)=>{
                toast.success("Job note added!");
            })

    }








    const onDelete = (index) => {

        job.splice(index, 1)

        setJob([...job])


        fetchQuote('/' + identifier + '/delete/' +  index)
            .then((resJSON)=>{
            })

    }

    const submitQuote = () => {
        if (!selected_garage) return;
        fetchQuote('/' + identifier + '/submit')
            .then((resJSON)=>{
                toast.success("Quote submitted! Thank you.");
                setSuccessPage(true)
            })

    }


    const declineQuote = () => {
        if (!selected_garage) return;
        fetchQuote('/' + identifier + '/decline')
            .then((resJSON)=>{
                toast.dark("Quote declined.");
                setDeclinedQuote(true)

            })


    }



    if (loading)
        return <Loading/>

    return (
        <Page
            className={styles.quotePage}
            title="Quote"
        >
            <div className={"quotePage"}>
            <Container  maxWidth="lg">


                {successPage ?
                    <SuccessQuoteView></SuccessQuoteView> :
                    declinedQuote ?
                        <DeclinedQuoteView></DeclinedQuoteView> :
                        viewQuote ?
                            <QuotedJobView quote={quote} job={JSON.parse(quote.breakdown)} notes={notes} total={total} inhand={inHand}></QuotedJobView> :
                            messagePage ?
                                <MessageView message={message} title={title}></MessageView> :


                                <Card style={{overflow: 'auto'}} style={{padding: 25}}>

                                    <div className={"header"}>
                                        Quote for {quote.firstname} {quote.lastname}'s {quote.make} {quote.model}
                                    </div>


                                    <div className={"disclaimer"}>
                                        <div>
                                            Hi {quote.name}. A customer in your area needs some help! If you're able to
                                            provide a quote for the below work, we'll pass it on and then confirm once
                                            they book.
                                        </div>

                                        <div className={"disclaimer-review"}>
                                            Please review the information by the customer below and use the fields below
                                            to submit your quote
                                        </div>

                                    </div>

                                    <div>
                                        <hr/>
                                    </div>


                                    <Grid container justifyContent="center" direction="row" alignItems="center">

                                        <Grid item md={12}>

                                            <div className={"car-info"}>
                                                Car Info
                                            </div>

                                        </Grid>
                                        <Grid container style={{marginTop: 15}}>
                                            <Grid item md={6} xs={6}>
                                                <div className={"car-info-title"}>
                                                    <span>Make:</span> {quote.make}
                                                </div>
                                                <div className={"car-info-title"}>
                                                    <span>Model:</span> {quote.model}
                                                </div>
                                                <div className={"car-info-title"}>
                                                    <span>Year: </span> {quote.year}
                                                </div>

                                            </Grid>

                                            <Grid item md={6} xs={6}>
                                                <div className={"car-info-title"}>
                                                    <span>Engine:</span> {quote.engine}
                                                </div>
                                                <div className={"car-info-title"}>
                                                    <span>Registration:</span> {quote.rego ? quote.rego.toUpperCase() : null}
                                                </div>
                                                <div className={"car-info-title"}>
                                                    <span>KM's Driven:</span> {quote.kms ? quote.kms : null}
                                                </div>

                                            </Grid>

                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <div className={"job-info"}>
                                                Job Info


                                                {(quote.services ? quote.services : []).map((service) => (
                                                    <li>
                                                        {service.title}

                                                    </li>
                                                ))}
                                            </div>


                                        </Grid>

                                        <Grid item md={12} xs={12}>
                                            <div className={"job-info"}>
                                                Further Relevant Info
                                            </div>

                                            <div className={"questions-wrapper"}>


                                                <div dangerouslySetInnerHTML={{__html: unescape(quote.problem)}}>


                                                </div>


                                            </div>


                                        </Grid>


                                    </Grid>

                                    <div>
                                        <hr/>
                                    </div>


                                    <Grid container direction="column">

                                        <Grid item>
                                            <FormControlLabel control={ <Switch defaultChecked={gstToggle}
                                                                                onChange={(e)=>{
                                                                                    setGSTToggle(e.target.checked);
                                                                                }}
                                                                                size={"large"} color={"primary"}

                                            >

                                            </Switch>} style={{fontSize:24}}  label={ <Typography style={{fontSize:18, fontFamily:'Gilroy', fontWeight:'bold'}}>Prices Exclude GST</Typography>}
                                                              labelPlacement={"right"} />
                                        </Grid>


                                        <div className={"action-wrapper"}>


                                            <div className={"parts-labour-title"}>
                                                Add parts and other costs <span> {gstToggle?"excl. GST":"incl. GST"}</span>
                                            </div>

                                            <Grid container direction="row" alignItems={"center"}>

                                                <Grid item md={5} xs style={{width: "80%"}}>
                                                    <div>
                                                        <input onChange={onInputChangeParts} className={"input"}
                                                               type="text" placeholder={"Item Name"}
                                                               value={items_1.product} name={"product"}/>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} xs={6}>
                                                    <div className={"input-group"}>
                                                        <div className={"input-before"}>
                                                            $
                                                        </div>
                                                        <input onChange={onInputChangeParts} className={"input"}
                                                               type="number" placeholder={"Unit Price"}
                                                               value={items_1.price} name={"price"} step={".01"}/>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} xs>
                                                    <div>
                                                        <input onChange={onInputChangeParts} className={"input"}
                                                               type="number" placeholder={"Quantity"}
                                                               value={items_1.quantity} step={"1"} name={"quantity"}/>

                                                    </div>
                                                </Grid>

                                                <Grid item md xs>
                                                    <div>
                                                        <button style={{}} disabled={
                                                            items_1.quantity === null || items_1.price === null || items_1.product === null ||
                                                            items_1.quantity === '' || items_1.price === '' || items_1.product === ''
                                                        } onClick={() => {
                                                            onAdd("Parts")
                                                        }}>Add
                                                        </button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </Grid>

                                    <Grid container direction="column">
                                        <div className={"action-wrapper"}>
                                            <div className={"parts-labour-title"}>
                                                Add labour <span> {gstToggle?"excl. GST":"incl. GST"}</span>
                                            </div>


                                            <Grid container direction="row" alignItems={"center"}>
                                                <Grid item md={5} xs style={{width: "80%"}}>
                                                    <div>
                                                        <select onChange={onSelectChange} value={items_2.product} id=""
                                                                name={"product"}>

                                                            {(quote.services ? quote.services : []).map((service) => (
                                                                <option value={service.title + " Labour"}>
                                                                    {service.title + " Labour"}
                                                                </option>
                                                            ))}


                                                        </select>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} xs={6}>
                                                    <div className={"input-group"}>
                                                        <div className={"input-before"}>
                                                            $
                                                        </div>
                                                        <input onChange={onInputChangeLabour} className={"input"}
                                                               type="number" placeholder={"Rate"} name={"price"}
                                                               value={items_2.price} step={".01"}/>
                                                    </div>
                                                </Grid>
                                                <Grid item md={3} xs>
                                                    <div>
                                                        <input onChange={onInputChangeLabour} className={"input"}
                                                               type="number" placeholder={"Hours"} step={"1"}
                                                               name={"quantity"} value={items_2.quantity}/>
                                                    </div>
                                                </Grid>

                                                <Grid item md xs>
                                                    <div>
                                                        <button disabled={
                                                            items_2.quantity === null || items_2.price === null ||
                                                            items_2.quantity === '' || items_2.price === ''
                                                        } onClick={() => {
                                                            onAdd("Labour")
                                                        }}>Add
                                                        </button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    </Grid>


                                    <Grid container direction="column">
                                        <div className={"action-wrapper"}>
                                            <div className={"parts-labour-title"}>
                                                Add notes
                                            </div>


                                            <Grid container direction="row">
                                                <Grid item md={10} xs={10}>
                                                    <div>
                                <textarea
                                    onChange={onNotesChange}
                                    value={notes ? notes.notes : null}
                                    name={"notes"}
                                >

                                </textarea>
                                                    </div>
                                                </Grid>
                                                <Grid item md={2} xs={2}>
                                                    <button onClick={() => {

                                                        addNote()
                                                    }}>Save Note
                                                    </button>
                                                </Grid>
                                            </Grid>

                                        </div>

                                    </Grid>


                                    <div>
                                        <hr/>
                                    </div>


                                    <Grid container>
                                        <div className={"parts-labour-title"}>
                                            Quoted Products & Services
                                        </div>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={"cell-header"}>
                                                        Product/Service
                                                    </TableCell>
                                                    <TableCell className={"cell-header"}>
                                                        Unit Price {gstToggle?"(excl. GST)":"(incl. GST)"}
                                                    </TableCell>
                                                    <TableCell className={"cell-header"}>
                                                        Quantity
                                                    </TableCell>
                                                    <TableCell className={"cell-header"}>
                                                        Total Price
                                                    </TableCell>


                                                </TableRow>
                                            </TableHead>

                                            <TableBody>


                                                {(job ? job : []).map((j, index) => (

                                                    <TableRow>


                                                        <TableCell className={"cell-item-text"}>
                                                            {j.product}
                                                        </TableCell>
                                                        <TableCell className={"cell-item-text"}>
                                                            ${(j.price)}
                                                        </TableCell>
                                                        <TableCell className={"cell-item-text"}>
                                                            {j.quantity}
                                                        </TableCell>
                                                        <TableCell className={"cell-item-text"}>
                                                            ${(j.price * j.quantity).toFixed(2)}
                                                        </TableCell>
                                                        <TableCell className={"cell-item-delete-cross"}>
                                                            <button onClick={
                                                                () => {
                                                                    onDelete(index)
                                                                }
                                                            }>X
                                                            </button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                                <TableRow>


                                                    <TableCell rowSpan={5}>

                                                        <div className={"notes-wrapper"}>

                                                            <div> Notes:</div>
                                                            <div>
                                                                {quote.notes ? quote.notes : jobNotes}
                                                            </div>

                                                        </div>

                                                    </TableCell>



                                                        <TableCell className={"cell-item-text-bold"} colSpan={2}>
                                                            Total to
                                                            Customer {gstToggle ? "(excl. GST)" : "(incl. GST)"}
                                                        </TableCell>

                                                        <TableCell className={"cell-item-text"}>
                                                            ${job.reduce((a, b) => +a + +b.price * +b.quantity, 0).toFixed(2)}
                                                        </TableCell>





                                                </TableRow>

                                                {gstToggle ?
                                                    <TableRow>
                                                        <TableCell className={"cell-item-text-bold"} colSpan={2}>
                                                            GST
                                                        </TableCell>
                                                        <TableCell className={"cell-item-text"}>
                                                            ${((job.reduce((a, b) => +a + +b.price * +b.quantity, 0) * 0.15).toFixed(2))}
                                                        </TableCell>
                                                    </TableRow>
                                                    : null
                                                }


                                                {gstToggle ?
                                                    <TableRow>

                                                        <TableCell className={"cell-item-text-bold"} colSpan={2}>
                                                            Total to Customer incl. GST
                                                        </TableCell>
                                                        <TableCell className={"cell-item-text"}>
                                                            ${((job.reduce((a, b) => +a + +b.price * +b.quantity, 0) * 1.15).toFixed(2))}
                                                        </TableCell>

                                                    </TableRow>
                                                    : null
                                                }

                                                <TableRow>

                                                    <TableCell colSpan={2} className={"cell-item-text-bold"}>
                                                        My Auto Shop Service Fee
                                                    </TableCell>
                                                    <TableCell className={"cell-item-text"}>
                                                        {quote.commission}%
                                                    </TableCell>

                                                </TableRow>

                                                <TableRow>

                                                    <TableCell colSpan={2} className={"cell-item-text-bold"}>
                                                        Total Payment (incl. GST)
                                                    </TableCell>
                                                    <TableCell className={"cell-item-text"}>
                                                        ${((job.reduce((a, b) => +a + +b.price * +b.quantity, 0) * ((100 - +quote.commission) / 100))*(gstToggle?1.15:1)).toFixed(2)}
                                                    </TableCell>

                                                </TableRow>


                                            </TableBody>


                                        </Table>

                                        <div>
                                            <hr/>
                                        </div>


                                        <div className={"warning-text"}>
                                            Note: You will not be able to modify your quote once submitted.
                                            <br/>
                                            You will be expected to honour this price when the customer books. However,
                                            if any unexpected elements outside the stated job are found, these can be
                                            communicated and solved with the customer directly.
                                        </div>


                                        <Grid container direction={"row"} justifyContent={"flex-start"}>
                                            <div className={"buttons-wrapper"}>

                                                <Grid item>
                                                    <button onClick={submitQuote} className={"submit-button"}>
                                                        Submit Quote
                                                    </button>
                                                </Grid>
                                                <Grid item>
                                                    <button onClick={declineQuote} className={"decline-button"}>
                                                        Decline Quote
                                                    </button>
                                                </Grid>


                                            </div>


                                        </Grid>


                                    </Grid>


                                </Card>
                }






            </Container>
            </div>

        </Page>
    );
};

export default QuoteView;
