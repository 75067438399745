import React, {useEffect, useState, useRef} from 'react';

import {
    colors,
    Container,
    Grid,
    makeStyles,
    Box,
    Checkbox,
    Card, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import styles from "../styles/job/job.scss"

import Paper from '@material-ui/core/Paper';
import LatestOrders from "../views/reports/DashboardView/LatestOrders";
import SummaryBox from "../views/reports/DashboardView/SummaryBox";
import {fetchAuth, fetchAPI} from "../utils/fetch";
import {AttachMoney, Description, CalendarToday, DriveEta} from '@material-ui/icons';
import Loading from "../components/Loading";
import TotalCustomers from "../views/reports/DashboardView/TotalCustomers";

import JobTable from "../views/reports/DashboardView/components/JobTable";
import MonthlyPaymentsTable from "../views/reports/DashboardView/MonthlyPaymentsTable";
import InfoBox from "./components/InfoBox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button"
import {useNavigate, useLocation, useParams, useSearchParams} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import theme from "../theme";
import Modal from 'react-bootstrap/Modal'
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";

import {BsArrowLeft} from "react-icons/bs"
import {BiRefresh} from "react-icons/bi"
import {BsPeopleCircle} from "react-icons/bs"
import {FaCar} from 'react-icons/fa';
import {MdAttachMoney} from 'react-icons/md'
import {BiNotepad, BiQuestionMark} from "react-icons/all";

import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Badge from 'react-bootstrap/Badge'
import {BiPhone} from "react-icons/bi"
import {AiOutlineMail} from "react-icons/all";
import {TiSpannerOutline} from "react-icons/all";
import {AiOutlineCalendar} from "react-icons/all";
import {MdSchedule} from "react-icons/md";
import Form from 'react-bootstrap/Form'
import {VscCircleFilled} from "react-icons/all";
import {AiOutlineCar} from "react-icons/all";
import Fade from "@material-ui/core/Fade";
import {GiTalk} from "react-icons/all";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {withStyles} from "@material-ui/core/styles";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        flexGrow:1
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: "30%",
    },

}));


const JobManagement = ({selected_garage}) => {


    const params = useParams();
    const hash = params.hash
    const classes = useStyles();

    const [searchParams] = useSearchParams();


    const GarageConfirmJob = () => {


        if (searchParams.get('q') == 'confirm') {
            setConfirmModal(true)


        } else {
            setConfirmModal(false)
        }


    };





    const [confirmModal, setConfirmModal] = useState(false)

    const [data, setData] = useState({});
    const [pending, setPending] = useState(0);
    const [loading, setLoading] = useState(false);
    const [infoLoading, setInfoLoading]=useState(true);
    const navigate=useNavigate();
    const [show, setShow] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showModal5, setShowModal5] = useState(false);
    const handleClose1 = () => setShowModal1(false);
    const handleClose2 = () => setShowModal2(false);
    const handleClose3 = () => setShowModal3(false);
    const handleClose4 = () => setShowModal4(false);
    const handleClose5 = () => setShowModal5(false);
    const handleShow1= () => setShowModal1(true);
    const handleShow2= () => setShowModal2(true);
    const handleShow3= () => setShowModal3(true);
    const handleShow4= () => setShowModal4(true);
    const handleShow5= () => setShowModal5(true);
    const handleConfirmModal= () => setConfirmModal(true);
    const handleCloseConfirmModal = () => setConfirmModal(false);



    const [checked, setChecked] = useState(false)


    const [showAfterpayField, setshowAfterpayField] = useState(false);

    const valueRef = useRef('');

    const sendValue = () => {
        return (valueRef.current.value) //on clicking button accesing current value of TextField and outputing it to console
    }


    const [jobNote, setJobNote] = useState(null);
    const [newJobNote, setNewJobNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [onGoingNoteReason, setOngoingNoteReason] = useState('');
    const [onGoingNoteCommentary, setOnGoingNoteCommentary] = useState('');
    const [extraLineItems, setExtraLineItems] = useState('');

    const [vehicle_registration, setRego] = useState('GTW444');
    const [email, setEmail] = useState("admin@myautoshop.co.nz");
    const [jobId, setJobID] = useState(null);

    const [afterpayAmount, setafterpayAmount] = useState(null);
    const [afterpayNote, setAfterpayExtraNote] = useState (null);
    const [afterpayStatus, setafterpayStatus] = useState(null);
    const [afterpayReceived, setafterpayReceived] = useState(false);
    const [completeJobDisabled, setcompleteJobDisabled] = useState(true);

    const [paymentType, setPaymentType] = useState(true)

    const [jobInformation, setJobInformation] = useState([]);
    const [jobData, setJobData] = useState(null);
    const [sfID, setSFID] = useState(null);
    const [isDisabled, setDisabled] = useState(true);
    const [jobClosed, setJobClosed] = useState(false);
    const [isJobUnderManagement, setisJobUnderManagement] = useState(false);

    const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);

    const [reason, setReason] = useState("Issue");
    const [reasonCommentaryBox, setReasonCommentaryBox] = useState("");


    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    const fees = {
        afterpay: 0.04,
        stripe: 0.04,
        instore: 0.00
    }





    useEffect(()=>{
        if (!selected_garage) return;

        GarageConfirmJob()

        fetchAuth('/user/manageJob?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, hash: hash})
            })
            .then((resJSON)=>{

                setJobData(resJSON.data[0])









                setJobInformation(resJSON.jobInformation)
                setIsBookingConfirmed(resJSON.isConfirmed)






                if(jobData)
                    setJobNote(jobData.Job_Notes__c);

                setLoading(false)


                return fetchAuth('/user/getMASJobNotes',
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({job_id: resJSON.data[0].sf_id})
                    })


            })




            .then((resJSON)=>{


                setNotes(resJSON.notes)
                setInfoLoading(false)

            })


    }, [selected_garage, vehicle_registration])


    const refreshAfterPay = () => {
        fetchAuth('/user/manageJob?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, hash: hash})
            })

            .then((resJSON)=>{


                if(jobData)
                    setJobNote(jobData.Job_Notes__c);
                setLoading(false)

            })

    }



    const confirmCheckedBookings = (hash) => {

                fetchAuth('/user/confirmbooking?garage=' + selected_garage,
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({garage_id: selected_garage, hash:hash})
                    })
                    .then((resJSON)=>{

                    })

        toast.success("Booking confirmed! Thank you.");
    }



    if (!jobData || !jobInformation)
        return <div style={{marginTop:50}}>
            <Loading></Loading>
        </div>;

    return (
        <Page
            className={classes.root}
            title="Job Management"
        >
            <Container>

                <div className={classes.root}>

                    {/*master grid*/}

                    <Grid container spacing={1}>


                        {/* top bar*/}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>

                            <div style={{
                                display:'flex',
                                flexDirection: 'row',

                                textAlign:'left',
                                justifyContent:'space-between',
                                alignItems:'center'
                              }}>

                                <div style={{display:"flex", flexDirection:'row'}}>
                                    <div style={{display:"flex", flexDirection:'column'}}>
                                        <Button  style={{padding:10, fontSize: 12}} variant="contained" color="primary" size="large" onClick={()=>{navigate(-1)}}>
                                            <BsArrowLeft></BsArrowLeft>
                                        </Button>
                                    </div>
                                </div>


                                <div
                                style={{
                                    fontWeight:'bold',
                                    color: 'black',
                                    fontFamily:'Gilroy',
                                    fontSize:18
                                }}
                               >
                                    Job for {jobData.first_name}'s {jobData.vehicle_make} {jobData.vehicle_model}
                                    {/*{jobData.OpportunityLineItems?jobData.OpportunityLineItems.records.ProductCode:null}*/}
                            </div>

                                <div style={{display:"flex", flexDirection:'row'}}>
                                    <Tooltip title={isBookingConfirmed?"Already Confirmed":"Please confirm the booking by selecting the checkbox on the toolbar below."}  aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                                    <Badge pill variant={isBookingConfirmed?
                                        "success":"danger"
                                    } style={{padding:10, marginRight:25, fontSize: 12}}>
                                        {isBookingConfirmed?
                                            "Booking Confirmed":"Booking Not Confirmed"
                                        }
                                    </Badge>
                                    </Tooltip>


                                    <Badge pill variant="warning" style={{padding:10}}>
                                        Drop off Date: {moment(jobData.pickup_time).format('DD/MM/YYYY')}
                                    </Badge>
                                    <Badge pill variant={jobData.payment_type==="instore"?"danger":"success"} style={{marginLeft:25, padding:10}}>
                                        <div>
                                            {jobData.payment_type === "instore" ?
                                                <div>NOT PAID</div>
                                                : jobData.payment_type === "afterpay" ?
                                                        <div>PAID ONLINE</div>
                                                        : jobData.payment_type === "stripe" ?
                                                            <div>PAID ONLINE</div>
                                                            : null}


                                        </div>
                                    </Badge>

                                    {JSON.parse(jobData.data)?.isManaged && !JSON.parse(jobData.data)?.isClosed?
                                        <Badge pill variant="danger" style={{marginLeft:25, padding:10}}>
                                            Under Management
                                        </Badge>
                                        : JSON.parse(jobData.data)?.isClosed?<Badge pill variant="success" style={{marginLeft:25, padding:10}}>
                                            Closed
                                        </Badge>:null

                                    }


                                </div>
                            </div>

                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container xs={12}>
                                <Grid item xs={12} sm={9}>



                                    <Grid container xs={12} spacing={1} id={'customerInfo'}>


                                    <Grid item xs={12} sm={8}>

                                        <InfoBox title={"Customer Information"} jobData={jobData} icon={BsPeopleCircle()} childicon={BiPhone()} tooltipText={"If you have any questions regarding the customer information, please contact My Auto Shop"} tooltip>
                                            <Grid>
                                                <div style={{marginTop:10, display:"flex", flexDirection:'row', alignItems:'space-between'}}>
                                                    <div>
                                                        <div style={{fontWeight:'bold', fontSize: 18, marginBottom: 10}}>
                                                            {jobData.first_name} {jobData.last_name}
                                                        </div>
                                                        <div>
                                                            <AiOutlineMail style={{marginRight:8}}></AiOutlineMail> {jobData.email}
                                                        </div>
                                                        <div>
                                                            <BiPhone style={{marginRight:8}}></BiPhone> {jobData.phone}
                                                        </div>

                                                        <div>
                                                            <MdSchedule style={{marginRight:8}}></MdSchedule> Scheduled time: {moment(jobData.pickup_time).format('DD/MM/YYYY hh:mm')} PM
                                                        </div>
                                                        <div>

                                                            <AiOutlineCar style={{marginRight:8}}></AiOutlineCar>
                                                            <span></span> Drop off method:

                                                            {jobData.type!=="schedule"?
                                                            <span> Garage to pick up</span> : <span> Customer</span>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div style={{position:'relative', marginLeft: 90}}>
                                                        <AiOutlineCalendar
                                                            size={125}
                                                        />

                                                        <div style={{position:'absolute', left:49, top:28, fontWeight:'bold'}}>
                                                            {moment(jobData.pickup_time).format('MMM')}
                                                        </div>
                                                        <div style={{position:'absolute', left:45, top:55, fontSize:30, fontWeight:'bold'}}>
                                                            {moment(jobData.pickup_time).format('DD')}
                                                        </div>

                                                    </div>

                                                </div>
                                            </Grid>
                                        </InfoBox>

                                    </Grid>


                                        <Grid item xs={12} sm={4}>
                                            <InfoBox
                                                title={"Vehicle Information"} customer={"Paul Rassie"} jobData={jobData} icon={FaCar()}>

                                                <div style={{marginTop:10}}>
                                                    <div>
                                                        {jobData.vehicle_make} {jobData.vehicle_model}
                                                    </div>
                                                    <div>
                                                        {jobData.vehicle_engine}
                                                    </div>
                                                    <div>
                                                        {jobData.year}
                                                    </div>

                                                    <div style={{justifyContent:'center'}}>
                                                        <div
                                                            style={{border: '2px solid rgba(0, 0, 0, 0.7)', borderRadius:5, borderColor:'black', marginTop:20, fontSize:34, fontWeight:'bold', boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)', display:'flex',
                                                                textAlign:'center',
                                                                alignItems:'center',
                                                                justifyContent:'center'}}>
                                                            {jobData.vehicle_rego?jobData.vehicle_rego.toUpperCase():"N/A"}
                                                        </div>
                                                    </div>

                                                </div>


                                            </InfoBox>

                                        </Grid>





                                    </Grid>

                                    <Grid container xs={12}  id={'jobInfo'} spacing={1}>

                                        <Grid item xs={12} sm={8}>

                                                <InfoBox
                                                    title={"Job Information"}
                                                    navigationPath={'/app/pricing/labour'}
                                                    customer={"Paul Rassie"}
                                                    jobData={jobInformation}
                                                    icon={BiNotepad()}
                                                    tooltipText={"If you have any questions regarding your services or pricing, please adjust your pricing or job preferences by clicking here"}
                                                    tooltip>
                                                    <p>
                                                        <div style={{marginTop: "2px"}}>
                                                            <table className={'jobInforTable'} style={{margin:0, width:"100%"}} aria-label={"simple table"}>
                                                                <thead>
                                                                <tr>
                                                                    <th
                                                                        scope={"col"}>Repair/Service
                                                                    </th>
                                                                    <th
                                                                        scope={"col"}>Quantity
                                                                    </th>
                                                                    <th
                                                                        scope={"col"}>Price&nbsp;($)
                                                                    </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {jobInformation.map(j => {
                                                                    return <tr>
                                                                        <td>
                                                                            <svg stroke={"currentColor"} fill={"currentColor"}
                                                                                 stroke-width={"0"} version={"1.2"}
                                                                                 baseProfile={"tiny"} viewBox={"0 0 24 24"}
                                                                                 height={"1em"} width={"1em"}
                                                                                 xmlns={"http://www.w3.org/2000/svg"}>
                                                                                <g>
                                                                                    <circle cx={"8"} cy={"16"} r={"1"}></circle>
                                                                                    <path
                                                                                        d={"M20.733 4.657c-.392-.378-1.013-.377-1.399.009.387-.386.388-1.008.01-1.4-1.078-.792-2.405-1.266-3.844-1.266-3.59 0-6.5 2.91-6.5 6.5l.031.379c-.337.239-2.893 2.147-4.258 3.301-1.135.99-1.773 2.375-1.773 3.82 0 2.757 2.243 5 5 5 1.465 0 2.854-.65 3.811-1.784 1.173-1.375 3.08-3.923 3.317-4.229l.372.013c3.59 0 6.5-2.91 6.5-6.5 0-1.44-.474-2.766-1.267-3.843zm-12.733 14.343c-1.656 0-3-1.343-3-3 0-.92.423-1.732 1.064-2.292 2.368-2.002 3.617-2.748 5.115-4.015-.105-.382-.179-.777-.179-1.193 0-2.485 2.015-4.5 4.5-4.5.47 0 .914.092 1.339.226l-2.839 2.774.5 2.5 2.5.5 2.805-2.741c.115.396.195.807.195 1.241 0 2.485-2.015 4.5-4.5 4.5-.416 0-.811-.074-1.193-.18-1.267 1.498-2.013 2.748-4.024 5.105-.551.652-1.363 1.075-2.283 1.075zm11.384-12.729l-2.705 2.645-1.329-.266-.263-1.314 2.726-2.663c.651.393 1.19.939 1.571 1.598z"}></path>
                                                                                </g>
                                                                            </svg>
                                                                            <span
                                                                                style={{marginLeft: "8px"}}>{j.name}</span>
                                                                        </td>
                                                                        <td>{j.quantity}</td>
                                                                        {j.price ?
                                                                            <td>
                                                                                <span style={{fontWeight: "bold"}}><div>${parseFloat(j.price).toFixed(2)}</div></span>
                                                                            </td>
                                                                            :
                                                                            null
                                                                        }
                                                                    </tr>
                                                                })}
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <div
                                                                    style={{fontSize: "14px", paddingTop: 30}}>Customer
                                                                    Notes: {jobInformation[0]&&jobInformation[0].notes?jobInformation[0].notes:'N/A'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </InfoBox>

                                        </Grid>



                                        <Grid item xs={12} sm={4}>

                                            <InfoBox
                                                title={"Payment Information"} navigationPath={'/app/pricing/labour'}
                                                jobData={jobData} icon={MdAttachMoney()}
                                                tooltip
                                                tooltipText={"If you have any questions regarding the payment information, please adjust your pricing in the pricing tab by clicking here"}>

                                                <div style={{marginTop:6}}>
                                                    <div style={{fontSize:12, color: 'grey'}}>
                                                        <div>
                                                            {jobData.payment_type === "instore" ?
                                                                <div> Payment Type: In-Store</div>
                                                                : null}
                                                            {jobData.payment_type === "afterpay" ?
                                                                <div>Payment Type: AfterPay</div>
                                                                : null}
                                                            {jobData.payment_type === "stripe" ?
                                                                <div>Payment Type: Online</div>
                                                                : null}
                                                        </div>

                                                    </div>
                                                    <div style={{fontSize:34, fontWeight:'bold', paddingTop: 5}}>
                                                        <div>
                                                            {jobData.payment_type === "instore" ?
                                                                <div>${jobInformation?.reduce((accum,item) => accum + (item.price*item.quantity), 0).toFixed(2)}</div>
                                                                : null}
                                                            {jobData.payment_type === "afterpay" ?
                                                                <div>${jobInformation?.reduce((accum,item) => accum + (item.price*item.quantity), 0).toFixed(2)}</div>

                                                                : null}
                                                            {jobData.payment_type === "stripe" ?
                                                                <div>${jobInformation?.reduce((accum,item) => accum + (item.price*item.quantity), 0).toFixed(2)}</div>
                                                                : null}
                                                        </div>


                                                    </div>

                                                    {/*</div>*/}


                                                </div>


                                                <div style={{marginTop:10}}>

                                                    {jobData.payment_type !== "instore" ?
                                                        <div style={{fontSize: 12, color: 'grey'}}>
                                                            <div>

                                                                <div>My Auto Shop will invoice you this amount</div>

                                                            </div>
                                                        </div> :
                                                        null
                                                    }
                                                    {jobData.payment_type !== "instore" ?
                                                        <div style={{fontSize: 24, fontWeight: 'bold'}}>

                                                            <div>${(fees[jobData.payment_type] * jobData.price).toFixed(2)}</div>


                                                        </div>
                                                        :
                                                        null
                                                    }




                                                    <div style={{paddingTop:12}}>


                                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                                        <div style={{fontSize:10, color: 'grey', paddingBottom:5}}>
                                                            Extras to process via AfterPay?
                                                        </div>

                                                        <div>
                                                            <Tooltip title="Refresh" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                                                            <Button onClick={()=>
                                                            {
                                                                refreshAfterPay();
                                                            }}>
                                                                <BiRefresh></BiRefresh>
                                                            </Button>
                                                            </Tooltip>
                                                        </div>
                                                        </div>
                                                            <Button style={{backgroundColor: "#b2fce4", fontSize: 12}} disabled={jobData.extras?.Extra_Requested__c} variant="contained" color="#fecb0a" onClick={()=>
                                                            {
                                                                handleShow3();
                                                            }}>AfterPay Extra Amount
                                                            </Button>
                                                    </div>





                                                    <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
                                                        {jobData.extras?.Extra_Requested__c?
                                                            <div style={{paddingTop:10, marginLeft:10, fontSize:12, fontWeight:'bold'}}>
                                                            {jobData.Extra_Amount_Customer__c?'$' + jobData.Extra_Amount_Customer__c.toFixed(2):''}

                                                            <Badge pill variant={jobData.extras.AP_payment_received__c?"success":'danger'} style={{marginLeft:25, padding:10}}>

                                                                            <div style={{float:'right'}}>{jobData.extras.AP_payment_received__c?'Payment Complete':'Payment Pending'}</div>
                                                            </Badge>
                                                        </div>:null



                                                        }

                                                    </div>



                                                </div>

                                            </InfoBox>
                                        </Grid>




                                    </Grid>

                                </Grid>

                                <Grid item xs={12} sm={3} id={'notes'}>

                                    <InfoBox title={"Job Commentary"} jobData={jobData} icon={GiTalk()}>


                                        <Tooltip title="Add a new note" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                                        <Button style={{borderRadius:25, position:'absolute', top:15, right:25}} variant="contained" color="primary"   onClick={()=>{
                                                    handleShow4();
                                                }}>+</Button>
                                        </Tooltip>




                                        <div style={{display:"flex", flexDirection:'row', width:"100%"}}>


                                            <div style={{paddingBottom:5, width:"100%", maxHeight:485, overflow:'auto', marginTop:10}}>

                                                {infoLoading?<div style={{marginTop:35}}><Loading></Loading> </div>:
                                                notes.map(note=><Box boxShadow={0} style={{padding:5, marginBottom:8, width:"100%"}}>

                                                    <div style={{fontWeight:'bold', fontSize: 13}}>{note.title}</div>
                                                    <div style={{color:'grey', fontSize: 10, paddingBottom:5}}>{moment(note.date).format('DD/MM/YYYY')}</div>
                                                    <div style={{fontStyle:'normal', fontSize: 14}} dangerouslySetInnerHTML={{__html: unescape(note.content)}}>
                                                    </div>


                                                </Box>)


                                                }





                                            </div>






                                        </div>








                                    </InfoBox>





                                </Grid>



                            </Grid>


                        </Grid>






                    </Grid>
                </div>

                <Grid item xs={12} sm={12} id={'action'}>

                    <Paper className={classes.paper}>

                <div style={{display:"flex", flexDirection:'row', justifyContent:'flex-end'}}>

                    <div style={{display:"flex", flexDirection:'column'}}>
                        <Tooltip title="Complete job if all payments have been captured" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>


                        <Button style={{
                            fontSize:13,
                            fontWeight:'bold',
                            padding:18,

                        }}  variant="contained" color="primary" size="large" onClick={()=>
                            // disabled={completeJobDisabled}
                        {
                            handleShow1();


                        }}>
                            Complete Job
                        </Button>
                        </Tooltip>
                        <Modal
                            show={showModal1}
                            onHide={handleClose1}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div>
                                        {jobData.Payment_Type__c === "In-Store" ?
                                            <div>Have you captured all remaining payments from the customer in-store?</div>
                                         : null}
                                        {jobData.Payment_Type__c === "AfterPay" ?
                                            <div>Have you captured all remaining payments?</div>
                                         : null}
                                        {jobData.Payment_Type__c === "Stripe" ?
                                            <div>Have you captured all remaining payments?</div>
                                         : null}

                                    </div>


                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div>
                                    {jobData.Payment_Type__c === "In-Store" ?
                                        <div>Remember that for an in-store payment, you must capture the full amount from the customer</div>
                                        : null}
                                    {jobData.Payment_Type__c === "AfterPay" ?
                                        <div>This payment was captured online on our website, so feel free to invoice us and we will send you what we owe next month!</div>
                                        : null}
                                    {jobData.Payment_Type__c === "Stripe" ?
                                        <div>This payment was captured online on our website, so feel free to invoice us and we will send you what we owe next month!</div>
                                        : null}

                                </div>



                                <Form style={{padding:20}} title={null} defaultValue={null}
                                >


                                    <Form.Group controlId="formGroupEmail">

                                        <Form.Label style={{fontWeight:'bold'}}>Did you complete any extra work on the vehicle?</Form.Label>
                                        <Form.Control required isInvalid type="text" placeholder="Give as much detail as possible on the extra work" onChange={(e)=>{

                                            setOnGoingNoteCommentary(e.target.value)}

                                        } />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a description of the extra work completed including quantity and parts.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" onClick={()=>{
                                    handleClose1();


                                    setOngoingNoteReason("Extra Work");
                                    setReason("Extra Work Items")


                                    fetchAuth('/user/issuejobNote',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({job_id: jobData.Id, notes: unescape(onGoingNoteCommentary), title: "Extra Work Items"})
                                        })
                                        .then((resJSON)=>{



                                            setNotes([...notes, {
                                                title: "Extra Work Items",
                                                content: onGoingNoteCommentary,
                                                date: new Date(),
                                            }]);
                                            setNewJobNote('');

                                        })

                                    fetchAuth('/user/closeJob?garage=' + selected_garage,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({garage_id: selected_garage, hash: jobData.hash})
                                        })
                                        .then((resJSON)=>{
                                            toast.success("Job Closed. Thank you")
                                        })

                                    fetchAuth('/user/logevent?garage=' + selected_garage,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({garage_id: selected_garage, event: "JOB CLOSED"})
                                        })
                                        .then((resJSON)=>{
                                        })


                                    navigate('/app/dashboard/jobmanagement/'+ jobData.hash)

                                }}>
                                    Close Job
                                </Button>
                                <Button variant="primary" onClick={()=>{
                                    handleClose1();
                                }}>
                                    No
                                </Button>

                            </Modal.Footer>
                        </Modal>



                    </div>

                    <div style={{display:"flex", flexDirection:'column', paddingRight: 10}}>


                        <Modal
                            show={showModal3}
                            onHide={handleClose3}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div>
                                        {jobData.Payment_Type__c === "In-Store" ?
                                            <div>Please enter the extra amount the customer would like to put on AfterPay:</div>
                                            : null}
                                        {jobData.Payment_Type__c === "AfterPay" ?
                                            <div>Please enter the extra amount the customer would like to put on AfterPay:</div>
                                            : null}
                                        {jobData.Payment_Type__c === "Stripe" ?
                                            <div>Please enter the extra amount the customer would like to put on AfterPay:</div>
                                            : null}

                                    </div>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>

                                <div style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>

                                    <div style={{width:'100%'}}>

                                        <TextField
                                            style={{width:'100%'}}
                                            id="outlined-multiline-static"
                                                   label="Please detail the extras the customer needs"
                                                   multiline
                                                   required={true}
                                                   rows={3}
                                                   defaultValue=""
                                                   variant="outlined"
                                                   value={null}
                                                   onChange={(e)=>{
                                                       setAfterpayExtraNote(e.target.value);
                                                   }}
                                        >

                                        </TextField>

                                    </div>
                                <div>
                                    <TextField style={{marginTop:10}} onChange={(e)=>{
                                        setafterpayAmount(parseFloat(e.target.value))

                                    }} value={null} rows={2} type="number" label={"Extra Amount($)"} variant={"outlined"} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />




                                </div>


                                </div>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button style={{backgroundColor: "#b2fce4", fontSize: 12}} variant="primary" onClick={()=>{
                                    setafterpayStatus("Payment Complete");
                                    setcompleteJobDisabled(false);
                                    setshowAfterpayField(true);

                                    fetchAPI('/afterpay/v1/create',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({hash:hash, amount: afterpayAmount})
                                        })
                                        .then((resJSON)=>{
                                            // setJobData({...jobData, Extra_Requested__c: true})
                                            handleClose3();

                                        })

                                    fetchAuth('/user/issuejobNote',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({job_id: jobData.Id, notes: unescape(afterpayNote), title: "Extra Work Items"})
                                        })
                                        .then((resJSON)=>{



                                            setNotes([...notes, {
                                                title: "Extra Work Items",
                                                content: onGoingNoteCommentary,
                                                date: new Date(),
                                            }]);
                                            setNewJobNote('');
                                            handleClose3();
                                        })





                                }}
                                >
                                    Submit Payment
                                </Button>


                            </Modal.Footer>
                        </Modal>



                    </div>


                    <div style={{display:"flex", flexDirection:'column', paddingRight: 10}}>


                        <Modal
                            show={showModal4}
                            onHide={handleClose4}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div>
                                        Enter a note for this job
                                    </div>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div style={{width:"100%"}}>
                                    <TextField id="outlined-multiline-static"
                                               label="Job Note"
                                               multiline
                                               rows={5}
                                               defaultValue=""
                                               variant="outlined"
                                               value={newJobNote}
                                               onChange={e=>setNewJobNote(e.target.value)}
                                               style={{width:"100%"}}

                                    >

                                    </TextField>

                                </div>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="primary" onClick={()=>{
                                    handleClose4();
                                    fetchAuth('/user/createjobNotes',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({job_id: jobData.Id, notes: newJobNote})
                                        })
                                        .then((resJSON)=>{

                                            const new_notes = notes;
                                            new_notes.push({
                                                title: "Workshop Note",
                                                content: newJobNote,
                                                date: new Date(),
                                            });

                                            setNotes(new_notes);
                                            setNewJobNote('');

                                        })


                                }}>
                                    Submit Note
                                </Button>


                            </Modal.Footer>
                        </Modal>



                    </div>


                    <div style={{display:"flex", flexDirection:'column', paddingRight: 10}}>
                        <Tooltip title="Did something go wrong?" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                        <Button style={{
                            fontSize:13,
                            fontWeight:'bold',
                            padding:18,
                        }} variant="outlined" color="secondary" size="large" onClick={()=>
                        {
                            handleShow2();
                        }}>
                            Something else?
                        </Button>
                        </Tooltip>

                    </div>


                    <div style={{display:"flex", flexDirection:'column', paddingRight: 10}}>
                        <Tooltip title="Help" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                        <Button style={{
                            fontSize:13,
                            fontWeight:'bold',
                            padding:18,
                            backgroundColor: "#d4d4d4"
                            }} variant="contained" color="secondary" size="large" onClick={()=>
                        {
                            navigate('/app/help/contact');
                            }}>
                            Help
                        </Button>
                        </Tooltip>


                        <Modal
                            show={showModal2}
                            onHide={handleClose2}
                            backdrop="static"
                            keyboard={false}
                            style={{padding:40}}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    {
                                        jobClosed?

                                          <div>  Job Closed!</div>:
                                            <div>    What's up? <span>





                                            </span></div>

                                    }
                                   </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div style={{display:'flex', justifyContent:'start', width:"100%", marginLeft:20}}>
                                    <Dropdown>
                                <DropdownButton variant="warning" title={reason} style={{width:"100%"}}>

                                    <Dropdown.Item value={reason} onClick={()=>{
                                        setReason("Customer didn't show");
                                        setDisabled(false);


                                    }}  as="button">Customer didn't show
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=> {
                                        setReason("More work to be completed");
                                        setDisabled(false);

                                    }}
                                        as="button">More work to be completed
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=> {
                                        setReason("Customer dispute");
                                        setDisabled(false);
                                    }}
                                        as="button">Customer dispute
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=> {
                                        setReason("Something else");
                                        setDisabled(false);
                                    }}
                                        as="button">Something else
                                    </Dropdown.Item>
                                </DropdownButton>
                                    </Dropdown>
                                </div>



                                {reason!=="More work to be completed"?
                                <Form style={{padding:20}} title={null} defaultValue={null}
                                >


                                    <Form.Group controlId="formGroupEmail">

                                        <Form.Label style={{fontWeight:'bold'}}>Notes</Form.Label>
                                        <Form.Control required isInvalid type="text" placeholder="Give as much detail as possible" onChange={(e)=>{
                                            setOnGoingNoteCommentary(e.target.value)}} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter an explanation of what happened.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>:



                                <Form style={{padding:20}} title={null} defaultValue={null}
                                >


                                    <Form.Group controlId="formGroupEmail">

                                        <Form.Label style={{fontWeight:'bold'}}>Extra Work Details</Form.Label>
                                        <Form.Control required isInvalid type="text" placeholder="Give as much detail as possible on the extra work" onChange={(e)=>{
                                            setOnGoingNoteCommentary(e.target.value)}} />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a description of the extra work completed including quantity and parts.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                                }

                                <div style={{fontSize:12, color:'lightslategray', marginLeft:20}}>
                                    This booking will be moved to the 'Open Jobs' folder for you to revisit when needed.
                                </div>


                            </Modal.Body>
                            <Modal.Footer>


                                <Button disabled={isDisabled}
                                             variant="primary" onClick={()=>{
                                        // console.log("reason", onGoingNoteCommentary)
                                        handleClose2();

                                    setOngoingNoteReason(reason);


                                    fetchAuth('/user/issuejobNote',
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({job_id: jobData.sf_id, notes: unescape(onGoingNoteCommentary), title: reason})
                                        })
                                        .then((resJSON)=>{



                                            setNotes([...notes, {
                                                title: reason,
                                                content: onGoingNoteCommentary,
                                                date: new Date(),
                                            }]);
                                            setNewJobNote('');

                                        })






                                        fetchAuth('/user/incompleteJob?garage=' + selected_garage,
                                            {
                                                method: 'POST',
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({garage_id: selected_garage, hash: jobData.hash, reason: reason})
                                            })
                                            .then((resJSON)=>{
                                            })

                                    fetchAuth('/user/logevent?garage=' + selected_garage,
                                        {
                                            method: 'POST',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({garage_id: selected_garage, event: "JOB_MANAGED"})
                                        })
                                        .then((resJSON)=>{
                                        })




                                    }}>
                                        Done
                                    </Button>

                            </Modal.Footer>
                        </Modal>

                    </div>

                    <div style={{display:"flex", flexDirection:'column', paddingRight: 10}}>
                        <Tooltip title="Reschedule Booking" aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>

                        <Button style={{
                            fontSize:13,
                            fontWeight:'bold',
                            padding:18,
                            backgroundColor: "#d4d4d4"
                        }} variant="contained" color="secondary" size="large" onClick={()=>
                        {
                            handleShow5();
                        }}>
                            Reschedule Booking
                        </Button>
                        </Tooltip>


                        <Modal
                            show={showModal5}
                            onHide={handleClose5}
                            backdrop="static"
                            keyboard={false}
                            style={{padding:40}}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div>Are you sure you want to reschedule this booking?</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div style={{display:'flex', justifyContent:'center', width:"100%", fontSize:12, fontWeight:'normal', fontStyle:'italic', color:'lightslategray'}}>
                                    Please select a new pick up and drop off time and what date for the customer to drop their car off:
                                    Please keep in mind the customer also when rescheduling jobs:
                                </div>

                                <Row>
                                    <div style={{display:'flex', justifyContent:'center', width:"100%", padding:20}}>

                                        <Button variant={"contained"} color={"primary"} size={"large"} onClick={()=>{
                                            window.open("https://book.myautoshop.co.nz/manage/" + jobData.Hash__c);

                                            fetchAuth('/user/logevent?garage=' + selected_garage,
                                                {
                                                    method: 'POST',
                                                    headers: {
                                                        'Accept': 'application/json',
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({garage_id: selected_garage, event: "JOB_RESCHEDULED"})
                                                })
                                                .then((resJSON)=>{
                                                })


                                        }}>Reschedule</Button>
                                    </div>


                                </Row>







                            </Modal.Body>
                            {/*<Modal.Footer>*/}


                            {/*    /!*<Button disabled={isDisabled}*!/*/}
                            {/*    /!*        variant="primary">*!/*/}
                            {/*    /!*    Done*!/*/}
                            {/*    /!*</Button>*!/*/}

                            {/*</Modal.Footer>*/}
                        </Modal>


                        <Modal
                            show={confirmModal}
                            onHide={handleCloseConfirmModal}
                            backdrop="static"
                            keyboard={false}
                            style={{padding:40}}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Please confirm this booking
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <div>
                                    <Tooltip
                                        title={isBookingConfirmed ? "Already Confirmed" : "Confirm Booking"}
                                        aria-label="add" TransitionComponent={Fade} TransitionProps={{timeout: 600}}
                                        disableFocusListener disableTouchListener interactive arrow>

                                        <Checkbox checked={isBookingConfirmed}
                                                  value={isBookingConfirmed}
                                                  disabled={isBookingConfirmed ? true : false}
                                                  onChange={() => {
                                                      setIsBookingConfirmed(true);

                                                      confirmCheckedBookings(jobData.hash);

                                                  }
                                                  }


                                        >
                                            Booking Confirmed

                                        </Checkbox>
                                    </Tooltip>

                                    <span style={{marginTop: 10}}>
                                        Confirm Booking
                                    </span>

                                </div>





                            </Modal.Body>

                        </Modal>

                    </div>

                        <div style={{display: "flex", flexDirection: 'row'}}>

                            <div>
                                <Tooltip
                                    title={isBookingConfirmed ? "Already Confirmed" : "Confirm Booking"}
                                    aria-label="add" TransitionComponent={Fade} TransitionProps={{timeout: 600}}
                                    disableFocusListener disableTouchListener interactive arrow>

                                    <Checkbox checked={isBookingConfirmed}
                                              value={isBookingConfirmed}
                                              disabled={isBookingConfirmed ? true : false}
                                              onChange={() => {
                                                  setIsBookingConfirmed(true);

                                                  confirmCheckedBookings(jobData.hash);

                                              }
                                              }


                                    >
                                        Booking Confirmed
                                    </Checkbox>
                                </Tooltip>

                            </div>

                            <span style={{marginTop: 5}}>
                            Confirm Booking
                        </span>


                        </div>





                </div>
                {/*navigate('/app/help/contact')*/}

                    </Paper>
                </Grid>




                </Container>
        </Page>
    );
};

export default JobManagement;
