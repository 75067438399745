import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField,FormControl,Input,InputLabel, InputBase,fade, withStyles,
    FormControlLabel,Switch
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import {PricingInput} from "./components/PricingInput";
import InputSlider from "./components/InputSlider";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";

import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));



const Adjustment = ({selected_garage}) => {
    const classes = useStyles();

    const [pricing, setPricing] = useState({});
    const [loading, setLoading] = useState(true);
    const [adjustment, setAdjustment] = useState({});

    const [value, setValue] = React.useState(0);



    useEffect(()=>{
        if (!selected_garage) return;
        fetchAuth('/pricing/adjustment?garage=' + selected_garage)
            .then((resJSON)=>{
                setPricing(resJSON.adjustment)
                setLoading(false)
            })

    }, [selected_garage])

    const onSave = () => {
        fetchAuth('/pricing/adjustment?garage=' + selected_garage,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({pricing:pricing})
        })
            .then((resJSON)=>{
                toast.success("Margin Adjustments Saved!");
            })
    }

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Pricing"
        >
            <Container>

                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <InputSlider
                                title={"Labour Margin Adjustment"}
                                value={pricing.labour_adjustment}
                                setValue={(value)=>{
                                    setPricing({...pricing, labour_adjustment: value})
                                }}
                                min={-50}
                                max={50}
                            />

                            <InputSlider
                                title={"Parts Margin Adjustment"}
                                value={pricing.parts_adjustment}
                                setValue={(value)=>{
                                    setPricing({...pricing, parts_adjustment: value})
                                }}
                                min={0}
                                max={200}
                            />


                            <div>
                                <Button variant="contained" color="primary" onClick={onSave}>Save</Button>

                            </div>


                        </Box>
                    </CardContent>

                </Card>

            </Container>
        </Page>
    );
};

export default Adjustment;
