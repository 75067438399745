import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableContainer, TablePagination, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem,Select, MenuItem, Slider
} from '@material-ui/core';
import Page from 'src/components/Page';
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import Modal from 'react-bootstrap/Modal';
import Checkbox from "@material-ui/core/Checkbox";
import {PricingInput} from "../pricing/components/PricingInput";
import {TiDelete} from "react-icons/all";




const ExtrasView = ({selected_garage}) => {

    const useStyles = makeStyles((theme) => ({
        root: {},
        actions: {
            justifyContent: 'flex-end'
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },
        table: {
            minWidth: "30%",
        },
    }));

    const classes = useStyles();

    const [defaultExtras, setDefaultExtras]=useState([
        {
            title: "Customer's vehicle pick up & drop off",
            is_enabled:0,
            price: 38,
            default: true
        },

        {
            title: "Complementary Loan Car",
            is_enabled:0,
            price: null,
            default: true

        },
        {
            title: "Tyre Rotation",
            is_enabled:0,
            price: 40.00,
            default: true

        },
        {
            title: "Car Wash",
            is_enabled:0,
            price: 25,
            default: true

        },
        {
            title: "Car Vacuum",
            is_enabled:0,
            price: 20,
            default: true

        },
        {
            title: "Warrant of Fitness",
            is_enabled:0,
            price: 45,
            default: true

        },
        {
            title: "Wiper Blade Refill",
            is_enabled:0,
            price: 20,
            default: true

        },
        {
            title: "Wheel Alignment",
            is_enabled:0,
            price: 79,
            default: true

        },
        {
            title: "Wheel Balance",
            is_enabled:0,
            price: 79,
            default: true

        },
        {
            title: "Diagnostic",
            is_enabled:0,
            price: 45,
            default: true

        }

    ]);






    const [loading, setLoading] = useState(true);

    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [pulledExtras, setPulledExtras] = useState([]);
    const [extras, setExtras] = useState([]);


    const [customExtra, setCustomExtras] = useState(
        {
            title:null,
            price:null,
            is_enabled:null
        }
    );

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);






    const onSave = (open) => {

        fetchAuth('/pricing/updateextras?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(

                    {garage_id: selected_garage,
                        to_change: extras
                        })
            })
            .then((resJSON)=>{
                toast.success(open?"Extras Saved":"Extras Updated")
            })


        // setTimeout(false)

    }

    const onDelete = (id) => {

        fetchAuth('/pricing/delete?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(

                    {garage_id: selected_garage,
                        extra_id: id?id:undefined
                    })
            })
            .then((resJSON)=>{
                toast.success("Extra Deleted")
            })

    }






    useEffect(()=>{
        if (!selected_garage) return;



        fetchAuth(`/pricing/getextras?garage=${selected_garage}`)
            .then((resJSON)=> {

                if (resJSON.extras?.length > 0) {
                    setExtras(resJSON.extras)

                } else {


                setExtras(defaultExtras)
                    onSave()

            }

                setLoading(false)

            })

    }, [selected_garage])



    // useEffect(()=>{
    //
    //     setExtras(extras => [...extras, customExtra]);
    //
    // }, [])




    // const filteredExtras = pulledExtras.filter(pulled => extras.map(extra => extra.title.includes(pulled.title)));

    return (
        <Page
            className={classes.root}
            title="Customer Reviews"
        >

            <div style={{
                marginLeft:40,
                fontSize: 45,
                fontWeight:'bold',
                paddingBottom: 5}}>
                <Divider light orientation="horizontal" flexItem />
            </div>

            {/* Customer Reviews*/}
            <Container maxWidth="lg">
                <Grid container md={12}>


                    {loading?<Loading></Loading>
                    :


                        <Grid item md={6}>
                            <Card style={{height:"100%"}}>
                                <Box style={{padding:10}}>
                                    <div style={{width:"100%", height:"100%"}}>
                                        <div style={{fontWeight:'bold', fontSize: 24, padding:10, maxHeight:850, overflow:'auto'}}>
                                            Extras

                                            <div style={{fontWeight:'normal', color:'grey', fontSize: 12, paddingTop:10, paddingBottom:10}}>
                                                Please select & price the extras you would like to provide. After each edit or addition, please remember to save.
                                            </div>


                                            {extras.map((extra, index) => (
                                            <div style={{fontWeight:'normal', fontSize: 16, width:"100%"}}>

                                                <div style={{display:"flex", justifyContent:'space-between'}}>

                                                    <div style={{display:"flex", justifyContent:'flex-start', alignItems:'center', alignContent:'center'}}>
                                                    <div>
                                                        <Checkbox
                                                            name={extra.is_enabled}
                                                            value={extra.is_enabled}
                                                            checked={extra.is_enabled?"on":false}
                                                            onChange={(e)=>{
                                                                let extras_copy = [...extras];
                                                                extras_copy[index] = {
                                                                    ...extras_copy[index],
                                                                    is_enabled: e.target.checked?1:0
                                                                }
                                                                setExtras(extras_copy)

                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        {extra.title}
                                                    </div>

                                                    </div>




                                                    <div style={{display:'flex', flexDirection:'row', alignContent:'center', alignItems:'center'}}>
                                                        <PricingInput
                                                            value={extra.price}
                                                            name={extra.title}
                                                            type="number"
                                                            title="$"

                                                            onChange={(e)=>{
                                                                let extras_copy = [...extras];
                                                                extras_copy[index] = {
                                                                    ...extras_copy[index],
                                                                    price: e.target.value
                                                                }
                                                                setExtras(extras_copy)

                                                            }}
                                                            placeholder={extra.price}

                                                        ></PricingInput>



                                                        <Button
                                                        onClick={()=> {
                                                            onDelete(extra.id)
                                                            console.log("test delete", extra.id)
                                                        }}

                                                        > <TiDelete size={17}></TiDelete> </Button>

                                                    </div>
                                                </div>



                                            </div>

                                                ))}


                                        </div>

                                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                                            <Button variant="outlined" color="primary" onClick={handleShow} style={{padding:10}}>Add Custom</Button>

                                            <Button variant="contained" disabled={disabled} color="primary" onClick={

                                                ()=> {

                                                    onSave()
                                                    setDisabled(true)

                                                }




                                            } style={{padding:10, marginLeft:10}}>Save</Button>
                                        </div>

                                    </div>
                                </Box>
                            </Card>

                        </Grid>
                    }
                </Grid>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add custom extra</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div>
                                <PricingInput
                                    title="Extra Name"

                                    onChange={(e)=>{
                                        let custom_extra = {...customExtra};

                                        custom_extra = {
                                            ...custom_extra,
                                            title: e.target.value,
                                            is_enabled: 1
                                        }
                                        setCustomExtras(custom_extra)

                                    }}
                                >
                                </PricingInput>

                            </div>

                            <div>
                                <PricingInput
                                    title="$ Amount"
                                    onChange={(e)=>{
                                        let custom_extra = {...customExtra};

                                        custom_extra = {
                                            ...custom_extra,
                                            price: parseInt(e.target.value)
                                        }
                                        setCustomExtras(custom_extra)

                                    }}
                                >
                                </PricingInput>

                            </div>



                        </div>


                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={()=> {
                            setExtras(extras => [...extras, customExtra]);

                            handleClose();


                        }

                        }>
                            Add Extra
                        </Button>
                    </Modal.Footer>
                </Modal>



            </Container>


        </Page>


    )


}

export default ExtrasView;
