import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,
    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import Paper from "@material-ui/core/Paper";
import {BsArrowLeft} from "react-icons/bs";
import theme from "../../theme";
import Badge from "react-bootstrap/Badge";
import {useNavigate, useLocation} from "react-router-dom";
import GaragePortalTutorial from "../../usertutorial/GaragePortalTour";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ContactUsView = ({selected_garage, setIsTourOpen}) => {
    const classes = useStyles();

    const [quotes, setQuotes] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate=useNavigate();

    const [value, setValue] = React.useState(0);

    useEffect(()=>{
        if (!selected_garage) return;
        setLoading(false)

        // fetchAuth('/quotes/requests?garage=' + selected_garage)
        //     .then((resJSON)=>{
        //         setQuotes(resJSON.quotes)
        //         setLoading(false)
        //         console.log(resJSON)
        //     })

    }, [selected_garage])

    // const onSave = () => {
    //     fetchAuth('/pricing/adjustment?garage=' + selected_garage,{
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({pricing:quotes})
    //     })
    //         .then((resJSON)=>{
    //             toast.success("Adjustments Saved!");
    //         })
    // }

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Contact Us"
        >
            <Container maxWidth="lg">

                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <div style={{
                        display:'flex',
                        flexDirection: 'row',
                        padding:20,
                        textAlign:'left',
                        justifyContent:'left',
                        alignItems:'center'
                    }}>
                        <div>

                            <Button  style={{padding:10, fontSize: 14}} variant="contained" color="primary" size="large" onClick={()=>{window.open("https://drive.google.com/file/d/18j-SLpxZ1x3NDC6xoOIYbAunchL7-lqF/view?usp=sharing")}}>
                                FAQ's
                            </Button>
                        </div>
                        <div>
                            <Button  style={{padding:10, fontSize: 14, marginLeft:20}} variant="contained" color="primary" size="large" onClick={()=>{window.open("https://drive.google.com/file/d/12hBJzSU5s1cGa_tRXPjlqniVFfMpEMK3/view?usp=sharing")}}>
                                Payment FAQ's
                            </Button>
                        </div>
                        <div>
                            <Button  style={{padding:10, fontSize: 14, marginLeft:20}} variant="contained" color="primary" size="large" onClick={()=>{window.open("https://drive.google.com/file/d/1GZ9eIQX4sWRSwDex39vYk6taxXBwr9oi/view?usp=sharing")}}>
                                2021 Servicing Schedule
                            </Button>
                        </div>
                        {/*<div>*/}
                        {/*    <Button  style={{padding:10, fontSize: 14, marginLeft:20}} variant="contained" color="primary" size="large" onClick={()=>setIsTourOpen(true)}>*/}
                        {/*        Garage Portal Walkthrough*/}
                        {/*    </Button>*/}
                        {/*</div>*/}
                    </div>

                    </Paper>
                </Grid>

                <Card style={{marginTop:10}}>
                    <CardHeader
                        subheader="Get in touch with the team at My Auto Shop"
                        // subheader="Get in touch with the team at My Auto Shop"
                        title="Contact Us"
                    />
                    <Divider />

                    <CardContent>
                        Email the team at <a href="mailto:hello@myautoshop.co.nz">hello@myautoshop.co.nz</a> or call <a href="tel:6498878317">09 887 8317</a>.</CardContent>
                    {/*<CardContent>*/}


                    {/*    <TextField*/}
                    {/*        fullWidth*/}
                    {/*        label="Subject"*/}
                    {/*        name="subject"*/}
                    {/*        // onChange={handleChange}*/}
                    {/*        // required*/}
                    {/*        // value={values.firstName}*/}
                    {/*        variant="outlined"*/}
                    {/*    />*/}
                    {/*    <TextField*/}
                    {/*        fullWidth*/}
                    {/*        label="Message"*/}
                    {/*        name="message"*/}
                    {/*        // onChange={handleChange}*/}
                    {/*        // required*/}
                    {/*        // value={values.firstName}*/}
                    {/*        variant="outlined"*/}
                    {/*        multiline*/}
                    {/*        rows={10}*/}
                    {/*        style={{marginTop:20}}*/}
                    {/*    />*/}

                    {/*</CardContent>*/}
                    {/*<Divider />*/}
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    justifyContent="flex-end"*/}
                    {/*    p={2}*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        color="primary"*/}
                    {/*        variant="contained"*/}
                    {/*    >*/}
                    {/*        Email Support*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </Card>
            </Container>
        </Page>
    );
};

export default ContactUsView;
