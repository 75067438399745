import 'react-perfect-scrollbar/dist/css/styles.css';
import React, {useEffect, useState} from 'react';
import { useRoutes } from 'react-router-dom';
import {ThemeProvider, Typography} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import Routes from 'src/routes';
import { withAuthenticator, AmplifyAuthenticator, AmplifySignIn, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifySignUp } from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import LoginView from "./views/auth/LoginView";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import Tour from 'reactour'
import GaragePortalTutorial from "./usertutorial/GaragePortalTour";
import Page from "./components/Page";
import 'react-quill/dist/quill.snow.css'; // ES6
import {fetchAuth} from "./utils/fetch";


const App = () => {

    const MASTheme = {
        signInButton: { backgroundColor: 'black' },
        signInButtonContent: {color:'blue'}
    }

    const [isTourOpen, setIsTourOpen] = useState(false);
    const [shortTut, setShortTut] = useState(false);

    const [garage, setGarage] = useState(null)
    const [garageName, setGarageName] = useState('')
    const [garageList, setGarageList] = useState([])
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)

    let isLoggedIn=false;

    useEffect(()=>{

        onAuthUIStateChange((nextAuthState, authData) => {
            setUser(authData)




            if (nextAuthState === "signedin") {

                if (!isLoggedIn) {
                    fetchAuth('/user/logevent?garage=' + garage,
                        {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({garage_id: garage, event: "USER_LOGGED_IN"})
                        })
                        .then((resJSON)=>{
                        })
                }


            isLoggedIn=true;
                fetchAuth('/user/garages')
                    .then((resJSON)=>{
                        setGarageList(resJSON.garages);
                        if (resJSON.garages.length > 0){
                            setGarage(resJSON.garages[0].id);
                            setGarageName(resJSON.garages[0].name);
                        }
                        setLoading(false)

                    })




            }
        });

    }, [])


    return (

      <AmplifyAuthenticator usernameAlias="email" style={{
          '--amplify-primary-color': '#fecb0a',
      }}>


          <div className="App" slot="sign-in" style={{
              display:'flex',
              height:'100vh',
              width:'100%',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: "#fecb0a"
          }}>
              <div style={{
                  display:'flex',
                  flexDirection: 'row'
              }}>
                  <div>
                      <div>
                          <img src={'/static/mechanicdude.png'} width={100} style={{objectFit: 'contain', marginBottom:-20}}/>
                          <img src={'/static/mechanichublogin.png'} width={400} style={{objectFit: 'cover', marginBottom:0}}/>

                      </div>
                      <AmplifySignIn slot="sign-in"/>


                  </div>
              </div>


          </div>

          <AmplifySignUp
              slot="sign-up"
              formFields={[
                  { type: "username" },
                  {type: "password"},
              ]}
          />
          <AmplifyRequireNewPassword slot={'require-new-password'} user={user}/>


          <ThemeProvider theme={theme}>
              <GlobalStyles />
              <Routes
                  setIsTourOpen={setIsTourOpen}
                  garage={garage}
                  setGarage={setGarage}
                  garageList={garageList}
                  setGarageList={setGarageList}
                  garageName={setGarageName}
                  setGarageName={setGarageName}
                  loading={loading}
                  setShortTut={setShortTut}

              />
              <ToastContainer />

              <GaragePortalTutorial isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} shortTut={shortTut} setShortTut={setShortTut}/>

          </ThemeProvider>



      </AmplifyAuthenticator>





  );
};

export default App;
