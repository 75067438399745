import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    colors
} from '@material-ui/core';

import {AiOutlineArrowUp} from "react-icons/all";
import {AiOutlineArrowDown} from "react-icons/all";
import {BsFileDiff} from "react-icons/all";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles(() => ({
    root: {
        height: '100%'
    },
    avatar: {
        height: 46,
        width: 56
    }
}));




const SummaryBox = ({tooltipVisible, tooltipText, inverse, className, render, comparisonInfo, garagePerformanceUp, comparisonData, difference, iconDirection, comparisonText, up, data, title, Icon, color, ...rest }) => {
    const classes = useStyles();

    const [praise, setPraise] = useState("Good job! You're performing better than other My Auto Shop garages")
    const [scold, setScold] = useState("Yikes! You're underperforming in comparison to other My Auto Shop garages")

    return (



        <Tooltip  title={tooltipVisible?inverse?scold:up?praise:scold:tooltipText} aria-label="add" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive arrow>
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>

                <Grid
                    container
                    justify="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                            style={{fontFamily:'Gilroy', fontWeight:'bold'}}
                        >
                            {title}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                            style={{fontFamily:'raleway', fontWeight:'bold'}}
                        >
                            {data}

                        </Typography>

                        {render?


                            (up?
                            <span style={{color:'green', fontSize:12}}>
                            <BsFileDiff></BsFileDiff> Your Performance: {difference.toFixed()}% <span> <AiOutlineArrowUp></AiOutlineArrowUp> </span>
                        </span>:
                            <span style={{color:'red', fontSize:12}}>
                            <BsFileDiff></BsFileDiff> Your Performance: {difference.toFixed()}% <span> <AiOutlineArrowDown></AiOutlineArrowDown> </span>
                        </span>

                            ):null

                        }

                        {inverse?


                            (up?
                                    <span style={{color:'green', fontSize:12}}>
                            <BsFileDiff></BsFileDiff> Your Performance: {difference} <span> <AiOutlineArrowUp></AiOutlineArrowUp> </span>
                        </span>:
                                    <span style={{color:'red', fontSize:12}}>
                            <BsFileDiff></BsFileDiff> Your Performance: {difference} <span> <AiOutlineArrowDown></AiOutlineArrowDown> </span>
                        </span>

                            ): null

                        }





                    </Grid>



                    {comparisonInfo?
                    <div style={{fontSize:12, color:'lightslategray', fontStyle:'italic', marginLeft:15}}>


                            <div>
                                {comparisonText} <span>{comparisonData}</span>
                            </div>

                    </div>:
                        null

                    }


                </Grid>


            </CardContent>
        </Card>

        </Tooltip>
    );
};

export default SummaryBox;
