import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import {makeStyles, useMediaQuery, useTheme} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {Auth} from 'aws-amplify'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({garage_list, set_garage, selected_garage, items, loading}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
      !loading && garage_list.length === 0?<div style={{display:'flex', justifyContent:'center', marginTop:50}}>You are not authorised for mechanic hub. <a href="#" onClick={()=>Auth.signOut()}>Logout</a></div>:
    <div className={classes.root}>

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        garage_list={garage_list}
        set_garage={set_garage}
        selected_garage={selected_garage}
        items={items}
      />
      <div className={classes.wrapper}>

        <div className={classes.contentContainer}>
          <div className={classes.content}>

            <div style={{position:'fixed', width:matches?'calc(100% - 255px)':'100%', zIndex:1000}}>
              <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} items={items}/>
            </div>
            <div style={{marginTop:64}}>
              <Outlet />

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
