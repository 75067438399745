import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Grid,
    Paper,
    Tooltip,
    makeStyles, Container, TablePagination
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {fetchAuth} from "../../utils/fetch";
import Loading from "../../components/Loading";


const data = [
    {
        id: uuid(),
        ref: 'CDD1049',
        amount: 30.5,
        customer: {
            name: 'Ekaterina Tankova'
        },
        createdAt: 1555016400000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1048',
        amount: 25.1,
        customer: {
            name: 'Cao Yu'
        },
        createdAt: 1555016400000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1047',
        amount: 10.99,
        customer: {
            name: 'Alexa Richardson'
        },
        createdAt: 1554930000000,
        status: 'refunded'
    },
    {
        id: uuid(),
        ref: 'CDD1046',
        amount: 96.43,
        customer: {
            name: 'Anje Keizer'
        },
        createdAt: 1554757200000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1045',
        amount: 32.54,
        customer: {
            name: 'Clarke Gillebert'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1044',
        amount: 16.76,
        customer: {
            name: 'Adam Denisov'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    }
];

const useStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: "30%",
    },
}));

const MonthlyPaymentsView = ({selected_garage, className, ...rest }) => {
    const classes = useStyles();
    const [orders] = useState(data);
    const [month, setMonth] = React.useState(1);
    const [MonthlyPaymentData, setMonthlyPaymentData]=useState([]);
    const [loading, setLoading] = useState(true);

    const [year, setYear]=React.useState(2021);

    const [color, setColor]=useState("primary");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(  0);
    };


    const handleChange = (event) => {
        setMonth(event.target.value);
    };


    useEffect(()=>{
        if (!selected_garage) return;
        // setLoading(false)
        // quotes
        fetchAuth(`/user/monthlyStatements?garage=${selected_garage}&month=${month}&year=${year}`)
            .then((resJSON)=>{
                setMonthlyPaymentData(resJSON.data)
                setLoading(false)
                // console.log('monthlypayments', resJSON.data)
            })

    }, [selected_garage, month, year])

    if (loading)
        return <Loading/>





    return (

        <Container maxWidth={false} style={{paddingTop: 20}}>
            <div>

                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">Payment Month</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={month}
                        onChange={handleChange}
                    >
                        <MenuItem value="This Month">
                            <em>This Month</em>
                        </MenuItem>
                        <MenuItem value={1}>January 2021</MenuItem>
                        <MenuItem value={2}>February 2021</MenuItem>
                        <MenuItem value={3}>March 2021</MenuItem>
                        <MenuItem value={4}>April 2021</MenuItem>
                        <MenuItem value={5}>May 2021</MenuItem>
                        <MenuItem value={6}>June 2021</MenuItem>
                        <MenuItem value={7}>July 2021</MenuItem>
                        <MenuItem value={8}>August 2021</MenuItem>
                        <MenuItem value={9}>September 2021</MenuItem>
                        <MenuItem value={10}>October 2021</MenuItem>
                        <MenuItem value={11}>November 2021</MenuItem>
                        <MenuItem value={12}>December 2021</MenuItem>
                    </Select>
                    <FormHelperText>Select the month of payment you would like to view</FormHelperText>
                </FormControl>

            </div>



        {/*table*/}
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >



            <CardHeader title="Here are your payments for the month" />
            <Divider />
            <PerfectScrollbar>
                <Box minWidth={400}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection="desc">
                                    <Tooltip
                                        enterDelay={300}
                                        title="Sort"
                                    >
                                        <TableSortLabel
                                            active
                                            direction="desc"
                                        >
                                            Booking Date
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    Car Registration
                                </TableCell>
                                <TableCell>
                                    Status
                                </TableCell>
                                <TableCell>
                                    Payment Type
                                </TableCell>
                                <TableCell>
                                    Total Amount
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {MonthlyPaymentData.slice((page)*rowsPerPage, (page+1)*rowsPerPage).map((order) => (
                                <TableRow
                                    hover
                                    key={order.id}
                                >
                                    <TableCell>


                                        {moment(order.pickup_time).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>
                                        {order.vehicle_rego.toUpperCase()}
                                    </TableCell>
                                    <TableCell>
                                        <Chip



                                            color={color}
                                            label={order.status}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            color={color}
                                            label={order.payment_type}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        ${order.price.toFixed(2)}

                                    </TableCell>


                                </TableRow>
                            ))}





                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[3, 4, 5]}
                        component="div"
                        count={MonthlyPaymentData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Box>
            </PerfectScrollbar>
            <Divider/>

            {/*<PerfectScrollbar>*/}
            {/*<Box*/}
            {/*    display="flex"*/}
            {/*    justifyContent="flex-end"*/}
            {/*    p={2}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        color={color}*/}
            {/*        endIcon={<ArrowRightIcon />}*/}
            {/*        size="small"*/}
            {/*        variant="text"*/}
            {/*    >*/}
            {/*        Download Invoice*/}
            {/*    </Button>*/}

            {/*</Box>*/}
            {/*</PerfectScrollbar>*/}



        </Card>




            <Card style={{marginTop:20}}>

                <CardHeader title="Payment Summary" />

                <Paper className={classes.paper}>


                <Table aria-label="spanning table">



                    <TableBody>
                                <TableRow>
                                    <TableCell style={{fontWeight:"bold"}}> Total (incl. GST)</TableCell>
                                    <TableCell align="center" style={{fontSize:"24"}}>${MonthlyPaymentData.reduce((accum,item) => accum + item.price, 0).toFixed(2)}</TableCell>
                                </TableRow>

                            </TableBody>




            </Table>
                </Paper>

            </Card>
        </Container>
    );
};

MonthlyPaymentsView.propTypes = {
    className: PropTypes.string
};

export default MonthlyPaymentsView;
