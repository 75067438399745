import React from 'react';
import {
  Box,
  Container,
  Typography,
    Button,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFoundView = () => {
  const classes = useStyles();
  const navigate=useNavigate();

  return (
    <Page
      className={classes.root}
      title="404"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >

        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
            style={{paddingBottom:40}}
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation

          </Typography>
          <Button style={{padding:20, display:'flex', alignItems:'center'}} variant={"contained"} color={'primary'}>Return to home</Button>



        </Container>
      </Box>

    </Page>
  );
};

export default NotFoundView;
