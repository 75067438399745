import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles, TablePagination
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {useNavigate} from "react-router-dom";
import Loading from "../../../components/Loading";
import styles from '../../../styles/job/job.scss'


const data = [
  {
    id: uuid(),
    ref: 'CDD1049',
    amount: 30.5,
    customer: {
      name: 'Ekaterina Tankova'
    },
    createdAt: 1555016400000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1048',
    amount: 25.1,
    customer: {
      name: 'Cao Yu'
    },
    createdAt: 1555016400000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1047',
    amount: 10.99,
    customer: {
      name: 'Alexa Richardson'
    },
    createdAt: 1554930000000,
    status: 'refunded'
  },
  {
    id: uuid(),
    ref: 'CDD1046',
    amount: 96.43,
    customer: {
      name: 'Anje Keizer'
    },
    createdAt: 1554757200000,
    status: 'pending'
  },
  {
    id: uuid(),
    ref: 'CDD1045',
    amount: 32.54,
    customer: {
      name: 'Clarke Gillebert'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  },
  {
    id: uuid(),
    ref: 'CDD1044',
    amount: 16.76,
    customer: {
      name: 'Adam Denisov'
    },
    createdAt: 1554670800000,
    status: 'delivered'
  }
];

function createData(name, rating, job, feedback, fault) {
  const density = job / feedback;
  return { name, rating, job, feedback, fault};


}

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
  {
    id: 'population',
    label: 'Population',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'size',
    label: 'Size\u00a0(km\u00b2)',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'density',
    label: 'Density',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];



const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  tableCell: {
    fontFamily:'Gilroy-Light'
  },
  tableHeaderCell: {
    fontFamily:'Gilroy',
    fontWeight:'bold',
    fontSize:14
  }
}));

const LatestOrders = ({message, isLoading, setIsLoading, quotes, className, ...rest }) => {
  const classes = useStyles();
  const [orders] = useState(data);
  const navigate=useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(  0);
  };

  useEffect(()=>{
    if (quotes.length) {
      setIsLoading(false)
    }

  }, [quotes])

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{display:'flex', flexDirection:'column', height:'100%'}}
    >
      <CardHeader title="Pending Quote Requests" titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}}/>
      <Divider />

        <PerfectScrollbar>
          <Box flexGrow={1}>
            <div>
              {
                isLoading?
                    <div style={{marginTop:25}}>
                      <Loading>
                      </Loading>
                    </div>
                    :

                quotes.length===0?
                  <div style={{fontWeight:'normal', alignText:'center', marginLeft:20, marginTop: 20, width:"100%", fontStyle:'italic', fontFamily:'Gilroy'}}>{message}</div>

                  :



                  <div>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>

                          <TableCell className={classes.tableHeaderCell}>
                            Quote ID
                          </TableCell>
                          <TableCell className={classes.tableHeaderCell}>
                            Services
                          </TableCell>
                          <TableCell className={classes.tableHeaderCell}>
                            Date Requested
                          </TableCell>
                          <TableCell className={classes.tableHeaderCell}>
                            Actions
                          </TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quotes.slice((page)*rowsPerPage, (page+1)*rowsPerPage).map((quote) => (
                            <TableRow
                                hover
                                key={quote.id}
                            >
                              <TableCell className={classes.tableCell}>
                                {quote.id}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <ul style={{margin:0}}>
                                  {quote.services.map((item)=>{
                                    return <li>{item.title}</li>})}

                                </ul>
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {moment(quote.date_created).format('DD/MM/YYYY')}
                              </TableCell>
                              <TableCell style={{whiteSpace:'nowrap' }}  className={classes.tableCell}>
                                <button className={'manage-button'}  variant="contained" color="primary" onClick={()=>{
                                  navigate('/app/quotes/quote/' + quote.identifier)}}>
                                  Quote
                                </button>
                                <button className={'quote-job-button-decline'}  style={{marginLeft:10, padding:5}}  onClick={()=>{
                                  window.open(quote.quote_url + '/decline', '_blank');
                                }}>
                                  Decline
                                </button>
                              </TableCell>
                              {/*<TableCell>*/}
                              {/*  {quote.rego}*/}
                              {/*</TableCell>*/}
                              {/*<TableCell>*/}
                              {/*  {quote.make} {quote.model}*/}
                              {/*</TableCell>*/}


                              {/*<TableCell>*/}
                              {/*    <Chip*/}
                              {/*        color="primary"*/}
                              {/*        label="Pending"*/}
                              {/*        size="small"*/}
                              {/*    />*/}
                              {/*</TableCell>*/}

                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {quotes.length>=3?
                    <TablePagination
                        rowsPerPageOptions={[3, 4, 5]}
                        component="div"
                        count={quotes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    ></TablePagination>:
                        null
                    }
                  </div>
              }
            </div>


          </Box>
        </PerfectScrollbar>


      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={()=>{navigate('/app/quotes/requests')}}
        >
          View Quoted Jobs
        </Button>


      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
