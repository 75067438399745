import React, {useEffect, useState} from 'react';
import {
    colors,
    Container,
    Grid,
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

import Sales from "../reports/DashboardView/Sales";
import SummaryBox from "../reports/DashboardView/SummaryBox";
import {fetchAuth} from "../../utils/fetch";
import {AttachMoney, Description, CalendarToday, DriveEta} from '@material-ui/icons';
import Loading from "../../components/Loading";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const GarageDataView = ({selected_garage}) => {
    const classes = useStyles();



    const [data, setData] = useState([]);
    const [garage, setGarageData] = useState([]);
    const [TimeQuoteData, setTimeQuoteData] = useState([]);
    const [quoteData, setQuoteData] = useState({});
    const [pending, setPending] = useState(0);
    const [loading, setLoading] = useState(true);

    const fakegaragequoterate=0.70;
    const fakegaragehours=4.2;

    const [qeDataMonth, setqeDataMonth] = useState([])
    const [qeDataYear, setqeDataYear] = useState([])
    const [graphData, setGraphData] = useState([])


    useEffect(()=>{
        if (!selected_garage) return;


        fetchAuth('/user/data?garage=' + selected_garage)
            .then((resJSON)=>{
                console.log(resJSON)
                setData(resJSON.data[0])
                setqeDataMonth(resJSON.month_results[0])
                setqeDataYear(resJSON.year_results[0])
                setGraphData(resJSON.revenue)
                setLoading(false)

            })

    }, [selected_garage])

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Your Data"
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox
                                    tooltipText={"How often you quote"}
                                    data={(data.quote_rate*100).toFixed() + "%"}
                                    tooltipVisible={true}
                                    title={"YOUR QUOTE PERCENTAGE"}
                                    Icon={Description}
                                    color={colors.green[600]}
                                    comparisonInfo
                                    comparisonData={fakegaragequoterate*100 + "%"}
                                    render
                                    comparisonText={"Avg. Garage Quote Rate:"}
                                    up={data.quote_rate > fakegaragequoterate?true:false}
                                    difference={data.quote_rate*100 - fakegaragequoterate*100}
                                    garagePerformanceUp
                                    inverse={false}
                        />
                    </Grid>
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >


                    </Grid>

                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox tooltipText={"The total number of quotes booked with you"} data={data.total_booked} title={"TOTAL QUOTES BOOKED"} Icon={DriveEta} color={colors.orange[600]}/>
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                    <SummaryBox tooltipText={"The total number of quotes My Auto Shop has sent you"} data={data.total_number_sent} title={"TOTAL QUOTE OPPORTUNITIES"} Icon={DriveEta} color={colors.orange[600]}/>
                    </Grid>





                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox data={qeDataMonth.month_bookings} title={"TOTAL JOBS THIS MONTH"} Icon={DriveEta} color={colors.orange[600]}/>
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox  data={qeDataYear.year_bookings} title={"TOTAL JOBS THIS YEAR"} Icon={DriveEta} color={colors.orange[600]}/>
                    </Grid>


                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox       data={"$" + parseFloat(qeDataMonth.month_rev).toFixed(2)} tooltipVisible={false} title={"TOTAL REVENUE THIS MONTH"} Icon={CalendarToday} />

                    </Grid>
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <SummaryBox tooltipText={"Let's get you that cash!"} data={"$" + parseFloat(qeDataYear.year_rev).toFixed(2)} tooltipVisible={false} title={"TOTAL REVENUE THIS YEAR"} Icon={AttachMoney} color={colors.red[600]} />
                    </Grid>





                    {graphData.length?
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xl={12}
                        xs={12}
                    >
                        <Sales data={graphData} />
                    </Grid>
                        :
                        null
                    }



                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    lg={3}*/}
                    {/*    md={3}*/}
                    {/*    xl={3}*/}
                    {/*    xs={3}*/}
                    {/*>*/}

                    {/*    <SummaryBox data={"$" + parseFloat(data.total_year).toFixed(2)} title={"CUSTOMER SATISFACTION RATING"} />*/}

                    {/*</Grid>*/}

                    {/*<Grid*/}
                    {/*    item*/}
                    {/*    lg={3}*/}
                    {/*    md={3}*/}
                    {/*    xl={3}*/}
                    {/*    xs={3}*/}
                    {/*>*/}
                    {/*    Anonymous Percentage Comparison Table to region of Quote Times and Rates*/}
                    {/*</Grid>*/}



                </Grid>
            </Container>
        </Page>
    );
};

export default GarageDataView;
