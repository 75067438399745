import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Divider,
    Grid,
    makeStyles,
    Typography,
    TextField,
    FormControl,
    Input,
    InputLabel,
    InputBase,
    fade,
    withStyles,
    FormControlLabel,
    Switch,
    TableHead,
    TableContainer,
    TablePagination,
    TableRow,
    TableCell,
    Tooltip,
    TableSortLabel,
    TableBody,
    Chip,
    Table,
    List,
    ListItem,
    Select,
    MenuItem,
    Slider,
    CardHeader
} from '@material-ui/core';
import Page from 'src/components/Page';
import clsx from "clsx";
import moment from "moment";
import {fetchAuth} from "../../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {garage_messages} from '../Reviews_Engagement/garage_messages';
import mas_man from "src/assets/mas_man.png";
import {MdRateReview} from 'react-icons/md'
import ReactStars from "react-rating-stars-component";
import { render } from "react-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
        width: '100%'
    },
    container: {
        maxHeight: 440,
    }

}))

const columns = [
    { id: 'name', label: 'Customer Name', minWidth: 170 },
    { id: 'rating', label: 'Rating', minWidth: 100 },
    {
        id: 'job',
        label: 'Job Type',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
    },
    {
        id: 'feedback',
        label: 'Comment',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
    }
];

function createData(name, rating, job, feedback, fault) {
    const density = job / feedback;
    return { name, rating, job, feedback, fault};
}


const rows = [
    createData('India', 'IN', 1324171354, 3287263),

];







const CustomerReviewView = ({selected_garage}) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [loading, setLoading] = useState(true);
    const [reviews, setReviews]=useState([]);
    const [rating, setRating]=useState([]);
    const [empty, setEmpty]=useState(false);
    const [satisfation_rating, setAvgRating]=useState(rating);
    const [average_garage_rating, setGarageAvgRating]=useState(5);
    const [data, setData] = useState({});

    const garage_average=60;
    const other_garages_average_rating=70;
    const difference = (other_garages_average_rating-garage_average);

    useEffect(()=>{
        if (!selected_garage) return;

        fetchAuth('/reviews/ratings?garage=' + selected_garage)
            .then((resJSON)=>{
                setRating(resJSON.allRatingData)
                setAvgRating(resJSON.garageRatingData.averageRating)
                setGarageAvgRating(resJSON.allRatingData.averageRating)
                setLoading(false)

            })

        fetchAuth('/reviews/reviews?garage=' + selected_garage)
            .then((resJSON)=>{
                setReviews(resJSON.data)
                setLoading(false)
                setEmpty(false);
                console.log('review', resJSON)

                if (resJSON.data.length===0)
                    setEmpty(true);
                    setLoading(false)

                // console.log("Review Array is Empty", resJSON)

            })

    }, [selected_garage])



    // empty page container

    if (empty)
        return  <Page
            className={classes.root}
            title="Customer Reviews"
        >

            <div style={{
                marginLeft:40,
                fontSize: 45,
                fontWeight:'bold',
                paddingBottom: 5}}>
                <Divider light orientation="horizontal" flexItem />
            </div>

            {/* Customer Reviews*/}
            <Container maxWidth="lg">
                <Card>


                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >

                            <Grid container>

                                <Grid item xs={12}>
                                    <div>

                                        <div>

                                            <div style={{
                                                display:'flex',
                                                flexDirection: 'row',
                                                marginBottom:5,

                                                textAlign:'center',
                                                justifyContent:'center',
                                                fontSize: 24,
                                                paddingBottom:10,
                                                fontVariant:'small-caps',
                                                fontWeight:'normal',
                                                color: 'grey'}}>
                                                You currently have no reviews from customers
                                            </div>

                                        </div>

                                        <Divider></Divider>
                                        <div style={{display:'flex',
                                            flexDirection: 'column'}}>

                                            <div style={{
                                                marginBottom:10,
                                                marginRight:20,
                                                paddingTop:10,
                                                textAlign:'right',
                                                fontSize: 24,
                                                fontWeight:'normal',
                                                color: 'grey'}}>
                                                <div style={{ display:'flex', flexDirection:'column', alignItems:'center', fontSize: 18, fontStyle: 'italic'}}>
                                                    Come back later to see what customers are saying about you

                                                </div>
                                            </div>




                                        </div>


                                    </div>








                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>









        </Page>;

    if (loading)
        return <Loading/>


    return (
        <Page
            className={classes.root}
            title="Customer Reviews"
        >

            <div style={{
                marginLeft:40,
                fontSize: 45,
                fontWeight:'bold',
                paddingBottom: 5}}>
                <Divider light orientation="horizontal" flexItem />
            </div>

            {/* Customer Reviews*/}
            <Container maxWidth="lg">
                <Card>
                    <CardContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <div>

                                <div>
                                    <div style={{
                                        display:'flex',
                                        flexDirection: 'row',
                                        textAlign:'center',
                                        justifyContent:'center',
                                        fontSize: 82,
                                        fontWeight:'bold'}}>
                                        {satisfation_rating}
                                    </div>
                                    <div style={{
                                        display:'flex',
                                        flexDirection: 'row',
                                        marginBottom:5,

                                        textAlign:'center',
                                        justifyContent:'center',
                                        fontSize: 24,
                                        fontVariant:'small-caps',
                                        fontWeight:'normal',
                                        color: 'grey'}}>
                                        satisfaction rating
                                    </div>
                                    <div style={{
                                        display:'flex',
                                        flexDirection: 'row',
                                        marginBottom:5,
                                        textAlign:'center',
                                        justifyContent:'center',
                                        fontSize: 24,
                                        fontVariant:'small-caps',
                                        fontWeight:'normal',
                                        color: 'grey'}}>
                                        <ReactStars

                                            count={5}
                                            value={satisfation_rating}
                                            size={24}
                                            edit={false}
                                            isHalf={true}
                                            activeColor="#ffd700"



                                        >

                                        </ReactStars>
                                    </div>

                                </div>
                                    <div style={{display:'flex',
                                        flexDirection: 'column'}}>

                                    <div style={{
                                        marginBottom:10,
                                        marginRight:20,
                                        textAlign:'right',
                                        fontSize: 24,
                                        fontWeight:'normal',
                                        color: 'grey'}}>
                                        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', fontSize: 18, fontStyle: 'italic'}}>
                                            {satisfation_rating*20 > garage_messages['mid_range']
                                                ?<div>{garage_messages['high_range_blurb']}</div>
                                                :(satisfation_rating*20 < garage_messages['mid_range']
                                                    ?<div>{garage_messages['low_range_blurb']}</div>
                                                    :<div>{garage_messages['mid_range_blurb']}</div>)}

                                        </div>
                                    </div>




                                    </div>


                                    </div>






                                    <Divider></Divider>

                                    <Grid style={{display:'flex',
                                        flexDirection:'row',
                                        fontVariant:'small-caps',
                                        paddingBottom:5,
                                        paddingTop:20
                                        }}>


                                        How you compare to other garages:
                                    </Grid>

<div>
                                        <ProgressBar label={"Your Garage Rating - "+`${satisfation_rating}`} variant="warning" now={satisfation_rating*20}/>
</div>
                                    <div style={{paddingTop: 5}}>

                                    <ProgressBar label={"Other Garages - 4.8"} variant="success" now={4.8*20}/>
                                    </div>


                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Container>





            {/*table*/}
            {/* Ratings*/}
            <Container maxWidth="lg" style={{paddingTop: 10}}>


                {/*<div style={{*/}
                {/*    marginLeft:40,*/}
                {/*    fontSize: 20,*/}
                {/*    fontWeight:'bold'}}>*/}

                {/*    <Typography>*/}
                {/*        /!*<MdRateReview></MdRateReview>*!/*/}
                {/*        <h1 style={{fontSize: 20}}>Recent Customer Ratings & Comments</h1>*/}
                {/*    </Typography>*/}


                {/*    <Divider light orientation="horizontal" flexItem />*/}
                {/*</div>*/}



                <Card style={{}}>

                    <CardHeader title="Recent Customer Ratings & Comments" />

                    <CardContent>

                        <Box
                            display="flex"
                            flexDirection="column"
                            boxShadow={2}

                        >

                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {/*<TableCell style={{ minWidth: columns.minWidth }}>*/}
                                            {/*    Booking ID*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell sortDirection="desc">*/}
                                            {/*    <Tooltip*/}
                                            {/*        enterDelay={300}*/}
                                            {/*        title="Sort"*/}
                                            {/*    >*/}
                                            {/*        <TableSortLabel*/}
                                            {/*            active*/}
                                            {/*            direction="desc"*/}
                                            {/*        >*/}
                                            {/*            Rating*/}
                                            {/*        </TableSortLabel>*/}
                                            {/*    </Tooltip>*/}
                                            {/*</TableCell>*/}
                                            <TableCell>
                                                Rating
                                            </TableCell>
                                            <TableCell>
                                                Feedback
                                            </TableCell>
                                            <TableCell>
                                                Date
                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reviews.slice((page)*rowsPerPage, (page+1)*rowsPerPage).map((info) => (
                                            <TableRow
                                                hover
                                                // key={info.id}
                                            >
                                                {/*<TableCell>*/}
                                                {/*    {info.booking_id}*/}

                                                {/*</TableCell>*/}
                                                <TableCell>
                                                    {info.rating}
                                                </TableCell>
                                                <TableCell>
                                                    {info.comments?<div>{info.comments}</div>:<div>N/A</div>}
                                                </TableCell>


                                                <TableCell>
                                                    {moment(info.created_at).format('DD/MM/YYYY')}
                                                </TableCell>


                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[3, 4, 5]}
                                    component="div"
                                    count={reviews.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Box>
                    </CardContent>
                </Card>
            </Container>



        </Page>




    )


}

export default CustomerReviewView;
