import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,

    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader, withMobileDialog,
} from '@material-ui/core';
import Page from 'src/components/Page';


import styles from "../../styles/quote/quote.scss"
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));




const DeclinedQuoteView = ({selected_garage, title, buttons, message, type}) => {
    const classes = useStyles();

    const params = useParams();
    const identifier = params.identifier

    const [quotes, setQuotes] = useState([]);
    const [breakdown, setBreakdown] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate=useNavigate();

    const [notes, setNotes] = useState(
        ""

    );

    const [sendNotes, setSendNotes] = useState([]);

    const [disabled, setDisabled] = useState(true)


    const [declined, setDeclinedQuote] = useState(false)
    const [declinedReason, setDeclinedReason] = useState("")


    const [displayJob, setDisplayJob] = useState([

    ])
    const [job, setJob] = useState([

    ])

    const [quote, setQuote] = useState([])
    const [total, setTotal] = useState(null)
    const [inHand, setInHand] = useState(null)


    const [jobNotes, setJobNotes] = useState([

    ])


    const [items_1, setItems_1] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )

    const [items_2, setItems_2] = useState(
        {
            quantity:null,
            price:null,
            product:null,
        }

    )




    // useEffect(()=>{
    //     if (!selected_garage) return;
    //     fetchQuote('/'+identifier)
    //         .then((resJSON)=>{
    //
    //             setQuote(resJSON.quote)
    //             setJob(resJSON.quote.breakdown)
    //             setNotes(resJSON.quote.notes)
    //             setTotal(resJSON.quote.total)
    //             setInHand(resJSON.quote.in_hand)
    //             setLoading(false)
    //             setItems_2({...items_2, product: resJSON.quote.services[0].title + " Labour"})
    //
    //             console.log("retrieved quote", resJSON.quote)
    //             console.log(resJSON.quote.breakdown)
    //
    //         })
    //
    // }, [selected_garage])


    const declineQuoteReason = (value) => {

        fetchQuote('/' + identifier + '/decline',{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                reason:value,
                identifier:identifier

            })
        })
            .then(()=>{
                toast.success("Thanks for your response");
                navigate('/app/quotes/requests')
            })

    }



    return (
        <Page
            className={styles.quotePage}
            title="Quote"
        >
            <div className={"quotePage"}>
            <Container  maxWidth="lg">


                    <Card style={{overflow:'auto'}} style={{padding:25}}>

                        <div style={{textAlign:'left'}} className={"header"}>
                            Thanks.
                        </div>
                        <div className={"disclaimer"}>
                           You have declined this quote request. You will no longer be able to quote on this job. Please contact us if you change your mind.
                       </div>

                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", marginTop:10}}>
                            Please let us know your reason for declining:

                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", marginTop:10}}>
                                <Button style={{marginRight:10}} color={"primary"} variant={"outlined"} onClick={()=>{
                                    declineQuoteReason("Too busy")
                                }}>
                                    Too busy
                                </Button>
                                <Button style={{marginRight:10}} color={"primary"} variant={"outlined"} onClick={()=>{
                                    declineQuoteReason("Not enough information")
                                }}>
                                Not enough information
                                </Button>
                                <Button style={{marginRight:10}} color={"primary"} variant={"outlined"} onClick={()=>{
                                    declineQuoteReason("Need to see car")
                                }}>
                                    Need to see car
                                </Button>
                                <Button color={"primary"} variant={"outlined"} onClick={()=>{
                                    declineQuoteReason("Other")
                                }}>
                                    Other
                                </Button>
                            </div>


                            <a style={{marginTop:10}} onClick={()=> {
                                navigate('/app/quotes/requests')
                            }}>Return to quotes</a>
                        </div>





                    </Card>


            </Container>
            </div>

        </Page>
    );
};

export default DeclinedQuoteView;
