import React, {useEffect, useState} from 'react';
import {
    Container,
    Grid,
    makeStyles,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import {fetchAuth} from "../../utils/fetch";
import Loading from "../../components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const Account = ({selected_garage}) => {
    const classes = useStyles();
    const [garage, setGarage] = useState({});
    const [loading, setLoading] = useState(false);

    if (loading)
        return <Loading/>

    return (
        <Page
            className={classes.root}
            title="Account"
        >
            <Container maxWidth="lg">

                <div>
                    <Button
                        color="primary"
                        variant="contained"
                    >Connect to Freshbooks Accounting</Button>
                </div>
                <div style={{marginTop:20}}>
                    <Button
                        color="primary"
                        variant="contained"

                    >Connect to Salesforce</Button>
                </div>

                <div style={{marginTop:20}}>
                    <Button
                        color="primary"
                        variant="contained"

                    >Connect to Google Calendar</Button>
                </div>

            </Container>
        </Page>
    );
};

export default Account;
