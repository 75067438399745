import React, { useState } from 'react'
import Tour from 'reactour'
import Button from "@material-ui/core/Button";

const LongTutorialSteps = [


    {
        selector: '#firsttable',
        content: () => (
            <div>
                Welcome to the My Auto Shop Garage Tutorial. You will be able to manage all your jobs, manage your data, pricing & availability in one easy place.
            </div>


        )
    },

    {
        selector: '#firsttable',
        content: () => (
            <div>
                Take a look at your upcoming jobs. These are your new upcoming bookings.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },




    {
        selector: '#todaysjobs',
        content: () => (
            <div>
                Here you will find your job's for the day.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#quotes',
        content: () => (
            <div>
                Pending quote requests will show up here - remember to quote jobs each day to maximise your bookings!
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#openjobs',
        content: () => (
            <div>
                Most importantly, here are your open jobs. These are jobs that have yet to be completed or 'Under Management'
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },



    {
        selector: '#actionbar',
        content: () => (
            <div>
                If you need to refresh the page, get help, or adjust your pricing, check the action bar out!
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#joblookup',
        content: () => (
            <div>
                Life gets easy when you can easily search a registration of a vehicle here.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#todaysjobs',
        content: () => (
            <div>
                Next, let's see all a job's information. To view, click the manage button on a job.
            </div>


        ),
        action: (node) => {
            if (node)
                node.focus()

        },
    },

    {
        selector: '#root',
        content: () => (
            <div>
                Here's everything you need to know about the customer and the vehicle
            </div>


        ),
        action: (node) => {

            function getElementByXpath(path) {
                return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
            }

            getElementByXpath("//div[@id='todaysjobs']//button[contains(@class, 'manage-button')]").click()
            // node.focus()
        },
    }
    ,

    {
        selector: '#jobInfo',
        content: () => (
            <div>
                Here's everything you need to know about the job & work required, and how much money is coming through the door.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#notes',
        content: () => (
            <div>
                We encourage you to add notes about the job as it goes on or if something changes. The notes we send about the job will also populate here.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#action',
        content: () => (
            <div>
At the conclusion of a job, you will need to close it. If everything went smoothly, just hit 'Complete Job'. If it didn't hit, 'Something Else' and follow the instructions.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    }
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.makeStyles-wrapper-2 > div > div > div:nth-child(2) > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-11 > div > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 > div:nth-child(4)',
    //     content: () => (
    //         <div>
    //             Remember to close jobs at the end of the day by clicking the close button!
    //         </div>
    //
    //     )
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.makeStyles-wrapper-2 > div > div > div:nth-child(2) > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-11 > div > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3 > div:nth-child(3) > div',
    //     content: () => (
    //         <div style={{fontWeight:'bold'}}>
    //             Click manage <span>to view a job details</span>
    //         </div>
    //
    //     ),
    //     action: node => {
    //         // by using this, focus trap is temporary disabled
    //         node.focus()
    //     }
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.makeStyles-wrapper-2 > div > div > div:nth-child(2) > div',
    //     content: () => (
    //         <div>
    //             Take a look at all the job details!
    //         </div>
    //
    //     )
    // },
    //
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.makeStyles-wrapper-2 > div > div > div:nth-child(2) > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12 > div > div > div:nth-child(1)',
    //     content: () => (
    //         <div>
    //             Complete Job/Close Job
    //         </div>
    //
    //     )
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.makeStyles-wrapper-2 > div > div > div:nth-child(2) > div > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-12 > div > div > div:nth-child(4) > button',
    //     content: () => (
    //         <div>
    //             Something else?
    //         </div>
    //
    //     )
    // },
    //
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.MuiDrawer-root.MuiDrawer-docked > div > div > div.MuiBox-root.MuiBox-root-15 > li:nth-child(3) > a > span.MuiTouchRipple-root',
    //     content: () => (
    //         <div>
    //             See your available quote requests and quoted jobs here
    //         </div>
    //
    //     ),
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.MuiDrawer-root.MuiDrawer-docked > div > div > div.MuiBox-root.MuiBox-root-15 > li:nth-child(6) > a > span.MuiTouchRipple-root',
    //     content: () => (
    //         <div>
    //            Change your availability here
    //         </div>
    //
    //     )
    // },
    // {
    //     selector: '#nav-header-1 > div.MuiAccordionSummary-content',
    //     content: () => (
    //         <div>
    //             Don't forget your pricing too
    //         </div>
    //
    //     )
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.MuiDrawer-root.MuiDrawer-docked > div > div > div.MuiBox-root.MuiBox-root-15 > li:nth-child(8) > a > span.MuiTouchRipple-root',
    //     content: () => (
    //         <div>
    //             View your monthly payments
    //         </div>
    //
    //     )
    // },
    // {
    //     selector: '#root > amplify-authenticator > div.makeStyles-root-1 > div.MuiDrawer-root.MuiDrawer-docked > div > div > div.MuiBox-root.MuiBox-root-15 > li:nth-child(9) > a > span.MuiTouchRipple-root',
    //     content: () => (
    //         <div>
    //             If you need help, click here
    //         </div>
    //
    //     )
    // }
    // ...
];

const ShortTutorialSteps = [


    {
        selector: '#firsttable',
        content: () => (
            <div>
                Welcome to the My Auto Shop Garage Tutorial. You will be able to manage all your jobs, manage your data, pricing & availability in one easy place.
            </div>

        )
    },

    {
        selector: '#firsttable',
        content: () => (
            <div>
                Take a look at your upcoming jobs. These are your new upcoming bookings.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },




    {
        selector: '#todaysjobs',
        content: () => (
            <div>
                Here you will find your job's for the day.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#quotes',
        content: () => (
            <div>
                Pending quote requests will show up here - remember to quote jobs each day to maximise your bookings!
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#openjobs',
        content: () => (
            <div>
                Most importantly, here are your open jobs. These are jobs that have yet to be completed or 'Under Management'
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },



    {
        selector: '#actionbar',
        content: () => (
            <div>
                If you need to refresh the page, get help, or adjust your pricing, check the action bar out!
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#joblookup',
        content: () => (
            <div>
                Life gets easy when you can easily search a registration of a vehicle here.
            </div>


        ),
        action: (node) => {
            node.focus()
        },
    },

    {
        selector: '#todaysjobs',
        content: () => (
            <div>
                When you have a job, there will be a manage job button on this table which you can click.
            </div>


        ),
        action: (node) => {
            if (node)
                node.focus()
        },
    },


];






const GaragePortalTutorial = ({isTourOpen, setIsTourOpen, shortTut, setShortTut}) => {

    const [disabled, isDisabled] = useState(false);



    return (
            <Tour
                startAt={0}
                accentColor={"#fecb0a"}
                steps={!shortTut?LongTutorialSteps:ShortTutorialSteps}
                isOpen={isTourOpen}
                disableKeyboardNavigation={['esc']}
                onRequestClose={() => setIsTourOpen(false)}
                lastStepNextButton={<Button>Done!</Button>}
                showCloseButton={false}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                disableDotsNavigation={true}
                disableFocusLock={false}
                showNavigation={true}
                prevButton={<div></div>}


            />
    )
};


export default GaragePortalTutorial;
