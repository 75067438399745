import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {IconButton} from "@material-ui/core";
import {Auth} from "aws-amplify";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "./Loading";

const useStyles = makeStyles((theme) => ({
    root: {},
    editButton: {

    }
}));


const Price = ({price, variant_row, overrides, index, setOverrides, onInputChange, overrideFunction, row, type}) => {

    const [edit, setEdit] = useState(false)



  return (
    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end', alignContent:'center', alignItems:'center'}}>

        {!edit ?

                <div>
                    ${price}

                </div>

                :
            <input  onChange={(event)=>{


                onInputChange(event, row['Make'],row['Model'], variant_row, type, index)

            }} placeholder={"$" + price} name={'price'} value={overrides.price} type="text"/>
        }



        <div>
            {!edit ?
                <IconButton size="small" color="inherit"  onClick={()=>{
                    setEdit(!edit)
                }}
                            >
                    <EditIcon />
                </IconButton>



                :
                <div>
                    <IconButton size="small" color="inherit"  onClick={() => {
                        setEdit(false)
                        overrideFunction()
                    }}
                    >
                        <CheckIcon />
                    </IconButton>
                    <IconButton size="small"  onClick={()=>{
                        setEdit(false)
                    }}>
                        <CancelIcon></CancelIcon>
                    </IconButton>
                </div>


            }
        </div>

    </div>
  );
};

export default Price;
