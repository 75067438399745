import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import theme from "../../theme";
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography,
    makeStyles,
    Button
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import {IoInformationOutline} from "react-icons/all";
import {BiQuestionMark} from "react-icons/all";
import Fade from '@material-ui/core/Fade';
import { withStyles} from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import {useNavigate} from "react-router";


const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        width:'100%',
        boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)'
    },
    avatar: {
        height: 56,
        width: 56
    }
}));




const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const InfoBox = ({title, jobData, children, icon, tooltipText, tooltip, tooltipButton, navigationPath}) => {
    const classes = useStyles();
    // console.log('jobData', jobData);

    const navigate=useNavigate();

    if (!jobData) return <div/>;

    return (
        <Card
            className={clsx(classes.root)}
            style={{position:'relative', boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.2)'}}
        >
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    spacing={1}

                >




                    <Grid item style={{padding:10, fontSize: 10,width:'100%'
                        }}>



                    <div style={{width:"100%", justifyContent:"space-between", display:'flex'}}>

                        <Typography
                            style={{
                                fontVariant:'small-caps',
                                fontWeight:'bold',
                                fontFamily:'Gilroy',
                                color: theme.palette.text.secondary}}

                            variant="h5"
                        >
                            {icon} <span style={{marginLeft:5}}>  {title}</span>

                        </Typography>

                        {tooltip?
                            <div style={{marginLeft:10, display:'inline'}}>
                                <a onClick={()=>{navigate(navigationPath?navigationPath:'/')}}>
                                <HtmlTooltip fullWidth={false} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive title={tooltipText} placement="bottom-start">
                                        <span style={{
                                            marginLeft:10,
                                            width: 30,
                                            height: 30,
                                            backgroundColor: "#e3e3e3",
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems:'center',
                                            display: 'flex'

                                        }}>
                                    <BiQuestionMark size={15}/>
                                    </span>



                                </HtmlTooltip>

                                </a>
                            </div>

                            :null}
                    </div>




                        <Typography style={{topPadding:10, fontFamily:'Gilroy-Light'}}>
                              {children}

                        </Typography>

                    </Grid>


                </Grid>

            </CardContent>
        </Card>
    );
};

export default InfoBox;
