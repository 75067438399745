import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Grid,
    Paper,
    Tooltip,
    makeStyles, Container, TablePagination
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {fetchAuth} from "../../utils/fetch";
import Loading from "../../components/Loading";
import TableContainer from "@material-ui/core/TableContainer";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const data = [
    {
        id: uuid(),
        ref: 'CDD1049',
        amount: 30.5,
        customer: {
            name: 'Ekaterina Tankova'
        },
        createdAt: 1555016400000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1048',
        amount: 25.1,
        customer: {
            name: 'Cao Yu'
        },
        createdAt: 1555016400000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1047',
        amount: 10.99,
        customer: {
            name: 'Alexa Richardson'
        },
        createdAt: 1554930000000,
        status: 'refunded'
    },
    {
        id: uuid(),
        ref: 'CDD1046',
        amount: 96.43,
        customer: {
            name: 'Anje Keizer'
        },
        createdAt: 1554757200000,
        status: 'pending'
    },
    {
        id: uuid(),
        ref: 'CDD1045',
        amount: 32.54,
        customer: {
            name: 'Clarke Gillebert'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    },
    {
        id: uuid(),
        ref: 'CDD1044',
        amount: 16.76,
        customer: {
            name: 'Adam Denisov'
        },
        createdAt: 1554670800000,
        status: 'delivered'
    }
];

const useStyles = makeStyles((theme) => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: "30%",
    },
}));

const InvoicesView = ({selected_garage, className, ...rest }) => {
    const classes = useStyles();
    const [orders] = useState(data);
    const [month, setMonth] = React.useState(1);
    const [MonthlyInvoice, setMonthlyInvoice]=useState([]);
    const [loading, setLoading] = useState(true);

    const [year, setYear]=React.useState(2022);

    const [color, setColor]=useState("primary");

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(  0);
    };


    const handleChange = (event) => {
        setMonth(event.target.value);
    };

    const [empty, setEmpty]=useState(false)


    useEffect(()=>{
        if (!selected_garage) return;

        fetchAuth(`/user/monthlyInvoices?garage=` + selected_garage)
            .then((resJSON)=>{
                setMonthlyInvoice(resJSON.invoiceData)
                setLoading(false)
                // console.log('monthlyInvoices', resJSON.invoiceData)
                setEmpty(false);

                if (resJSON.invoiceData?.length===0)
                    setEmpty(true);
                setLoading(false)
            })



    }, [selected_garage, month, year])

    if (loading)
        return <Loading/>





    return (


        <Container maxWidth={false} style={{paddingTop: 20}}>

            {empty ?
                <Card
                    className={clsx(classes.root, className)}
                    {...rest}
                >
                    <CardHeader title="Sorry! There are no invoices."/>
                    <Divider/>

                </Card> :


                <div>


                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invoice Month</TableCell>
                                    <TableCell align="right">Amount ($)</TableCell>
                                    <TableCell align="right">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {MonthlyInvoice.map((row) => (
                                    <TableRow>
                                        <TableCell align="left" component="th" scope="row"
                                                   style={{fontWeight: 'bold', fontSize: '14'}}>
                                            {row.key.split("/")[0]}
                                        </TableCell>
                                        <TableCell align="right" style={{fontWeight: 'bold'}}>${row.total}</TableCell>
                                        <TableCell align="right" color={"warning"} variant="warning"><Button
                                            onClick={() => window.open(row.url)}>Download</Button></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </div>
            }




        </Container>
    );
};

InvoicesView.propTypes = {
    className: PropTypes.string
};

export default InvoicesView;
