import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Loading from "../../../../components/Loading";
import {
    Box,
    Button,
    Card,
    CardHeader,
    Chip,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    makeStyles, TablePagination
} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import Fade from "@material-ui/core/Fade";
import {BiQuestionMark} from "react-icons/all";
import {toast} from "react-toastify";

import styles from "../../../../styles/job/job.scss"
import {fetchAuth} from "../../../../utils/fetch";






const useStyles = makeStyles(() => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    },
    tableCell: {
        fontFamily:'Gilroy-Light'
    },
    tableHeaderCell: {
        fontFamily:'Gilroy',
        fontWeight:'bold',
        fontSize:14
    }
}));



const JobTable = ({onClick, tooltip, isLoading, setIsLoading, selected_garage, tooltipText, close, onClick2, message, data, title, button_text, dualButton,  className, ...rest }) => {
    const classes = useStyles();
    const navigate=useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);

    const [infoLoading, setInfoLoading]=useState(true);

    const [show, setShow]=useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const closeJob = (info) => {
        fetchAuth('/user/closeJob?garage=' + selected_garage,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({garage_id: selected_garage, hash: info.hash})
            })
            .then((resJSON)=>{
                toast.success("Booking Closed")
            })
    };



    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(  0);
    };

    useEffect(()=>{
        if (data) {
            setIsLoading(false)
        }

    }, [data])

    return (
        <Card

            className={clsx(classes.root, className)}
            {...rest}
            style={{shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 3,
                },
                shadowOpacity: 0.27,
                shadowRadius: 4.65,
                display:'flex', flexDirection:'column', height:'100%',


                elevation: 6}}

        >




<div style={{display:'flex', justifyContent:"space-between"}}>

    <div>
            <CardHeader className={'jobTableHeader'}  title={title} titleTypographyProps={{style: {fontWeight: 'bold', fontSize:18, fontFamily:'Gilroy'}}}>

            </CardHeader>

    </div>

<div style={{marginTop:15}}>

            {tooltip?
                <div style={{display:'flex', justifyContent:'flex-end', marginRight:25, paddingBottom:"20", alignContent:'space-between'}}>

                    <Tooltip fullWidth={false} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} disableFocusListener disableTouchListener interactive title={tooltipText} placement="left-end">
                                        <span style={{
                                            marginLeft:10,
                                            width: 30,
                                            height: 30,
                                            backgroundColor: "#e3e3e3",
                                            borderRadius: 15,
                                            justifyContent: 'center',
                                            alignItems:'center',
                                            display: 'flex'

                                        }}>
                                    <BiQuestionMark size={15}/>
                                    </span>



                    </Tooltip>

                </div>

                :null}

</div>

</div>



            <Divider />
            <PerfectScrollbar >
                <Box flexGrow={1} >

                    <div>
                        {isLoading?
                            <div style={{marginTop:25}}>
                                <Loading>
                                </Loading>
                            </div>
                            :


                            data?.length===0?
                            <div style={{fontWeight:'normal', alignText:'center', marginLeft:20, marginTop: 20, width:"100%", fontStyle:'italic', fontFamily:'Gilroy-Light'}}>
                                {message}
                            </div>:





                            <div>

                            <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeaderCell} style={{minWidth: 170}}>
                                    Customer Name
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell}  style={{minWidth: 170}}>
                                    REGO
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell} >
                                    Date
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell} >
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.length?data.slice((page)*rowsPerPage, (page+1)*rowsPerPage).map((info) => (
                                <TableRow
                                    hover
                                >
                                    <TableCell className={classes.tableCell}>
                                        {info.first_name} {info.last_name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {info.vehicle_rego?info.vehicle_rego.toUpperCase():"N/A"}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {moment(info.pickup_time).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>

                                        {dualButton?<div style={{display:'flex', justifyContent:'space-between'}}>

                                                {/*<button info={{padding:5}} className={'manage-button'} variant="contained" color="primary" size="small" onClick={()=>closeJob(info)}>{button_text}</button>*/}
                                           <button info={{padding:5}} style={{marginLeft:5}} className={'manage-button'} variant="contained" color="primary" size="small" onClick={()=>onClick2(info)}>Manage</button>
                                        </div>
                                        : <button className={'manage-button'} info={{padding:10}}  variant="contained" color="primary" size="small" onClick={()=>onClick(info)}>{button_text}</button>}
                                    </TableCell>
                                </TableRow>

                            )):null}
                        </TableBody>
                    </Table>



                                {data.length>=3?
                                <TablePagination
                                    rowsPerPageOptions={[3, 5, 7]}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}>
                                </TablePagination>
                                :null}

                            </div>
                        }
                    </div>
                </Box>
            </PerfectScrollbar>

            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
            </Box>
        </Card>
    );
};

JobTable.propTypes = {
    className: PropTypes.string
};

export default JobTable;
