import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import {fetchAuth} from "../../../utils/fetch";
import Loading from "../../../components/Loading";
import WorkshopProfileDetails from "./WorkshopProfileDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,

    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = ({selected_garage}) => {
  const classes = useStyles();
  const [garage, setGarage] = useState({});
  const [loading, setLoading] = useState(true);
  const [garageInfo, setGarageInfo] = useState({});


  useEffect(()=>{
    if (!selected_garage) return;
    fetchAuth('/user/garage?garage=' + selected_garage)
        .then((resJSON)=>{
          setGarage(resJSON.garage)

          return fetchAuth('/user/garageprofileinfo?garage=' + selected_garage)

        }).then((resJSON)=>{
          setGarageInfo(resJSON.garageprofileinfo)
      console.log('m',resJSON.garageprofileinfo)
          setLoading(false)
        })

  }, [selected_garage])


  if (loading)
    return <Loading/>

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid

          container
          spacing={2}
          alignItems={"stretch"}
          direction={"row"}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <Profile garage={garage} garageInfo={garageInfo} setGarageInfo={setGarageInfo}/>

            <WorkshopProfileDetails garage={garage} garageInfo={garageInfo} setGarageInfo={setGarageInfo}/>

          </Grid>

          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <ProfileDetails garage={garage} selected_garage={selected_garage}  garageInfo={garageInfo} setGarageInfo={setGarageInfo}/>
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
