import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button, Card, CardActions, CardContent,
    Container, Divider,
    Grid,

    makeStyles, Typography, TextField, FormControl, Input, InputLabel, InputBase, fade, withStyles,
    FormControlLabel, Switch, TableHead, TableRow, TableCell, Tooltip, TableSortLabel, TableBody, Chip, Table,
    List, ListItem, CardHeader,
} from '@material-ui/core';
import Page from 'src/components/Page';


import styles from "../../styles/quote/quote.scss"
import Password from "../settings/SettingsView/Password";
import clsx from "clsx";
import moment from "moment";
import {fetchAuth, fetchQuote} from "../../utils/fetch";
import Loading from 'src/components/Loading';
import {toast} from "react-toastify";
import {v4 as uuid} from "uuid";
import {useNavigate, useParams} from "react-router-dom";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,

        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));




const SuccessQuoteView = ({selected_garage}) => {
    const classes = useStyles();

    const params = useParams();
    const identifier = params.identifier

    const [quotes, setQuotes] = useState([]);
    const [quotedJobs, setQuotedJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate=useNavigate();






    return (
        <Page
            className={styles.quotePage}
            title="Quote"
        >
            <div className={"quotePage"}>


                    <Card style={{overflow:'auto'}} style={{padding:25}}>

                            <div style={{textAlign:'center', fontSize:36, fontWeight:600}}>
                                Success!
                            </div>
                        <div style={{textAlign:'center', fontSize:20, fontWeight:600}}>
                            Now let us handle the rest!
                        </div>


                        <div style={{textAlign:'center', fontSize:36, fontWeight:600, marginTop:20}}>
                            What happens next?
                        </div>

                        <div style={{display:"flex", justifyContent:'space-between', flexDirection:'row', padding:20, marginTop:20, alignItems:'center'}}>
                            <div style={{display:"flex", justifyContent:'center', flexDirection:'column', textAlign:"center", alignItems:'center'}}>
                                <div>
                                    <img width={120} style={{objectFit:'contain' }} height={90} src="https://booking.myautoshop.co.nz/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fquotingengine_assets%2Fimages%2Fbasket%2Ficon_1.3307ee363ea82098c4522f3b97a6b34c.png&w=128&q=75" alt=""/>
                                </div>
                                <div style={{marginTop:10, fontSize:24, fontWeight:600}}>
                                    1. You've provided a quote
                                </div>
                                <div>
                                    We'll send your quote to the customer along with your business profile and a Book Now button.
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:'center', flexDirection:'column', textAlign:"center"}}>
                                <div>
                                    <img style={{objectFit:'contain' }} width={120} height={90} src="https://booking.myautoshop.co.nz/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fquotingengine_assets%2Fimages%2Fbasket%2Ficon_2.d02774fdd4499498db8be2ce5abc19ed.png&w=128&q=75" alt=""/>
                                </div>
                                <div style={{marginTop:10, fontSize:24, fontWeight:600}}>
                                    2. Customer hits 'Book'
                                </div>
                                <div>
                                  Once the customer decides to book, we'll send through a confirmation email with all the details.
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:'center', flexDirection:'column', textAlign:"center"}}>
                                <div>
                                    <img width={120} style={{objectFit:'contain' }} height={90} src="https://booking.myautoshop.co.nz/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fquotingengine_assets%2Fimages%2Fbasket%2Ficon_1.3307ee363ea82098c4522f3b97a6b34c.png&w=128&q=75" alt=""/>
                                </div>
                                <div style={{marginTop:10, fontSize:24, fontWeight:600}}>
                                    3. Complete the job!
                                </div>
                                <div>
                                    You complete the work, and we'll pay you the price quoted, less any service fee, the following month.
                                </div>
                            </div>
                        </div>

                        <div>
                            <Button onClick={()=>{
                                navigate('/app/dashboard/')

                            }}>
                                Back
                            </Button>
                        </div>







                    </Card>

            </div>

        </Page>
    );
};

export default SuccessQuoteView;
